import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getWarehouses } from '../../services/warehouse_service';
import ErrorHandler from '../../util/error_handler';
import Table from "../../components/table";

class Warehouses extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      warehouse: undefined
    }
  }

  componentDidMount() {
    this.getWarehouses();
  }

  getWarehouses() {
    getWarehouses().then((warehouses) => {
      if (warehouses) {
        this.setState({ warehouses: warehouses }, () => {
          this.setWarehouseRows();
        });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  openWarehouse(warehouse) {
    this.props.history.push(`/warehouses/${warehouse.code}/stock${this.props.location.search}`);
  }

  getWarehouseHeaders() {
    return [
      { name: "Name", key: "name" },
      { name: "City", key: "city" },
      { name: "Country", key: "country_name" },
      { name: "Address", key: "address" },
      { name: "Postcal code", key: "postal_code" }
    ];
  }

  setWarehouseRows() {
    var rows = [];
    this.state.warehouses.forEach(warehouse => {
      rows.push({
        code: warehouse.warehouse_code,
        name: warehouse.name,
        address: warehouse.address,
        postal_code: warehouse.postal_code,
        city: warehouse.city,
        country_name: warehouse.country.name
      });
    });
    this.setState({ warehouse_rows: rows });
  }

  render() {
    return (
      <div>
        
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem active>Warehouses</BreadcrumbItem>
          </Breadcrumb>
                    
          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Warehouses</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm">
                <Table
                  loading_rows={4}
                  headers={this.getWarehouseHeaders()}
                  rows={this.state.warehouse_rows}
                  selectable={true}
                  onRowSelected={this.openWarehouse.bind(this)}
                  empty_rows_text="There are no warehouses"
                />
              </div>
            </div>
            
          </div>
          
        </div>
      </div >
    );
  }
}

export default Warehouses;
