import React from 'react';

const CLEANUP_REGEX = /^[^1-9]+|[^0-9]+|\s+/g; // Only keep digits, without leading zero's

class MatchUtil extends React.Component {

  static find(source, property, target) {
    var matchers = [
      MatchUtil.match1.bind(this),
      MatchUtil.match2.bind(this),
      MatchUtil.match3.bind(this)
    ];

    for(const matcher of matchers) {
      var results = source.filter(c => matcher(this.cleanup(c[property]), this.cleanup(target)));
      var unique =  [...new Set(results.map(v => v[property]))];
      if(unique.length === 1) {
        return results;
      }
      if(unique.length > 1) {
        return []; // Multiple results, dont continue and return empty array
      }
    }

    return [];
  }

  static match1(n1, n2) {
    return n1 === n2;
  }

  static match2(n1, n2) {
    if(n1.length - n2.length <= 3) {
      return n1.includes(n2);
    }
  }

  static match3(n1, n2) {
    if(n2.length - n1.length <= 3) {      
      return n2.includes(n1);
    }
  }

  static cleanup(value) {
    return value.replace(CLEANUP_REGEX, "");
  }
}

export default MatchUtil;
