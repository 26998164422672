import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Shipments from "./components/shipments";

class ActiveShipments extends React.Component {

  openShipment(event) {
    var newTab = event.event.ctrlKey || event.event.metaKey;
    var url = `/shipments/all/${event.data.id}${this.props.location.search}`;
    if(newTab) {
      window.open(url, "_blank", "noreferrer");
    } else {
      this.props.history.push(url);
    }
  }
  
  newShipment() {
    this.props.history.push(`/shipments/all/create${this.props.location.search}`);
  }

  render() {
    return (
      <div>
        
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem active>All Shipments</BreadcrumbItem>
          </Breadcrumb>

          <Shipments
            location={this.props.location}
            history={this.props.history}
            statuses={undefined}
            openShipment={this.openShipment.bind(this)}
            newShipment={this.newShipment.bind(this)}
            emptyMessage="There are no shipments"
          />

        </div>
      </div >
    );
  }
}

export default ActiveShipments;
