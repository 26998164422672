import { Component } from "react";
import packageJson from '../../../../package.json';

export default class CacheBuster extends Component {
  
  shouldForceRefresh(latestVersion) {
    var refresh = false;
    refresh |= (latestVersion !== packageJson.version);
    return refresh;
  }

  refreshCacheAndReload() {
    console.log('Clearing cache and hard reloading...')
    if (caches) {
      caches.keys().then(function(names) {
        for (let name of names) caches.delete(name);
      });
    }
    window.location.reload();
  }

  componentDidMount() {
    var noCacheHeaders = new Headers();
    noCacheHeaders.append('pragma', 'no-cache');
    noCacheHeaders.append('cache-control', 'no-cache');
    
    var params = {
      method: 'GET',
      headers: noCacheHeaders,
    };
    
    fetch('/meta.json', params)
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const shouldForceRefresh = this.shouldForceRefresh(latestVersion);
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          this.refreshCacheAndReload();
        } else {
          console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
        }
      });
  }

  render() {
    return this.props.children
  }
}
