import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Theme from './theme.js';
import ErrorHandler from '../util/error_handler.js';
import { getInvoice, getInvoiceGroupDocument } from '../services/invoice_service.js';
import { getShipmentDocument } from '../services/shipment_service.js';

class ViewInvoiceDialog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.showDocument();
  }
  
  showDocument() {
    this.getDocument().then(blob => {
      var document = blob.slice(0, blob.size, "application/pdf")
      var document_url = URL.createObjectURL(document);
      this.setState({ document_url: document_url });
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getDocument() {
    if(this.props.invoice.resource_type === 'shipment') {
      return getShipmentDocument(this.props.invoice.resource_id, this.props.invoice.document.id);
    }
    if(this.props.invoice.resource_type === 'invoice_group') {
      return getInvoiceGroupDocument(this.props.invoice.resource_id, this.props.invoice.document.id);
    }
  }

  render() {
    return (
      <Theme>
        <Dialog fullWidth={true} maxWidth={"md"} open={this.props.show} onClose={this.props.dismiss} >
          <DialogTitle>{this.props.title}</DialogTitle>
          <DialogContent>
            <div className="row">
              <div className="col-md-12">
                <iframe className="invoice-viewer" src={this.state.document_url} type="application/pdf" />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
          <Button onClick={() => this.props.dismiss()} color="primary">Close</Button>
          </DialogActions>
        </Dialog>
      </Theme>
    )
  }
}

ViewInvoiceDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
  invoice: PropTypes.object,
  title: PropTypes.string
}

export default confirmable(ViewInvoiceDialog);
