import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { hasModule } from '../../services/environment_service';

class Reports extends React.Component {

  constructor(props) {
    super(props)
  }

  openReport(report) {
    this.props.history.push(`/reports/${report}`);
  }

  getReportRows() {
    var reports = [];

    if(hasModule("invoicing")) {
      reports.push({
        title: "Invoices revenue",
        description: "Generate a detailed revenue report for a specific time period. This report provides insights into financial performance. Allowing you to view revenues for an individual account or across all your accounts.",
        image: "images/reports/invoices-revenue.png",
        link: "invoices-revenue"
      });
    }
    
    const reportRows = [];
    while(reports.length) {
      reportRows.push(reports.splice(0,4));
    }
        
    return reportRows;
  }

  render() {
    return (
      <div>
        
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem active>Reports</BreadcrumbItem>
          </Breadcrumb>

          {this.getReportRows().map(row => {
            return (
              <div className="row row-cards">

                {row.map(report => {
                  return (
                    <div className="col col-md-3">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md">
                            <div className="form-header">
                              <label>{report.title}</label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md">
                            <img className='report-image' src={report.image} />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md">
                            <p>{report.description}</p>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => this.openReport(report.link)}
                              startIcon={<Icon>chevron_right_icon</Icon>}>
                              Open
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                
              </div>
            );
          })}
          
        </div>
      </div >
    );
  }
}

export default Reports;
