import React from 'react';
import { Link } from 'react-router-dom';
import DomUtil from '../../../shared/util/dom_util';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import { getQuote, createQuote } from '../../../shared/services/quote_service';
import { getCountries, getPorts } from '../../../shared/services/location_service';
import { getWarehouses } from '../../../shared/services/warehouse_service';
import { getCurrentAccount } from '../../../shared/services/account_service';
import ErrorHandler from '../../../shared/util/error_handler';
import Mapper from '../../../shared/util/mapper'
import confirm from '../../../shared/components/confirm.js'
import QuoteForm from './components/form'
import EventBus from '../../../shared/components/event_bus';
import { hasModule } from '../../../shared/services/environment_service';

class CreateQuote extends React.Component {

  constructor(props) {
    super(props)
    this.createQuote = this.createQuote.bind(this);

    const params = new URLSearchParams(props.location.search);
    let clone_id = params.get('clone_id') ? params.get('clone_id') : undefined;

    this.state = {
      clone_id: clone_id,
      quote: undefined,
      countries: undefined,
      warehouses: undefined,
      ports: undefined,
      account: undefined,
    };
  }

  componentWillMount() {
    this.getCountries();
    this.getPorts();
    this.getCurrentAccount();
    if(hasModule("warehouse")) {
      this.getWarehouses();
    } else {
      this.setState({ warehouses: [] });
    }

    if(this.state.clone_id) {
      this.getQuoteClone();
    } else {
      var initialQuote = {
        pickup_location: {
          type: "address",
          address: "",
          postal_code: "",
          city: "",
          country: null
        },
        delivery_location: {
          type: "address"
        },
        containers: [],
        packages: []
      };
      this.setState({ quote: initialQuote });
    }
  }

  getQuoteClone() {
    getQuote(this.state.clone_id).then((quote) => {
      if (quote) {
        quote.reference = undefined;
        this.setState({ quote: quote });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getCountries() {
    getCountries().then((countries) => {
      if (countries) {
        this.setState({ countries: countries });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getWarehouses() {
    getWarehouses().then((warehouses) => {
      if (warehouses) {
        this.setState({ warehouses: warehouses });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getPorts() {
    getPorts().then((ports) => {
      if (ports) {
        this.setState({ ports: ports });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getCurrentAccount() {
    getCurrentAccount().then((account) => {
      if (account) {
        this.setState({ account: account });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }
  
  createQuote(e) {
    e.preventDefault();
    var form = e.currentTarget;
    confirm("Please confirm", "Are you sure to create this quote?").then(() => {
      DomUtil.disableFormSubmitButtonClass(form);
      var new_quote = Mapper.toQuoteRequest(this.state.quote);
      createQuote(new_quote).then((quote) => {
        EventBus.dispatch("updateQuote");
        this.props.history.push(`${this.getBasePath()}/${quote.id}`);
      }).catch(error => {
        ErrorHandler.showError(error);
        DomUtil.enableFormSubmitButtonClass(form);
      });
    });
  }

  isLoaded() {
    return this.state.countries &&
      this.state.warehouses &&
      this.state.ports &&
      this.state.account &&
      this.state.quote;
  }

  isActivePath() {    
    return this.props.location.pathname.startsWith('/quotes/active');
  }

  getBasePath() {
    if(this.isActivePath()) {
      return '/quotes/active';
    }
    return '/quotes/all';
  }

  getBreadCrumbTitle() {
    if(this.isActivePath()) {
      return 'Active Quotes';
    }
    return 'All Quotes';
  }

  render() {
    return (
      <div>

        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.getBasePath()}${this.props.location.search}`}>{this.getBreadCrumbTitle()}</Link></BreadcrumbItem>
            <BreadcrumbItem active>New Quote</BreadcrumbItem>
          </Breadcrumb>
          
          
          {this.isLoaded() &&
            <QuoteForm
              title={"New Quote"}
              countries={this.state.countries}
              warehouses={this.state.warehouses}
              ports={this.state.ports}
              account={this.state.account}
              quote={this.state.quote}
              saveQuote={this.createQuote}
            />
          }
          
          {!this.isLoaded() &&
            <div className="container-body">
              <div className="row">
                <div className="col-md">
                  <div className="form-header">
                    <label>New Quote</label>
                  </div>
                </div>
              </div>          
              <div className="row row-small">
                <div className="col-md-12 col-lg-8">
                  <Skeleton count={5}/>
                </div>
              </div>
            </div>
          }

        </div>
      </div>
    );
  }
}

export default CreateQuote;
