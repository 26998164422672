import React from 'react';
import { ThemeProvider,  createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#009FE3',
      contrastText: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: [
      'Lato',
      'sans-serif'
    ].join(','),
  },
  overrides: {
    MuiButton: {
      root: {
        marginRight: "10px",
        textTransform: "none"
      }
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: "#ffffff"
      }
    }
  }
});

class Theme extends React.Component {
    
  render() {
    return (
      <ThemeProvider theme={theme}>
        <div>
          {this.props.children}
        </div>
      </ThemeProvider>
      );
  }
}

export default Theme;
