import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Theme from './theme';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Mapper from '../util/mapper'
import DocumentUpload from './document_upload'
import { getColliExampleDocument, readColliDocument } from '../services/shipment_service';
import Converter from '../util/converter'
import fileDownload from 'js-file-download';
import alertDialog from './alert';

class ShipmentColliImportDialog extends React.Component {

  constructor(props) {
    super();
    this.state = {
      merge: true,
      colli: [],
      replace: false

    };

    this.formRef = React.createRef();
  }

  submit(e) {
    e.preventDefault();

    if(!this.formRef.current.checkValidity()) {
      this.formRef.current.reportValidity();
      return;
    }

    this.props.proceed({
      colli: this.state.colli,
      replace: this.state.replace
    })
  }

  handleMergeChange(e) {
    this.setState({ merge: e.target.value });
  }

  handleReplaceChange(e) {
    this.setState({ replace: e.target.value });
  }

  downloadExampleFile(e) {
    e.preventDefault();
    getColliExampleDocument().then(blob => {
      fileDownload(blob, "shipment_colli_import_example.xlsx");
    }).catch(error => {
      console.error(error)
      alertDialog("Alert", "Failed to download document.")
    });
  }

  uploadDocument(file, progressEvent) {
    return readColliDocument(file, this.state.merge, progressEvent);
  }

  documentUploaded(data) {
    this.setState({ colli: data.colli });
  }

  getTotalPackages() {
    if(!this.state.colli) {
      return 0;
    }
    return this.state.colli.length;
  }

  getTotalParts() {
    if(!this.state.colli) {
      return 0;
    }

    var total = 0;
    this.state.colli.forEach(_package => {
      if(_package.parts) {
        total += _package.parts.length;
      }
    });
    return total;
  }

  render() {
    return (
      <Theme>
        <Dialog fullWidth={true} maxWidth={"md"} open={this.props.show} onClose={this.props.dismiss} >
          <DialogTitle>Import colli details</DialogTitle>
          <DialogContent>
            <form autoComplete="off" ref={this.formRef} onSubmit={this.submit.bind(this)}>
                
              <div className="row">
                <div className="col-md">

                  <div className="row">
                    <div className="col-md-12">
                      <span>The import allows you to set your colli details using an Excel file.</span>
                      <br/>
                      <span>Download the example Excel file to see in which structure the data should be supplied.</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={this.downloadExampleFile.bind(this)}
                        startIcon={<Icon>download</Icon>}>
                        Download example Excel
                      </Button>
                    </div>
                  </div>
                                  
                </div>
              </div>

              <div className="row">
                <div className="col-md">

                  <div className="row">
                    <div className="col-md-6">
                      <span>Upload your Excel file with colli details:</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <FormControl
                        variant="outlined"
                        margin="dense"
                        fullWidth>
                        <InputLabel>Merge duplicate part/orders in package *</InputLabel>
                        <Select
                          native
                          required={true}
                          label="Merge duplicate part/orders in package"
                          value={this.state.merge}
                          onChange={(e) => {this.handleMergeChange(e)}} >
                            {Object.keys(Mapper.booleans()).map(type => {
                              return (<option key={"merge_"+type} value={type}>{Mapper.fromBoolean(type)}</option>);
                            })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <DocumentUpload 
                        title="Excel with colli details"
                        required={true}
                        uploadDocument={this.uploadDocument.bind(this)}
                        documentUploaded={this.documentUploaded.bind(this)}
                      />
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="col-md-6">
                      <FormControl
                        variant="outlined"
                        margin="dense"
                        fullWidth>
                        <InputLabel>Replace existing colli *</InputLabel>
                        <Select
                          native
                          required={true}
                          label="Replace existing colli"
                          value={this.state.replace}
                          onChange={(e) => {this.handleReplaceChange(e)}} >
                            {Object.keys(Mapper.booleans()).map(type => {
                              return (<option key={"replace_"+type} value={type}>{Mapper.fromBoolean(type)}</option>);
                            })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <table className="table table-details" >
                        <tbody>
                          <tr className="row-no-top-border-and-padding">
                            <td width={200} className="header-text">Total packages</td>
                            <td>{Converter.formatNumber(this.getTotalPackages(), 0)}</td>
                          </tr>
                          <tr>
                            <td width={200} className="header-text">Total parts</td>
                            <td>{Converter.formatNumber(this.getTotalParts(), 0)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.dismiss()} color="primary">Cancel</Button>
            <Button onClick={this.submit.bind(this)} color="primary">Save</Button>
          </DialogActions>
        </Dialog>
      </Theme>
    )
  }
}

ShipmentColliImportDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
}

export default confirmable(ShipmentColliImportDialog);
