import { DefaultApi } from "generated-environments-api";
import { getHeaders, handleResponse } from './web_service';

export const API = new DefaultApi({
  basePath: window.appConfig.API_HOSTNAME
});

export function getCurrentEnvironment() {
  return API.getCurrentEnvironment(getHeaders()).then(handleResponse);
}

export function getCachedEnvironment() {
  var environment = JSON.parse(localStorage.getItem("environment"));
  if(!environment) {
    environment = {};
  }
  return environment;
}

export function cacheEnvironment(environment) {
  localStorage.setItem("environment", JSON.stringify(environment));
}

export function hasModule(module) {
  var environment = getCachedEnvironment();
  return environment.modules && environment.modules.includes(module);
}
