import React from 'react';
import MultiShipmentDocumentUpload from '../../../../shared/components/multi_shipment_document_upload'

class DocumentsTab extends React.Component {

  documentsUpdated(property, documents) {
    this.props.shipment[property] = documents;
    this.props.updateShipment(this.props.shipment);
  }

  render() {
    return (
      <div>

        { !this.props.shipment.id &&
          <div className="row">
            <div className="col-md">
              <span><i>Document can be uploaded after the shipment is created</i></span>
            </div>
          </div>
        }

        { this.props.shipment.id &&
          <div>

            <div className="row">
              <div className="col-md">
                <div className="header-text">
                  <label>Public documents</label>
                </div>
              </div>
            </div>

            <div className="row row-small">
              <div className="col-md">
                <MultiShipmentDocumentUpload 
                  title="Public documents"
                  shipment_id={this.props.shipment.id}
                  access="all"
                  required={false}
                  documentsUpdated={this.documentsUpdated.bind(this, "public_documents")}
                  documents={this.props.shipment.public_documents} />                            
              </div>
            </div>

            <div className="tab-splitter" />

            <div className="row">
              <div className="col-md">
                <div className="header-text">
                  <label>Internal documents</label>
                </div>
              </div>
            </div>

            <div className="row row-small">
              <div className="col-md">
                <MultiShipmentDocumentUpload 
                  title="Internal documents"
                  shipment_id={this.props.shipment.id}
                  access="all"
                  required={false}
                  documentsUpdated={this.documentsUpdated.bind(this, "internal_documents")}
                  documents={this.props.shipment.internal_documents} />
              </div>
            </div>
          </div>
        }
        
      </div>
      
    );
  }
}

export default DocumentsTab;
