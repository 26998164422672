import React from 'react';
import Skeleton from 'react-loading-skeleton';

class LoadingContent extends React.Component {
  
  render() {
    if(!this.props.mustBeLoaded) {
      return <Skeleton count={this.props.rows} />;
    }
    if(this.props.content()) {
      return this.props.content();
    }
    return <span/>;
  }
}

export default LoadingContent;
