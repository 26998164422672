import React from 'react';
import { getShipmentDocument, uploadShipmentDocument } from '../services/shipment_service';
import DocumentUpload from './document_upload';

class ShipmentDocumentUpload extends React.Component {

  uploadDocument(file, progressEvent) {
    return uploadShipmentDocument(this.props.shipment_id, file, this.props.access, progressEvent);
  }

  documentUploaded(document) {
    this.props.documentUploaded(document, this.props.parent_data);
  }

  documentCleared() {
    this.props.documentCleared(this.props.parent_data);
  }

  downloadDocument(document) {
    return getShipmentDocument(this.props.shipment_id, document.id);
  }

  render() {
    return(
      <DocumentUpload
        title={this.props.title}
        file_name={this.props.file_name}
        document={this.props.document}
        required={this.props.required}
        disabled={this.props.disabled}
        alwayClearable={this.props.alwayClearable}
        uploadDocument={this.uploadDocument.bind(this)}
        documentUploaded={this.documentUploaded.bind(this)}
        documentCleared={this.documentCleared.bind(this)}
        downloadDocument={this.downloadDocument.bind(this)}
      />
    );
  }
}

export default ShipmentDocumentUpload;
