import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Checkbox from '@material-ui/core/Checkbox';
import AsyncButton from '../../components/async_button';
import { setCompanyDetails, updateCurrentUser } from '../../services/account_service';
import { actionRequired, hasAction } from '../../services/authentication_service';
import alertDialog from '../../components/alert';
import Mapper from '../../util/mapper';
import DomUtil from '../../util/dom_util';
import PasswordForm from '../../components/password_form';
import ErrorHandler from '../../util/error_handler';
import Alert from '@material-ui/lab/Alert';

class UpdateUser extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      user: {},
      company: {},
      password_errors: [ "length", "case", "numeric", "symbol", "confirm" ]
    }
  }

  componentWillMount() {
    if (!actionRequired()) {
      this.redirect();
    }
  }

  redirect() {
    this.props.history.replace('/');
  }

  handleChange(property, e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.state[property][e.target.name] = value;
    this.setState({ [property]: this.state[property] });
  }

  handleCheckboxChange(e) {
    this.state.user[e.target.name] = e.target.checked;
    this.setState({ user: this.state.user });
  }

  passwordUpdated(password, has_errors) {
    this.state.user.password = password;
    this.state.user.password_has_errors = has_errors;
    this.setState({ user: this.state.user });
  }

  updateUser(e) {
    e.preventDefault();
    var form = e.currentTarget;

    if(hasAction("password") && this.state.user.password_has_errors) {
      alertDialog("Alert", "Password does not match all requirements");
      return;
    }
    
    DomUtil.disableFormSubmitButtonClass(form);
    var update = Mapper.toUserUpdate(this.state.user);

    if(hasAction("company")) {
      var details = Mapper.toCompanyDetails(this.state.company);

      setCompanyDetails(details).then(() => {
        updateCurrentUser(update).then(() => {
          DomUtil.enableFormSubmitButtonClass(form);
          this.redirect();
        }).catch((error) => {
          ErrorHandler.showError(error);
          DomUtil.enableFormSubmitButtonClass(form);
        });
      }).catch((error) => {
        ErrorHandler.showError(error);
        DomUtil.enableFormSubmitButtonClass(form);
      });
    } else {
      updateCurrentUser(update).then(() => {
        DomUtil.enableFormSubmitButtonClass(form);
        this.redirect();
      }).catch((error) => {
        ErrorHandler.showError(error);
        DomUtil.enableFormSubmitButtonClass(form);
      });
    }
  }

  openTerms(e) {
    e.preventDefault();
    var url = `/document/terms-and-conditions?file_name=General-Terms-and-Conditions.pdf`;
    window.open(url, "_blank");
  }

  render() {
    return (
      <div>
                
        <div className="container-content">

          {hasAction("terms") &&
            <Breadcrumb>
              <BreadcrumbItem active>Welcome</BreadcrumbItem>
            </Breadcrumb>
          }

          {!hasAction("terms") &&
            <Breadcrumb>
              <BreadcrumbItem active>Update account</BreadcrumbItem>
            </Breadcrumb>
          }

          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Note</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md">
                <span>Please provide all details as requested below in order to continue to the platform.</span>
              </div>
            </div>
            
          </div>

          <form autoComplete="off" onSubmit={this.updateUser.bind(this)}>

            {hasAction("company") &&
              <div className="container-body">

                <div className="row">
                  <div className="col-md">
                    <div className="form-header">
                      <label>Company information</label>
                    </div>
                  </div>
                </div>

                <div className="row row-small">
                  <div className="col-md-6">
                    <Alert severity="warning">You wont be able to change the company information once submitted.</Alert>
                  </div>
                </div>
              
                <div className="row row-small">
                  <div className="col-md-6">
                    <TextField
                      label="Company name"
                      variant="outlined"
                      required
                      fullWidth
                      margin="dense"
                      name="name"
                      onChange={this.handleChange.bind(this, "company")} 
                    />
                  </div>
                </div>
                
              </div>
            }
            
            {hasAction("password") &&
              <div className="container-body">
                <div className="row">
                  <div className="col-md">
                    <div className="form-header">
                      <label>Password</label>
                    </div>
                  </div>
                </div>
                <div className="row row-small">
                  <div className="col-md-6">
                    <PasswordForm passwordUpdated={this.passwordUpdated.bind(this)} />
                  </div>
                </div>

              </div>
            }

            {hasAction("full_name") &&
              <div className="container-body">
                <div className="row">
                  <div className="col-md">
                    <div className="form-header">
                      <label>User account</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <span>Please enter your full name.</span>
                  </div>
                </div>
                <div className="row row-small">
                  <div className="col-md-6">
                    <TextField
                      label="Full name"
                      variant="outlined"
                      required
                      fullWidth
                      margin="dense"
                      name="full_name"
                      onChange={this.handleChange.bind(this, "user")} 
                    />
                  </div>
                </div>
              </div>
            }

            {hasAction("terms") &&
              <div className="container-body">
                <div className="row">
                  <div className="col-md">
                    <div className="form-header">
                      <label>Terms and conditions</label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md">
                    <Checkbox
                      required
                      color="primary"
                      name="terms"
                      onChange={this.handleCheckboxChange.bind(this)} 
                    />
                    <span>I have read and agree to the&nbsp;</span>
                    <Link
                      className="inline-link"
                      component="button"
                      variant="body2"
                      type="button"
                      onClick={this.openTerms.bind(this)}>
                        terms and conditions
                    </Link>
                  </div>
                </div>
              </div>
            }

            <div className="container-body">
              <div className="row">
                <div className="col-md">
                  <div className="form-header">
                    <label>Submit</label>
                  </div>
                </div>
              </div>
              
              <div className="row">
                <div className="col-md">
                  <span>The form can be submitted once it has been filled in completly.</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <AsyncButton
                    type="submit"
                    variant="outlined"
                    color="primary"
                    icon="check"
                    text="Submit" />
                </div>
              </div>
            </div>
            
          </form>
          
        </div>
      </div >
    );
  }
}

export default UpdateUser;
