import React from 'react';

class DomUtil extends React.Component {

  static findFormSubmitButton(element) {
    if(!element || !element.nodeName) {
      return;
    }
    if(element.nodeName === "BUTTON" && element.type === "submit") {
      return element;
    }
    if(element.hasChildNodes()) {
      for(var node of element.childNodes) {
        var result = this.findFormSubmitButton(node);
        if(result) {
          return result;
        }
      }
    }
  }

  static disableFormSubmitButtonClass(element) {
    var button = this.findFormSubmitButton(element);
    if(button) {
      button.disabled = true;
    }
  }

  static enableFormSubmitButtonClass(element) {
    var button = this.findFormSubmitButton(element);
    if(button) {
      button.disabled = false;
    }
  }

  static disableClass(element) {
    if(element) {
      element.disabled = true;
    }
  }

  static enableClass(element) {
    if(element) {
      element.disabled = false;
    }  
  }
}

export default DomUtil;
