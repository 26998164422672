import React from 'react';
import alertDialog from '../components/alert';

class ErrorHandler extends React.Component {

  static showError(error) {
    var message = "Something went wrong";

    if (error.response) {
      var data = error.response.data;

      if(data.message) {
        message = data.message;
      }

      if(data instanceof Blob) {
        data.text().then(result => {
          console.error(result);
          
          var parsed = JSON.parse(result);
          if(parsed.message) {
            alertDialog("Alert", parsed.message);
          } else {
            alertDialog("Alert", message);
          }
        });
        return;
      }
    } else if (error.message) {
      message = error.message;
    }

    console.error(error);
    alertDialog("Alert", message);
  }
}

export default ErrorHandler;
