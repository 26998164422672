import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Theme from './theme.js';

class OutboundLabelDialog extends React.Component {

  constructor(props) {
    super();
    this.state = {
      number_of_pallets: props.initial_pallet_count,
      copies: 1
    };

    this.formRef = React.createRef();
  }

  submit(e) {
    e.preventDefault();

    if(!this.formRef.current.checkValidity()) {
      this.formRef.current.reportValidity();
      return;
    }

    this.props.proceed({
      number_of_pallets: this.state.number_of_pallets,
      copies: this.state.copies,
    })
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <Theme>
        <Dialog open={this.props.show} onClose={this.props.cancel} >
          <DialogTitle>Outbound label details</DialogTitle>
          <DialogContent>
            <form autoComplete="off" ref={this.formRef} onSubmit={this.submit.bind(this)}>

              <div className="row">
                <div className="col-md-12">

                  <div className="row">
                    <div className="col-md-12">
                      <span>Please specify the number of pallets to put on the label</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <TextField
                        label="Number of pallets"
                        variant="outlined"
                        required
                        fullWidth
                        type="number"
                        margin="dense"
                        autoFocus
                        value={this.state.number_of_pallets}
                        name="number_of_pallets"
                        onChange={this.handleChange.bind(this)}
                        InputProps={{
                          inputProps: { min: 0 }
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <TextField
                        label="Copies"
                        variant="outlined"
                        required
                        fullWidth
                        type="number"
                        margin="dense"
                        value={this.state.copies}
                        name="copies"
                        onChange={this.handleChange.bind(this)}
                        InputProps={{
                          inputProps: { min: 1, max: 20 }
                        }}
                      />
                    </div>
                  </div>

                </div>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.cancel()} color="primary">Cancel</Button>
            <Button onClick={this.submit.bind(this)} color="primary">Ok</Button>
          </DialogActions>
        </Dialog>
      </Theme>
    )
  }
}

OutboundLabelDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
  initial_pallet_count: PropTypes.number
}

export default confirmable(OutboundLabelDialog);
