import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Converter from '../../../../shared/util/converter'
import confirmPackage from '../../../../shared/components/confirm_package.js'
import PackagesTable from '../../../../shared/components/packages_table.js'
import confirm from '../../../../shared/components/confirm.js'
import confirmShipmentColliImport from '../../../../shared/components/shipment_confirm_colli_import.js'

class ColliTab extends React.Component {

  addPackageToList() {
    if(!this.props.shipment.colli) {
      this.props.shipment.colli = [];
    }
    var _package = { key: this.getUniqueKey(), index: this.props.shipment.colli.length };
    confirmPackage("Add package", _package, this.props.shipment.transport_type, true).then((response_package) => {
      this.props.shipment.colli.push(response_package);
      this.props.updateShipment(this.props.shipment);
    });
  }

  editPackageOfList(index) {
    const clone = JSON.parse(JSON.stringify(this.props.shipment.colli[index]));
    clone.index = index;

    confirmPackage("Edit package", clone, this.props.shipment.transport_type, true).then((response_package) => {
      this.props.shipment.colli[index] = response_package;
      this.props.updateShipment(this.props.shipment);
    });
  }

  clonePackageOfList(index) {
    const clone = JSON.parse(JSON.stringify(this.props.shipment.colli[index]));
    clone.key = this.getUniqueKey()
    clone.index = this.props.shipment.colli.length;
    
    confirmPackage("Clone package", clone, this.props.shipment.transport_type, true).then((response_package) => {
      this.props.shipment.colli.push(response_package);
      this.props.updateShipment(this.props.shipment);
    });
  }

  removePackageFromList(index) {
    confirm("Please confirm", `Are you sure you want to delete Package ${index + 1}?`).then(() => {
      this.props.shipment.colli.splice(index, 1);
      this.props.updateShipment(this.props.shipment);
    });
  }

  importColli() {
    confirmShipmentColliImport().then((data) => {
      if(!data.colli) {
        return;
      }

      if(!this.props.shipment.colli) {
        this.props.shipment.colli = [];
      }

      if(data.replace) {
        this.props.shipment.colli = [];
      }

      for(const _package of data.colli) {
        _package.key = this.getUniqueKey();
        this.props.shipment.colli.push(_package);
      }
      
      this.props.updateShipment(this.props.shipment);
    });
  }

  getTotalPackages() {
    if(!this.props.shipment.colli) {
      return 0;
    }
    return this.props.shipment.colli.length;
  }

  getTotalParts() {
    if(!this.props.shipment.colli) {
      return 0;
    }

    var total = 0;
    this.props.shipment.colli.forEach(_package => {
      if(_package.parts) {
        total += _package.parts.length;
      }
    });
    return total;
  }

  getUniqueKey() {
    return new Date().getTime() + Math.random();
  }

  render() {
    return (
      <div>
    
        <div className="row row-small">
          <div className="col-md-6 col-lg-4">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td width={200} className="header-text">Total packages</td>
                  <td>{Converter.formatNumber(this.getTotalPackages(), 0)}</td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Total parts</td>
                  <td>{Converter.formatNumber(this.getTotalParts(), 0)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="col-md">
            <Button
              variant="outlined"
              color="primary"
              onClick={this.addPackageToList.bind(this)}
              startIcon={<Icon>playlist_add_check</Icon>}>
              Add package
            </Button>
            <Button
              className='align_right'
              variant="outlined"
              color="primary"
              onClick={this.importColli.bind(this)}
              startIcon={<Icon>upload_file</Icon>}>
              Import
            </Button>
          </div>
        </div>
      
        <div className="row">
          <div className="col-sm">
            <PackagesTable
                name="shipment_form_packages_table_state"
                data={this.props.shipment}
                headers={[ 
                  "number",
                  "loading_number",
                  "order_number",
                  "quantity",
                  "unloading_quantity",
                  "loading_quantity",
                  "reference",
                  "part_price",
                  "country_of_origin",
                  "description",
                  "arrival_date",
                  "ready_date",
                  "dispatch_date"
                ]}
                editable={true}
                onEdit={this.editPackageOfList.bind(this)}
                onClone={this.clonePackageOfList.bind(this)}
                onDelete={this.removePackageFromList.bind(this)}
            />
          </div>
        </div>
      
      </div>
      
    );
  }
}

export default ColliTab;
