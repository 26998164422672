import React from 'react';
import { Link } from 'react-router-dom';
import AsyncButton from '../../../shared/components/async_button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DomUtil from '../../../shared/util/dom_util';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { inviteUser } from '../../../shared/services/account_service';
import ErrorHandler from '../../../shared/util/error_handler';
import Mapper from '../../../shared/util/mapper'

class InviteUser extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      user: {}
    };
  }

  handleChange(e) {
    this.state.user[e.target.name] = e.target.value;
    this.setState({ user: this.state.user });
  }

  inviteUser(e) {
    e.preventDefault();
    var form = e.currentTarget;
    DomUtil.disableFormSubmitButtonClass(form);
    inviteUser(this.state.user).then(() => {
      this.props.history.push(`/account/` + this.props.location.search);
    }).catch(error => {
      ErrorHandler.showError(error);
      DomUtil.enableFormSubmitButtonClass(form);
    });
  }

  render() {
    return (
      <div>

        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`/account/` + this.props.location.search}>Account</Link></BreadcrumbItem>
            <BreadcrumbItem active>Invite user</BreadcrumbItem>
          </Breadcrumb>
                
          <div className="container-body">
            
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Invite user</label>
                </div>
              </div>
            </div>

            <form autoComplete="off" onSubmit={this.inviteUser.bind(this)}>
              
              <div className="row row-small">
                <div className="col-md-4">
                  <TextField
                    label="Company email"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    name="email"
                    onChange={this.handleChange.bind(this)}
                  />
                </div>
              </div>

              <div className="row row-small">
                <div className="col-md-4">
                  <TextField
                    label="Full name"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    name="full_name"
                    onChange={this.handleChange.bind(this)}
                  />
                </div>
              </div>

              <div className="row row-small">
                <div className="col-sm">
                  <span>Roles</span>
                  <br/>
                  <span>- <strong>Read only:</strong> only has read access to resource on the platform.</span>
                  <br/>
                  <span>- <strong>Default:</strong> can create and update resource on the platform.</span>
                  <br/>
                  <span>- <strong>Admin:</strong> can invite and edit users within the account, there must always be one admin user.</span>
                </div>
              </div>

              <div className="row row-small">
                <div className="col-md-4">
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    fullWidth>
                    <InputLabel>Role *</InputLabel>
                    <Select
                      native
                      required
                      label="Role *"
                      name="role"
                      onChange={this.handleChange.bind(this)} >
                        <option key={"role_empty"} value=""></option>
                        {Object.keys(Mapper.userRoles()).map(role => {
                          return (<option key={"role_"+role} value={role}>{Mapper.fromUserRole(role)}</option>);
                        })}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="row row-small">
                <div className="col-md">
                  <AsyncButton
                    type="submit"
                    variant="outlined"
                    color="primary"
                    icon="check"
                    text="Invite" />
                </div>
              </div>

            </form>
          
          </div>

        </div>
      </div>
    );
  }
}

export default InviteUser;
