import React from 'react';
import { Link } from 'react-router-dom';
import AsyncButton from '../../components/async_button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DomUtil from '../../util/dom_util';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import LoadingSelectField from "../../components/loading_select_field";
import { createAddress } from '../../services/account_service';
import { getCountries } from '../../services/location_service';
import ErrorHandler from '../../util/error_handler';
import Mapper from '../../util/mapper'

class CreateAddress extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      countries: undefined,
      address: {}
    };
  }

  componentWillMount() {
    this.getCountries();
  }

  getCountries() {
    getCountries().then((countries) => {
      if (countries) {
        this.setState({ countries: countries });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }
  
  firstLetter(option) {
    return option.name[0].toUpperCase();
  }

  handleChange(e) {
    this.state.address[e.target.name] = e.target.value;
    this.setState({ address: this.state.address });
  }
  
  handleAutoCompleteChange(e, newValue) {
    if(newValue) {
      this.state.address.country = newValue;
    } else {
      this.state.address.country = { name: "" };
    }
    this.setState({ address: this.state.address });
  }

  createAddress(e) {
    e.preventDefault();
    var form = e.currentTarget;
    DomUtil.disableFormSubmitButtonClass(form);
    createAddress(Mapper.toAddressCreation(this.state.address)).then(() => {
      this.props.history.push(`/account/` + this.props.location.search);
    }).catch(error => {
      ErrorHandler.showError(error);
      DomUtil.enableFormSubmitButtonClass(form);
    });
  }

  render() {
    return (
      <div>

        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`/account/` + this.props.location.search}>Account</Link></BreadcrumbItem>
            <BreadcrumbItem active>Add address</BreadcrumbItem>
          </Breadcrumb>
                
          <div className="container-body">
            
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Add address</label>
                </div>
              </div>
            </div>

            <form autoComplete="off" onSubmit={this.createAddress.bind(this)}>
              
              <div className="row row-small">
                <div className="col-md-4">
                  <TextField
                    label="Name"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    name="name"
                    onChange={this.handleChange.bind(this)}
                  />
                </div>
              </div>
              
              <div className="row row-small">
                <div className="col-md-4">
                  <TextField
                    label="Address line"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    name="address"
                    onChange={this.handleChange.bind(this)}
                  />
                </div>
              </div>

              <div className="row row-small">
                <div className="col-md-4">
                  <TextField
                    label="Postal code"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    name="postal_code"
                    onChange={this.handleChange.bind(this)}
                  />
                </div>
              </div>

              <div className="row row-small">
                <div className="col-md-4">
                  <TextField
                    label="City"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    name="city"
                    onChange={this.handleChange.bind(this)}
                  />
                </div>
              </div>
              
              <div className="row row-small">
                <div className="col-md-4">
                  { this.state.countries &&
                    <Autocomplete
                      key="country_code"
                      options={this.state.countries.sort((a, b) => -b.name[0].localeCompare(a.name[0]))}
                      groupBy={this.firstLetter.bind(this)}
                      getOptionLabel={(option) => option.name}
                      onChange={this.handleAutoCompleteChange.bind(this)}
                      renderInput={(params) => 
                        <TextField {...params}
                          label="Country"
                          variant="outlined"
                          required
                          fullWidth
                          margin="dense"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                          }}
                        />                      
                      }
                    />
                  }
                  { !this.state.countries &&
                    <LoadingSelectField
                      label="Country"
                      shrink={false}
                    />
                  }
                </div>
              </div>

              <div className="row row-small">
                <div className="col-md">
                  <AsyncButton
                    type="submit"
                    variant="outlined"
                    color="primary"
                    disabled={this.state.countries === undefined}
                    icon="check"
                    text="Add" />
                </div>
              </div>

            </form>
          
          </div>

        </div>
      </div>
    );
  }
}

export default CreateAddress;
