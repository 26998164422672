import React from 'react';

class Calculator extends React.Component {

  static calculateLoadingMeters(pallets) {
    if(!pallets) {
      return 0;
    }

    var total_ldm = 0;

    for(const pallet of pallets) {
      var pallet_ldm = (Number(pallet.length_cm) / 100) * (Number(pallet.width_cm) / 100) / 2.4;
      if(pallet.stackable === "true" || pallet.stackable === true) {
        pallet_ldm = pallet_ldm * ((Number(pallet.height_cm) / 100) / 2.2);
      }
      total_ldm += pallet_ldm;
    }

    return total_ldm;
  }
}

export default Calculator;
