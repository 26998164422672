import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getAccount } from '../../../shared/services/account_service';
import Mapper from '../../../shared/util/mapper';
import ErrorHandler from '../../../shared/util/error_handler';
import StringUtil from '../../../shared/util/string_util';
import Table from "../../../shared/components/table";
import LoadingText from '../../../shared/components/loading_text';

class Account extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      account_id: this.props.match.params.account_id,
      account: {}
    }
  }

  componentDidMount() {
    this.getAccount();
  }

  getAccount() {
    getAccount(this.state.account_id).then((account) => {
      if (account) {
        this.setState({ account: account }, () => {
          this.setUserRows();
          this.setAddressRows();
        });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getUserHeaders() {
    return [
      { name: "Email", key: "email" },
      { name: "Full name", key: "full_name" },
      { name: "Role", key: "role" }
    ];
  }

  setUserRows() {
    var rows = [];
    this.state.account.users.forEach(user => {
      rows.push({
        id: user.id,
        email: user.email,
        full_name: user.full_name,
        role: Mapper.fromUserRole(user.role)
      });
    });
    this.setState({ user_rows: rows });
  }

  getAddressHeaders() {
    return [
      { name: "Name", key: "name" },
      { name: "Address line", key: "address" },
      { name: "Postcal code", key: "postal_code" },
      { name: "City", key: "city" },
      { name: "Port name", key: "port_name" },
      { name: "Country", key: "country_name" }
    ];
  }
  
  setAddressRows() {
    var rows = [];
    this.state.account.addresses.forEach(address => {
      rows.push({
        id: address.id,
        name: address.name,
        address: address.address,
        postal_code: address.postal_code,
        city: address.city,
        port_name: address.port_name,
        country_name: address.country.name
      });
    });
    this.setState({ address_rows: rows });
  }

  render() {
    return (
      <div>
          
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`/accounts` + this.props.location.search}>Accounts</Link></BreadcrumbItem>
            <BreadcrumbItem active>{StringUtil.shortId(this.state.account_id)}</BreadcrumbItem>
          </Breadcrumb>
          
          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Company information</label>
                </div>
              </div>
            </div>
            <div className="row row-small">
              <div className="col-sm-6">
                <table className="table table-details" >
                  <tbody>
                    <tr className="row-no-top-border-and-padding">
                      <td style={{width: "230px"}} className="header-text">ID</td>
                      <td><LoadingText text={StringUtil.shortId(this.state.account.id)} /></td>
                    </tr>
                    <tr>
                      <td width={200} className="header-text">Account type</td>
                      <td><LoadingText text={StringUtil.capitalize(this.state.account.type)} /></td>
                    </tr>
                    <tr>
                      <td width={200}  className="header-text">Name</td>
                      <td><LoadingText text={this.state.account.name} /></td>
                    </tr>
                    <tr>
                      <td width={200}  className="header-text">Environment</td>
                      <td><LoadingText text={this.state.account.environment} /></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Addresses</label>
                </div>
              </div>
            </div>

            <div className="row row-small">
              <div className="col-sm">
                <Table
                  loading_rows={4}
                  headers={this.getAddressHeaders()}
                  rows={this.state.address_rows}
                  selectable={false}
                  empty_rows_text="There are no addresses"
                />
              </div>
            </div>
          </div>

          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Users</label>
                </div>
              </div>
            </div>

            <div className="row row-small">
              <div className="col-sm">
                <Table
                  loading_rows={4}
                  headers={this.getUserHeaders()}
                  rows={this.state.user_rows}
                  selectable={false}
                  empty_rows_text="There are no users"
                />
              </div>
            </div>
            
          </div>
          
        </div>
      </div >
    );
  }
}

export default Account;
