import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Theme from './theme';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Mapper from '../util/mapper'
import DocumentUpload from './document_upload'
import { getPalletsExampleDocument, readPalletsDocument } from '../services/quote_service';
import Converter from '../util/converter'
import fileDownload from 'js-file-download';
import alertDialog from './alert';

class QuotePalletsImportDialog extends React.Component {

  constructor(props) {
    super();
    this.state = {
      pallets: [],
      replace: false

    };

    this.formRef = React.createRef();
  }

  submit(e) {
    e.preventDefault();

    if(!this.formRef.current.checkValidity()) {
      this.formRef.current.reportValidity();
      return;
    }

    this.props.proceed({
      pallets: this.state.pallets,
      replace: this.state.replace
    })
  }

  handleReplaceChange(e) {
    this.setState({ replace: e.target.value });
  }

  downloadExampleFile(e) {
    e.preventDefault();
    getPalletsExampleDocument().then(blob => {
      fileDownload(blob, "quote_pallets_import_example.xlsx");
    }).catch(error => {
      console.error(error)
      alertDialog("Alert", "Failed to download document.")
    });
  }

  uploadDocument(file, progressEvent) {
    return readPalletsDocument(file, progressEvent);
  }

  documentUploaded(data) {
    this.setState({ pallets: data.pallets });
  }

  render() {
    return (
      <Theme>
        <Dialog fullWidth={true} maxWidth={"md"} open={this.props.show} onClose={this.props.dismiss} >
          <DialogTitle>Import pallets</DialogTitle>
          <DialogContent>
            <form autoComplete="off" ref={this.formRef} onSubmit={this.submit.bind(this)}>
                
              <div className="row">
                <div className="col-md">

                  <div className="row">
                    <div className="col-md-12">
                      <span>The import allows you to set your pallets using an Excel file.</span>
                      <br/>
                      <span>Download the example Excel file to see in which structure the data should be supplied.</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={this.downloadExampleFile.bind(this)}
                        startIcon={<Icon>download</Icon>}>
                        Download example Excel
                      </Button>
                    </div>
                  </div>
                                  
                </div>
              </div>

              <div className="row">
                <div className="col-md">

                  <div className="row">
                    <div className="col-md-6">
                      <span>Upload your Excel file with pallets:</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <DocumentUpload 
                        title="Excel with pallets"
                        required={true}
                        uploadDocument={this.uploadDocument.bind(this)}
                        documentUploaded={this.documentUploaded.bind(this)}
                      />
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="col-md-6">
                      <FormControl
                        variant="outlined"
                        margin="dense"
                        fullWidth>
                        <InputLabel>Replace existing pallets *</InputLabel>
                        <Select
                          native
                          required={true}
                          label="Replace existing pallets"
                          value={this.state.replace}
                          onChange={(e) => {this.handleReplaceChange(e)}} >
                            {Object.keys(Mapper.booleans()).map(type => {
                              return (<option key={"replace_"+type} value={type}>{Mapper.fromBoolean(type)}</option>);
                            })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="col-md-6">
                      <table className="table table-details" >
                        <tbody>
                          <tr className="row-no-top-border-and-padding">
                            <td width={200} className="header-text">Total pallets</td>
                            <td>{Converter.formatNumber(this.state.pallets.length, 0)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.dismiss()} color="primary">Cancel</Button>
            <Button onClick={this.submit.bind(this)} color="primary">Save</Button>
          </DialogActions>
        </Dialog>
      </Theme>
    )
  }
}

QuotePalletsImportDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
}

export default confirmable(QuotePalletsImportDialog);
