import React from 'react';
import { logout } from '../../shared/services/authentication_service';
import { getShipmentCount } from '../../shared/services/shipment_service';
import EventBus from '../../shared/components/event_bus';
import ErrorHandler from '../../shared/util/error_handler';
import ParentMenu from '../../shared/components/parent_menu';

class Menu extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      shipment_count: {}
    }
  }

  componentDidMount() {
    this.getShipmentCount();
    
    EventBus.on("updateShipment", (data) => {
      this.getShipmentCount();
    });
  }

  getShipmentCount() {
    getShipmentCount().then((shipment_count) => {
      if (shipment_count) {
        this.setState({ shipment_count: shipment_count });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getActiveShipmentsCount() {
    return this.state.shipment_count.opened + this.state.shipment_count.ready + this.state.shipment_count.received + this.state.shipment_count.in_transit + this.state.shipment_count.at_destination;
  }

  getSidebarItems() {
    var items = [];

    var servicesItem = {
      title: "Services",
      items: []
    };

    servicesItem.items.push(
      {
        name: "Dashboard",
        icon: "fas fa-chart-area",
        link: ""
      }
    );

    servicesItem.items.push(
      {
        name: "Shipments",
        icon: "fas fa-truck",
        link: "shipments/active",
        sub_items: [
          {
            name: "Active",
            link: "shipments/active",
            counter: this.getActiveShipmentsCount()
          },
          {
            name: "All",
            link: "shipments/all"
          }
        ]
      }
    );

    servicesItem.items.push(
      {
        name: "Warehouses",
        icon: "fas fa-warehouse",
        link: "warehouses",
        required_module: "warehouse"
      }
    );

    items.push(servicesItem);

    var companyItem = {
      title: "Company",
      items: []
    };

    companyItem.items.push(
      {
        name: "Account",
        icon: "fas fa-building",
        link: "account"
      }
    );

    items.push(companyItem);
    
    return items;
  }

  render() {
    return <ParentMenu
      children={this.props.children}
      location={this.props.location}
      topbar_items={[
        {
          name: "Settings",
          icon: "fas fa-cog",
          link: "settings"
        },
        {
          name: "Sign out",
          icon: "fas fa-sign-out-alt",
          onClick: () => logout()
        }
      ]}
      sidebar_items={ this.getSidebarItems() }
    />;
  }
}

export default Menu;
