import { getToken, setToken } from './authentication_service';

export function getHeaders() {
  return {
    headers: {
      'Authorization': 'Bearer ' + getToken(),
    },
  };
}

export function handleResponse(response) {
  try {
    checkStatus(response);
    checkNewAuthorization(response);
    return Promise.resolve(response.data);
  } catch(error) {
    return Promise.reject(error)
  }
}

export function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    var error = new Error(response)
    error.response = response
    throw error
  }
}

export function checkNewAuthorization(response) {
  if(response.headers && response.headers.authorization) {
    var token = response.headers.authorization;
    token = token.replace('Bearer ','');
    setToken(token)
  }
}
