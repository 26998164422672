import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import Icon from '@material-ui/core/Icon';
import Theme from './theme';
import alertDialog from './alert';
import Mapper from '../util/mapper'

class PalletDialog extends React.Component {

  constructor(props) {
    super();
    this.state = {
      pallet: props.pallet,
    };

    this.formRef = React.createRef();
  }

  handlePalletChange(property, e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.state.pallet[property] = value;
    this.setState({ pallet: this.state.pallet });
  }

  addHsCodeToPallet() {
    var hs_code = this.state.pallet.hs_code;
    if(!hs_code || (hs_code.length !== 8 && hs_code.length !== 10)) {
      alertDialog("Invalid HS code", "HS code must be exactly 8 or 10 digits");
      return;
    }

    const isnum = /^\d+$/.test(hs_code);
    if(!isnum) {
      alertDialog("Invalid HS code", "HS code must be digits only");
      return;
    }

    if(!this.state.pallet.hs_codes) {
      this.state.pallet.hs_codes = [];
    }
    this.state.pallet.hs_codes.push(hs_code);
    this.state.pallet.hs_code = "";
    this.setState({ pallet: this.state.pallet });
  }

  removeHsCodeFromPacakge(index) {
    this.state.pallet.hs_codes.splice(index, 1);
    this.setState({ pallet: this.state.pallet });
  }

  getTotalWeightOfPallet() {
    var weight = 0;
    if(this.state.pallet.quantity && this.state.pallet.weight_kg) {
      weight = Number(this.state.pallet.quantity) * Number(this.state.pallet.weight_kg);
    }
    return weight;
  }

  getTotalVolumeOfPallet() {
    var volume = 0;
    if(this.state.pallet.quantity && this.state.pallet.length_cm && this.state.pallet.width_cm && this.state.pallet.height_cm) {
      volume = Number(this.state.pallet.quantity) * (Number(this.state.pallet.length_cm) / 100) * (Number(this.state.pallet.width_cm) / 100) * (Number(this.state.pallet.height_cm) / 100);
    }
    return volume;
  }

  submit(e) {
    e.preventDefault();

    if(!this.formRef.current.checkValidity()) {
      this.formRef.current.reportValidity();
      return;
    }

    this.props.proceed(this.state.pallet)
  }

  render() {
    return (
      <Theme>
        <Dialog fullWidth={true} maxWidth={"lg"} open={this.props.show} onClose={this.props.dismiss} >
          <DialogTitle>{this.props.title}</DialogTitle>
          <DialogContent>
            <form autoComplete="off" ref={this.formRef} onSubmit={this.submit.bind(this)}>
              <div key={this.state.pallet.key} className="list-row-item">
                  <span className="list-row-item-header-text">Pallet #{this.state.pallet.index + 1}</span>

                  <div className="row">
                    <div className="col-md-4 col-lg-4">
                      <TextField
                        label="Length (cm)"
                        variant="outlined"
                        required={this.props.dimension_required}
                        fullWidth
                        type="number"
                        margin="dense"
                        value={this.state.pallet.length_cm}
                        onChange={(e) => {this.handlePalletChange("length_cm", e)}}
                      />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <TextField
                        label="Width (cm)"
                        variant="outlined"
                        required={this.props.dimension_required}
                        fullWidth
                        type="number"
                        margin="dense"
                        value={this.state.pallet.width_cm}
                        onChange={(e) => {this.handlePalletChange("width_cm", e)}}
                      />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <TextField
                        label="Height (cm)"
                        variant="outlined"
                        required={this.props.dimension_required}
                        fullWidth
                        type="number"
                        margin="dense"
                        value={this.state.pallet.height_cm}
                        onChange={(e) => {this.handlePalletChange("height_cm", e)}}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 col-lg-4">
                      <TextField
                        label="Weight (kg)"
                        variant="outlined"
                        required={this.props.dimension_required}
                        fullWidth
                        type="number"
                        margin="dense"
                        value={this.state.pallet.weight_kg}
                        onChange={(e) => {this.handlePalletChange("weight_kg", e)}}
                      />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <TextField
                        className='hs-code-input'
                        label="HS code"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={this.state.pallet.hs_code}
                        onChange={(e) => {this.handlePalletChange("hs_code", e)}}
                        inputProps={{
                          minLength: 0,
                          maxLength: 10,
                        }}
                        InputProps={{
                          endAdornment: 
                          <Button
                            className='hs-code-add'
                            variant="outlined"
                            color="primary"
                            onClick={(e) => {this.addHsCodeToPallet(e)}}
                            startIcon={<Icon>add</Icon>}>
                            Add
                          </Button>
                        }}
                      />
                      {this.state.pallet.hs_codes && this.state.pallet.hs_codes.map((hs_code, hs_code_index) => {
                        return (
                          <Chip
                            className='hs-code-chip'
                            color="primary"
                            label={hs_code}
                            onDelete={(e) => {this.removeHsCodeFromPacakge(hs_code_index, e)}}
                          />
                        );
                      })}
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <FormControl
                        variant="outlined"
                        margin="dense"
                        fullWidth>
                        <InputLabel>{this.props.dimension_required ? "Stackable *" : "Stackable"}</InputLabel>
                        <Select
                          native
                          required={this.props.dimension_required}
                          label="Stackable"
                          value={this.state.pallet.stackable}
                          name="stackable"
                          onChange={(e) => {this.handlePalletChange("stackable", e)}} >
                            <option key={"package_stackable_empty"} value=""></option>
                            {Object.keys(Mapper.booleans()).map(type => {
                              return (<option key={"package_stackable_"+type} value={type}>{Mapper.fromBoolean(type)}</option>);
                            })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                </div>
              </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.dismiss()} color="primary">Cancel</Button>
            <Button onClick={this.submit.bind(this)} color="primary">Save</Button>
          </DialogActions>
        </Dialog>
      </Theme>
    )
  }
}

PalletDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
  title: PropTypes.string,
  pallet: PropTypes.object,
  dimension_required: PropTypes.bool
}

export default confirmable(PalletDialog);
