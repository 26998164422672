import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import PackagesTable from '../../../../../shared/components/packages_table.js';
import alertDialog from '../../../../../shared/components/alert.js';
import confirmOutbound from '../../../../../shared/components/confirm_outbound.js'
import MatchUtil from '../../../../util/match_util.js';

class ColliTab extends React.Component {

  constructor(props) {
    super(props)

    this.setInboundShipments(props.shipment, props.stock);
    
    this.state = {
      shipment: props.shipment,
      stock: props.stock,
      package_number: "",
      shipment_id: ""
    };
  }

  onKeyDown(e) {
    if (e.key === 'Enter') {
      if(e.target.name === 'package_number') {
        this.getPackage(e);
      }
      if(e.target.name === 'shipment_id') {
        this.getShipment(e);
      }
    }
  }

  setInboundShipments(shipment, stock) {
    for(const _package of shipment.colli) {
      var package_shipment_ids = [];

      for(const part of _package.parts) {
        var part_shipment_ids = [];
        var stock_match = stock.find(s => 
          s.package_number === _package.package_number 
          && s.part_number === part.part_number
          && s.order_number === part.order_number
        );
        if(stock_match) {
          for(const history of stock_match.history) {
            if(history.type === 'in') {
              part_shipment_ids.push(history.shipment_id);
              package_shipment_ids.push(history.shipment_id);
            }
          }
        }
        part.inbound_shipment = [...new Set(part_shipment_ids)].join(", ");
      }

      _package.inbound_shipment = [...new Set(package_shipment_ids)].join(", ");
    }
  }

  handleChange(e) {
    var value = e.target.value;
    if(e.target.name === 'shipment_id') {
      value = value.replaceAll('=', '-');
    }
    this.setState({ [e.target.name]: value });
  }

  getPackage(e) {
    e.preventDefault();

    if(!this.state.package_number) {
      return;
    }

    var _package = this.findPackage(this.state.package_number)
    if(!_package) {
      alertDialog("Package not found", `Package number ${this.state.package_number} not found.`);
      return;
    }

    var stock_parts = [];
    if(this.state.stock) {
      for(const stock_part of this.state.stock) {
        if(stock_part.package_number === _package.package_number) {
          stock_parts.push(stock_part);
        }
      }
    }
    
    const clone = JSON.parse(JSON.stringify(_package));
    const stock_parts_clone = JSON.parse(JSON.stringify(stock_parts));
    confirmOutbound(`Package ${_package.package_number}`, [ clone ], stock_parts_clone).then(() => {
      _package.ready_date = new Date();
      _package.loading_status = 'complete';
      for(const part of _package.parts) {
        part.ready_date = new Date();
        part.loading_status = 'complete';
        part.loading_quantity = part.quantity;
      }
    
      this.setState({ package_number: "" })
      this.props.updateShipment(this.state.shipment, true);
    });
  }

  findPackage(number) {
    var matches = MatchUtil.find(this.state.shipment.colli, "package_number", number);
    if(matches.length === 1) {
      return matches[0];
    }
  }

  getShipment(e) {
    e.preventDefault();

    if(!this.state.shipment_id) {
      return;
    }

    var stock_parts = this.findShipmentStockParts(this.state.shipment_id)
    if(!stock_parts || stock_parts.length === 0) {
      alertDialog("Shipment not found", `Shipment ${this.state.shipment_id} not found.`);
      return;
    }

    var package_numbers = [];
    for(const stock_part of stock_parts) {
      if(!package_numbers.find(p => p === stock_part.package_number)) {
        package_numbers.push(stock_part.package_number);
      }
    }

    var shipment_packages = [];
    for(const package_number of package_numbers) {
      var shipment_package = this.state.shipment.colli.find(p => p.package_number === package_number);
      if(shipment_package) {
        shipment_packages.push(shipment_package);
      }
    }

    const clone = JSON.parse(JSON.stringify(shipment_packages));
    const stock_parts_clone = JSON.parse(JSON.stringify(stock_parts));
    confirmOutbound(`${shipment_packages.length} packages of shipment ${this.state.shipment_id}`, clone, stock_parts_clone).then(() => {
      for(const shipment_package of shipment_packages) {      
        shipment_package.ready_date = new Date();
        shipment_package.loading_status = 'complete';
        for(const part of shipment_package.parts) {
          part.ready_date = new Date();
          part.loading_status = 'complete';
          part.loading_quantity = part.quantity;
        }
      }
      
      this.setState({ shipment_id: "" })
      this.props.updateShipment(this.state.shipment, true);
    });
  }

  findShipmentStockParts(shipment_id) {
    var stock_parts = [];
    
    if(this.state.stock) {
      for(const stock_part of this.state.stock) {
        for(const history of stock_part.history) {
          if(history.type === 'in' && history.shipment_id.toUpperCase() === shipment_id.toUpperCase()) {
            stock_parts.push(stock_part);
            continue;
          }
        }
      }
    }
    
    return stock_parts;
  }

  getCount(status) {
    var count = 0;

    for(const _package of this.state.shipment.colli) {
      if(_package.loading_status === status) {
        count++;
      }
    }

    return count;
  }

  render() {
    return (
      <div>        
        <div className="row row-small">
          <div className="col-md-12 col-lg-6">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td width={200} className="header-text">To do</td>
                  <td>{this.getCount("todo")}</td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Complete</td>
                  <td>{this.getCount("complete")}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row row-small">
          <div className="col-md-3">
            <span className='text-padding'>Scan or enter package number..</span>
            <div className='file-input-download-parent'>
              <TextField
                label="Package number"
                variant="outlined"
                fullWidth
                required
                autoFocus
                margin="dense"
                name="package_number"
                value={this.state.package_number}
                onKeyDown={this.onKeyDown.bind(this)}
                onChange={this.handleChange.bind(this)} 
              />
              <Button
                className="button-icon-only button-inline clear-form-button"
                color="primary"
                type="button"
                disabled={!this.state.package_number}
                onClick={this.getPackage.bind(this)}
                startIcon={<Icon>search</Icon>} />
            </div>                      
          </div>
          <div className="col-md-3">
            <span className='text-padding'>Or enter shipment ID..</span>
            <div className='file-input-download-parent'>
              <TextField
                label="Shipment ID"
                variant="outlined"
                fullWidth
                required
                margin="dense"
                name="shipment_id"
                value={this.state.shipment_id}
                onKeyDown={this.onKeyDown.bind(this)}
                onChange={this.handleChange.bind(this)} 
              />
              <Button
                className="button-icon-only button-inline clear-form-button"
                color="primary"
                type="button"
                disabled={!this.state.shipment_id}
                onClick={this.getShipment.bind(this)}
                startIcon={<Icon>search</Icon>} />
            </div>                      
          </div>
        </div>
        
        <div className="row">
          <div className="col-sm">
            <PackagesTable
                name="shipment_outbound_packages_table_state"
                data={this.state.shipment}
                headers={[
                  "number",
                  "loading_number",
                  "inbound_shipment",
                  "order_number",
                  "quantity",
                  "loading_quantity",
                  "scan_loading_status",
                  "reference",
                  "part_price",
                  "country_of_origin",
                  "description",
                  "ready_date"
                ]}
                groupDefaultExpanded={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ColliTab;
