import { DefaultApi } from "generated-quotes-api";
import { getHeaders, handleResponse } from './web_service';

export const API = new DefaultApi({
  basePath: window.appConfig.API_HOSTNAME
});

export function getQuote(id) {
  return API.getQuote(id, getHeaders()).then(handleResponse);
}

export function getQuotes(page, size, sort, filter) {
  return API.getQuotes(page, size, sort, filter, getHeaders()).then(handleResponse);
}

export function getQuoteCount() {
  return API.getQuoteCount(getHeaders()).then(handleResponse);
}

export function createQuote(data) {
  return API.createQuote(data, getHeaders()).then(handleResponse);
}

export function updateQuote(id, data) {
  return API.updateQuote(id, data, getHeaders()).then(handleResponse);
}

export function acceptQuote(id, data) {
  return API.acceptQuote(id, data, getHeaders()).then(handleResponse);
}

export function declineQuote(id, data) {
  return API.declineQuote(id, data, getHeaders()).then(handleResponse);
}

export function calculateQuoteCosts(data) {
  return API.calculateQuoteCosts(data, getHeaders()).then(handleResponse);
}

export function priceQuote(id, data) {
  return API.priceQuote(id, data, getHeaders()).then(handleResponse);
}

export function commentQuote(id, data) {
  return API.commentQuote(id, data, getHeaders()).then(handleResponse);
}

export function deleteQuote(id) {
  return API.deleteQuote(id, getHeaders()).then(handleResponse);
}

export function getPalletsExampleDocument() {
  var headers = getHeaders();
  headers['responseType'] = 'blob';
  return API.getPalletsExampleDocument(headers).then(handleResponse);
}

export function readPalletsDocument(file, progress_event) {
  var headers = getHeaders();
  headers['content-type'] = 'multipart/form-data';
  headers['onUploadProgress'] = progress_event;
  return API.readPalletsDocument(file, headers).then(handleResponse);
}

export function exportPalletsToDocument(id) {
  var headers = getHeaders();
  headers['responseType'] = 'blob';
  return API.exportPalletsToDocument(id, headers).then(handleResponse);
}

export function getColliExampleDocument() {
  var headers = getHeaders();
  headers['responseType'] = 'blob';
  return API.getColliExampleDocument(headers).then(handleResponse);
}

export function readColliDocument(file, merge, progress_event) {
  var headers = getHeaders();
  headers['content-type'] = 'multipart/form-data';
  headers['onUploadProgress'] = progress_event;
  return API.readColliDocument(file, merge, headers).then(handleResponse);
}

export function exportColliToDocument(id) {
  var headers = getHeaders();
  headers['responseType'] = 'blob';
  return API.exportColliToDocument(id, headers).then(handleResponse);
}
