import React from 'react';
import { getShipmentDocument, uploadShipmentDocument } from '../services/shipment_service';
import MultiDocumentUpload from './multi_document_upload';

class MultiShipmentDocumentUpload extends React.Component {

  uploadDocument(file, progressEvent) {
    return uploadShipmentDocument(this.props.shipment_id, file, this.props.access, progressEvent);
  }

  documentsUpdated(documents) {
    this.props.documentsUpdated(documents);
  }

  downloadDocument(document) {
    return getShipmentDocument(this.props.shipment_id, document.id);
  }

  render() {
    return(
      <MultiDocumentUpload
        title={this.props.title}
        documents={this.props.documents}
        required={this.props.required}
        uploadDocument={this.uploadDocument.bind(this)}
        documentsUpdated={this.documentsUpdated.bind(this)}
        downloadDocument={this.downloadDocument.bind(this)}
      />
    );
  }
}

export default MultiShipmentDocumentUpload;
