import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getAccount } from '../../../shared/services/account_service';
import { getInvoiceGroup, uploadInvoiceGroupDocument, getInvoiceGroupDocument } from '../../../shared/services/invoice_service.js';
import InvoiceContainer from '../../../shared/components/invoicing_container.js';
import ErrorHandler from '../../../shared/util/error_handler';
import LoadingText from '../../../shared/components/loading_text';
import Mapper from '../../../shared/util/mapper.js';
import AsyncButton from '../../../shared/components/async_button';

class InvoiceGroup extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      account_id: this.props.match.params.account_id,
      invoice_group_id: this.props.match.params.invoice_group_id,
      invoice_group: {},
      counterparty: {}
    };
  }

  componentWillMount() {
    this.getInvoiceGroup();
    this.getCounterparty();
  }

  getInvoiceGroup() {
    getInvoiceGroup(this.state.invoice_group_id).then((invoice_group) => {
      if (invoice_group) {
        this.setState({ invoice_group: invoice_group });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getCounterparty() {
    getAccount(this.state.account_id).then((counterparty) => {
      if (counterparty) {
        this.setState({ counterparty: counterparty });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }
  
  editInvoiceGroup(e) {
    e.preventDefault();
    this.props.history.push(`/accounts/${this.state.account_id}/invoice-groups/${this.state.invoice_group_id}/edit${this.props.location.search}`);
  }

  uploadDocument(file, progressEvent) {
    return uploadInvoiceGroupDocument(this.state.invoice_group_id, file, "nobody", progressEvent);
  }

  downloadDocument(document) {
    return getInvoiceGroupDocument(this.state.invoice_group_id, document.id);
  }
  
  render() {
    return (
      <div>
        
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`/accounts` + this.props.location.search}>Accounts</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`/accounts/${this.state.account_id}` + this.props.location.search}>{this.state.counterparty.name}</Link></BreadcrumbItem>
            <BreadcrumbItem>Invoice groups</BreadcrumbItem>
            <BreadcrumbItem active>{this.state.invoice_group.name}</BreadcrumbItem>
          </Breadcrumb>
          
          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Invoice group</label>
                </div>
              </div>
            </div>
            <div className="row row-small">
            <div className="col-md">
              <AsyncButton
                variant="outlined"
                color="secondary"
                onClick={this.editInvoiceGroup.bind(this)}
                icon="edit"
                text="Edit" />
            </div>
          </div>
            <div className="row row-small">
              <div className="col-md-6 col-lg-6">
                <table className="table table-details" >
                  <tbody>
                    <tr className="row-no-top-border-and-padding">
                      <td width={200} className="header-text">Name</td>
                      <td><LoadingText text={this.state.invoice_group.name} /></td>
                    </tr>
                    <tr>
                      <td width={200} className="header-text">Status</td>
                      <td><LoadingText text={Mapper.fromInvoiceGroupStatus(this.state.invoice_group.status)} /></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          
          <InvoiceContainer
            title={"Invoicing"}
            resource_type="invoice_group"
            resource_id={this.state.invoice_group_id}
            counterparty={this.state.counterparty}
            uploadDocument={this.uploadDocument.bind(this)}
            downloadDocument={this.downloadDocument.bind(this)}
          />
        
        </div>
      </div >
    );
  }
}

export default InvoiceGroup;
