import React from 'react';
import { Link } from 'react-router-dom';
import DomUtil from '../../../shared/util/dom_util';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import { getQuote, updateQuote } from '../../../shared/services/quote_service';
import { getCountries, getPorts } from '../../../shared/services/location_service';
import { getWarehouses } from '../../../shared/services/warehouse_service.js';
import { getCurrentAccount } from '../../../shared/services/account_service';
import ErrorHandler from '../../../shared/util/error_handler';
import Mapper from '../../../shared/util/mapper'
import confirm from '../../../shared/components/confirm.js'
import QuoteForm from './components/form'
import EventBus from '../../../shared/components/event_bus';
import { hasModule } from '../../../shared/services/environment_service';

class EditQuote extends React.Component {

  constructor(props) {
    super(props)
    this.updateQuote = this.updateQuote.bind(this);

    this.state = {
      quote_id: this.props.match.params.quote_id,
      quote: undefined,
      countries: undefined,
      warehouses: undefined,
      ports: undefined,
      account: undefined,
    };
  }

  componentWillMount() {
    this.getQuote();
    this.getCountries();
    this.getPorts();
    this.getCurrentAccount();
    if(hasModule("warehouse")) {
      this.getWarehouses();
    } else {
      this.setState({ warehouses: [] });
    }
  }

  getQuote() {
    getQuote(this.state.quote_id).then((quote) => {
      if (quote) {
        this.setState({ quote: quote });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getCountries() {
    getCountries().then((countries) => {
      if (countries) {
        this.setState({ countries: countries });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getWarehouses() {
    getWarehouses().then((warehouses) => {
      if (warehouses) {
        this.setState({ warehouses: warehouses });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getPorts() {
    getPorts().then((ports) => {
      if (ports) {
        this.setState({ ports: ports });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getCurrentAccount() {
    getCurrentAccount().then((account) => {
      if (account) {
        this.setState({ account: account });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }
  
  updateQuote(e) {
    e.preventDefault();
    var form = e.currentTarget;
    confirm("Please confirm", "Are you sure to update this quote?").then(() => {
      DomUtil.disableFormSubmitButtonClass(form);
      var patch = Mapper.toQuoteRequest(this.state.quote);

      updateQuote(this.state.quote_id, patch).then(() => {
        EventBus.dispatch("updateQuote");
        this.props.history.push(`${this.getBasePath()}/${this.state.quote_id}`);
      }).catch(error => {
        ErrorHandler.showError(error);
        DomUtil.enableFormSubmitButtonClass(form);
      });
    });
  }

  isLoaded() {
    return this.state.countries &&
      this.state.warehouses &&
      this.state.ports &&
      this.state.account &&
      this.state.quote;
  }

  isActivePath() {    
    return this.props.location.pathname.startsWith('/quotes/active');
  }

  getBasePath() {
    if(this.isActivePath()) {
      return '/quotes/active';
    }
    return '/quotes/all';
  }

  getBreadCrumbTitle() {
    if(this.isActivePath()) {
      return 'Active Quotes';
    }
    return 'All Quotes';
  }

  render() {
    return (
      <div>

        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.getBasePath()}/${this.props.location.search}`}>{this.getBreadCrumbTitle()}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.getBasePath()}/${this.props.match.params.quote_id}${this.props.location.search}`}>{this.props.match.params.quote_id}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Edit</BreadcrumbItem>
          </Breadcrumb>
          
          {this.isLoaded() &&
            <QuoteForm
              title={"Update Quote"}
              countries={this.state.countries}
              warehouses={this.state.warehouses}
              ports={this.state.ports}
              account={this.state.account}
              quote={this.state.quote}
              saveQuote={this.updateQuote}
            />
          }
          
          {!this.isLoaded() &&
            <div className="container-body">
              <div className="row">
                <div className="col-md">
                  <div className="form-header">
                    <label>Update Quote</label>
                  </div>
                </div>
              </div>          
              <div className="row row-small">
                <div className="col-md-12 col-lg-8">
                  <Skeleton count={5}/>
                </div>
              </div>
            </div>
          }

        </div>
      </div>
    );
  }
}

export default EditQuote;
