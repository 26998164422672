import React from 'react';

class TableUtil extends React.Component {

  static getTextColumn(field, headerName, cellRenderer, params = {}) {
    return {
      field: field,
      headerName: headerName,
      minWidth:  params.minWidth ? params.minWidth : 150,
      width: params.width,
      cellRenderer: cellRenderer,
      filter: params.disable ? undefined : "agTextColumnFilter",
      filterParams: {
        maxNumConditions: 1,
        filterOptions: this.getTextColumnFilters()
      },
      floatingFilter: true,
      sortable:  params.disable ? false : true,
      hide: params.hide,
      lockVisible: params.lockVisible,
      pinned: params.pinned,
    };
  }

  static getNumberColumn(field, headerName, cellRenderer, params = {}) {
    return {
      field: field,
      headerName: headerName,
      minWidth: 150,
      cellRenderer: cellRenderer,
      filter: params.disable ? undefined : "agNumberColumnFilter",
      filterParams: {
        maxNumConditions: 1,
        filterOptions: this.getNumberColumnFilters()
      },
      floatingFilter: true,
      sortable:  params.disable ? false : true,
      hide: params.hide,
      lockVisible: params.lockVisible,
      pinned: params.pinned,
    };
  }

  static getSetColumn(field, headerName, cellRenderer, values, filterNameFunction, params = {}) {
    return {
      field: field,
      headerName: headerName,
      minWidth: 150,
      cellRenderer: cellRenderer,
      filter: params.disable ? undefined : "agSetColumnFilter",
      filterParams: {
        maxNumConditions: 1,
        values: values,
        suppressSorting: true,
        valueFormatter: (params) => filterNameFunction(params.value)
      },
      floatingFilter: true,
      sortable:  params.disable ? false : true,
      hide: params.hide,
      lockVisible: params.lockVisible,
      pinned: params.pinned,
    };
  }

  static getDateColumn(field, headerName, cellRenderer, params = {}) {
    return {
      field: field,
      headerName: headerName,
      minWidth: 150,
      cellRenderer: cellRenderer,
      filter: params.disable ? undefined : "agDateColumnFilter",
      filterParams: {
        maxNumConditions: 1,
        filterOptions: this.getDateColumnFilters()
      },
      floatingFilter: true,
      sortable:  params.disable ? false : true,
      hide: params.hide,
      lockVisible: params.lockVisible,
      pinned: params.pinned,
    };
  }

  getLoadingFilter(params) {
    if (!params.value) {
        return '(Blanks)';
    } else if (params.value === '(Select All)') {
        return params.value;
    } else {
      return params.value;
    }
  }

  static getTextColumnFilters() {
    return [
      'contains',
      {
          displayKey: 'does_not_contain',
          displayName: 'Does not contain',
          predicate: ([filterValue], cellValue) => cellValue !== filterValue
      },
      'equals',
      {
          displayKey: 'does_not_equal',
          displayName: 'Does not equal',
          predicate: ([filterValue], cellValue) => cellValue !== filterValue
      },
      'blank',
      {
          displayKey: 'not_blank',
          displayName: 'Not blank',
          predicate: ([fv1, fv2], cellValue) => cellValue !== null,
          numberOfInputs: 0,
      }
    ];
  }

  static getNumberColumnFilters() {
    return [
      'equals',
      {
          displayKey: 'does_not_equal',
          displayName: 'Does not equal',
          predicate: ([filterValue], cellValue) => cellValue !== filterValue
      },
      {
          displayKey: 'greater_than',
          displayName: 'Greater than',
          predicate: ([filterValue], cellValue) => cellValue > filterValue
      },
      {
          displayKey: 'greater_than_equals',
          displayName: 'Greater than or equals',
          predicate: ([filterValue], cellValue) => cellValue >= filterValue
      },
      {
          displayKey: 'less_than',
          displayName: 'Less than',
          predicate: ([filterValue], cellValue) => cellValue < filterValue
      },
      {
          displayKey: 'less_than_equals',
          displayName: 'Less than or equals',
          predicate: ([filterValue], cellValue) => cellValue <= filterValue
      },
      'blank',
      {
          displayKey: 'not_blank',
          displayName: 'Not blank',
          predicate: ([fv1, fv2], cellValue) => cellValue !== null,
          numberOfInputs: 0,
      }
    ];
  }

  static getDateColumnFilters() {
    return [
      'equals',
      {
          displayKey: 'does_not_equal',
          displayName: 'Does not equal',
          predicate: ([filterValue], cellValue) => cellValue !== filterValue
      },
      {
          displayKey: 'less_than',
          displayName: 'Before',
          predicate: ([filterValue], cellValue) => cellValue <= filterValue
      },
      {
          displayKey: 'greater_than',
          displayName: 'After',
          predicate: ([filterValue], cellValue) => cellValue >= filterValue
      },
      'blank',
      {
          displayKey: 'not_blank',
          displayName: 'Not blank',
          predicate: ([fv1, fv2], cellValue) => cellValue !== null,
          numberOfInputs: 0,
      }
    ];
  }
}

export default TableUtil;
