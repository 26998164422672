import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Theme from './theme';
import Mapper from '../util/mapper.js';

class QuestionDialog extends React.Component {

  constructor(props) {
    super();
    this.state = {
      answer: undefined
    };

    this.formRef = React.createRef();
  }

  submit(e) {
    e.preventDefault();

    if(!this.formRef.current.checkValidity()) {
      this.formRef.current.reportValidity();
      return;
    }

    this.props.proceed({ answer: this.state.answer === "true" })
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <Theme>
        <Dialog open={this.props.show} onClose={this.props.dismiss} >
          <DialogTitle>{this.props.title}</DialogTitle>
          <DialogContent>
            <form autoComplete="off" ref={this.formRef} onSubmit={this.submit.bind(this)}>

              <div className="row">
                <div className="col-md-12">

                  <div className="row">
                    <div className="col-md-12">
                      <span>{this.props.message}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <FormControl
                        variant="outlined"
                        margin="dense"
                        fullWidth>
                        <InputLabel>Answer *</InputLabel>
                        <Select
                          native
                          required
                          autoFocus
                          label="Answer"
                          value={this.state.answer}
                          name="answer"
                          onChange={this.handleChange.bind(this)}  >
                            <option key={"answer_empty"} value=""></option>
                            {Object.keys(Mapper.booleans()).map(type => {
                              return (<option key={"answer_"+type} value={type}>{Mapper.fromBoolean(type)}</option>);
                            })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                </div>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.dismiss()} color="primary">Cancel</Button>
            <Button onClick={this.submit.bind(this)} color="primary">Save</Button>
          </DialogActions>
        </Dialog>
      </Theme>
    )
  }
}

QuestionDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
  title: PropTypes.string,         // arguments of your confirm function
  message: PropTypes.string,       // arguments of your confirm function
  options: PropTypes.object        // arguments of your confirm function
}

export default confirmable(QuestionDialog);
