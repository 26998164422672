import React from 'react';
import Mapper from '../../../util/mapper.js';
import Converter from '../../../util/converter.js'
import LoadingContent from '../../../components/loading_content.js';

class CargoContainer extends React.Component {

  render() {
    return (
      <div className="container-body">
        <div className="row">
          <div className="col-md">
            <div className="form-header">
              <label>Cargo</label>
            </div>
          </div>
        </div>

        <div className="row row-small">
          <div className="col-md-6 col-lg-6">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td width={200} className="header-text">Cargo value</td>
                  <td><LoadingContent mustBeLoaded={this.props.quote} content={() => Converter.toCurrency("eur", this.props.quote.cargo_value)} /></td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Cargo description</td>
                  <td><LoadingContent mustBeLoaded={this.props.quote} content={() => <span style={{ whiteSpace: "pre", textWrap: "wrap" }}>{this.props.quote.cargo_description}</span>} /></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6 col-lg-6">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td width={200} className="header-text">Insurance</td>
                  <td><LoadingContent mustBeLoaded={this.props.quote} content={() => Mapper.fromInsuranceType(this.props.quote.insurance)} /></td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Transport type</td>
                  <td><LoadingContent mustBeLoaded={this.props.quote} content={() => Mapper.fromTransportType(this.props.quote.transport_type)} /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default CargoContainer;
