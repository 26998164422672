import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Quotes from "./components/quotes";

class ActiveQuotes extends React.Component {

  openQuote(event) {
    var newTab = event.event.ctrlKey || event.event.metaKey;
    var url = `/quotes/active/${event.data.id}${this.props.location.search}`;
    if(newTab) {
      window.open(url, "_blank", "noreferrer");
    } else {
      this.props.history.push(url);
    }
  }

  newQuote() {
    this.props.history.push(`/quotes/active/create${this.props.location.search}`);
  }
  
  render() {
    return (
      <div>
        
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem active>Active Quotes</BreadcrumbItem>
          </Breadcrumb>

          <Quotes
            location={this.props.location}
            history={this.props.history}
            statuses={["pending", "quoted"]}
            openQuote={this.openQuote.bind(this)}
            newQuote={this.newQuote.bind(this)}
            emptyMessage="There are no active quotes"
          />

        </div>
      </div >
    );
  }
}

export default ActiveQuotes;
