import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Theme from './theme';
import Mapper from '../util/mapper'

class ContainerDialog extends React.Component {

  constructor(props) {
    super();
    this.state = {
      container: props.container,
    };

    this.formRef = React.createRef();
  }

  handlContainerChange(property, e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.state.container[property] = value;
    this.setState({ container: this.state.container });
  }

  submit(e) {
    e.preventDefault();

    if(!this.formRef.current.checkValidity()) {
      this.formRef.current.reportValidity();
      return;
    }

    this.props.proceed(this.state.container)
  }

  getUniqueKey() {
    return new Date().getTime() + Math.random();
  }

  containsField(name) {
    return this.props.fields && this.props.fields.includes(name);
  }

  render() {
    return (
      <Theme>
        <Dialog fullWidth={true} maxWidth={"md"} open={this.props.show} onClose={this.props.dismiss} >
          <DialogTitle>{this.props.title}</DialogTitle>
          <DialogContent>
            <form autoComplete="off" ref={this.formRef} onSubmit={this.submit.bind(this)}>
              <div key={this.state.container.key} className="list-row-item">
                  <span className="list-row-item-header-text">Container #{this.state.container.index + 1}</span>

                  { this.containsField("quantity") &&
                    <div className="row">
                      { this.containsField("quantity") &&
                        <div className="col-md-6 col-lg-6">
                          <TextField
                            label="Quantity"
                            variant="outlined"
                            required
                            fullWidth
                            type="number"
                            margin="dense"
                            value={this.state.container.quantity}
                            onChange={(e) => {this.handlContainerChange("quantity", e)}}
                          />
                        </div>
                      }
                    </div>
                  }

                  { (this.containsField("container_number") || this.containsField("net_weight_kg")) &&
                    <div className="row">
                      { this.containsField("container_number") &&
                        <div className="col-md-6 col-lg-6">
                          <TextField
                            label="Container number"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            value={this.state.container.container_number}
                            onChange={(e) => {this.handlContainerChange("container_number", e)}}
                          />
                        </div>
                      }
                      { this.containsField("net_weight_kg") &&
                        <div className="col-md-6 col-lg-6">
                          <TextField
                            label="Net weight (kg)"
                            variant="outlined"
                            fullWidth
                            type="number"
                            margin="dense"
                            value={this.state.container.net_weight_kg}
                            onChange={(e) => {this.handlContainerChange("net_weight_kg", e)}}
                          />
                        </div>
                      }
                    </div>
                  }
                    
                  { (this.containsField("size") || this.containsField("type")) &&
                    <div className="row">
                      { this.containsField("size") &&
                        <div className="col-md-6 col-lg-6">
                          <FormControl
                            variant="outlined"
                            margin="dense"
                            fullWidth>
                            <InputLabel>Size *</InputLabel>
                            <Select
                              native
                              required
                              label="Size *"
                              value={this.state.container.size}
                              name="size"
                              onChange={(e) => {this.handlContainerChange("size", e)}} >
                                <option key={"container_size_empty"} value=""></option>
                                {Object.keys(Mapper.containerSizes()).map(container_size => {
                                  return (<option key={"container_size_"+container_size} value={container_size}>{Mapper.fromContainerSize(container_size)}</option>);
                                })}
                            </Select>
                          </FormControl>
                        </div>
                      }
                      { this.containsField("type") &&
                        <div className="col-md-6 col-lg-6">
                          <FormControl
                            variant="outlined"
                            margin="dense"
                            fullWidth>
                            <InputLabel>Type *</InputLabel>
                            <Select
                              native
                              required
                              label="Type *"
                              value={this.state.container.type}
                              name="type"
                              onChange={(e) => {this.handlContainerChange("type", e)}} >
                                <option key={"container_type_empty"} value=""></option>
                                {Object.keys(Mapper.containerTypes()).map(container_type => {
                                  return (<option key={"container_type_"+container_type} value={container_type}>{Mapper.fromContainerType(container_type)}</option>);
                                })}
                            </Select>
                          </FormControl>
                        </div>
                      }
                    </div>
                  }
                  
                </div>
              </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.dismiss()} color="primary">Cancel</Button>
            <Button onClick={this.submit.bind(this)} color="primary">Save</Button>
          </DialogActions>
        </Dialog>
      </Theme>
    )
  }
}

ContainerDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
  title: PropTypes.string,
  container: PropTypes.object,
  fields: PropTypes.array
}

export default confirmable(ContainerDialog);
