import { DefaultApi } from "generated-locations-api";
import { getHeaders, handleResponse } from './web_service';

export const API = new DefaultApi({
  basePath: window.appConfig.API_HOSTNAME
});

export function getCountries() {
  return API.getCountries(getHeaders()).then(handleResponse);
}

export function getPorts() {
  return API.getPorts(getHeaders()).then(handleResponse);
}
