import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import Theme from './theme';

class OutboundDialog extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  getParts(_package) {
    var parts = [];
    for(const shipment_part of _package.parts) {
      shipment_part.shipment_quantity = shipment_part.quantity;
      shipment_part.warehouse_quantity = 0;
      parts.push(shipment_part);
    }

    var stock_parts_of_box = this.props.stock_parts.filter(stock_part => _package.package_number === stock_part.package_number)

    for(const stock_part of stock_parts_of_box) {
      var part = parts.find(p => p.part_number === stock_part.part_number && p.order_number === stock_part.order_number);

      if(part) {
        part.warehouse_quantity = stock_part.in_quantity;
        part.fixed = !this.isInvalid(part);
      } else {
        stock_part.shipment_quantity = 0;
        stock_part.warehouse_quantity = stock_part.in_quantity;
        stock_part.fixed = !this.isInvalid(stock_part);
        parts.push(stock_part);
      }
    }
    return parts;
  }

  submit(e) {
    e.preventDefault();

    if(!this.formRef.current.checkValidity()) {
      this.formRef.current.reportValidity();
      return;
    }

    this.props.proceed();
  }

  isInvalid(part) {
    return Number(part.shipment_quantity) !== Number(part.warehouse_quantity);
  }

  render() {
    return (
      <Theme>
        <Dialog fullWidth={true} maxWidth={"lg"} open={this.props.show} onClose={this.props.dismiss} >
          <DialogTitle>{this.props.title}</DialogTitle>
          <DialogContent>
            <form autoComplete="off" ref={this.formRef} onSubmit={this.submit.bind(this)}>

              {this.props.packages.map((_package) => {
                return (
                  <div key={_package.package_number} className="list-row-item">
                    <span className="list-row-item-header-text">Package {_package.package_number} - Parts overview</span>

                    <div className="row">
                      <div className="col-md-7 col-lg-7">
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <span>Shipment quantity</span>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <span>Warehouse quantity</span>
                      </div>
                      <div className="col-md-1 col-lg-1">
                        <span>Resolved?</span>
                      </div>
                    </div>

                    {this.getParts(_package).map((part, index) => {
                      return (
                        <div key={_package.key + part.key}>

                          <div className="row">
                            <div className="col-md-2 col-lg-2">
                              <TextField
                                label="Part number"
                                variant="outlined"
                                fullWidth
                                disabled
                                margin="dense"
                                value={part.part_number}
                              />
                            </div>
                            <div className="col-md-2 col-lg-2">
                              <TextField
                                label="Order number"
                                variant="outlined"
                                fullWidth
                                disabled
                                margin="dense"
                                value={part.order_number}
                              />
                            </div>
                            <div className="col-md-3 col-lg-3">
                              <TextField
                                label="Reference"
                                variant="outlined"
                                fullWidth
                                disabled
                                margin="dense"
                                value={part.reference}
                              />
                            </div>
                            <div className="col-md-2 col-lg-2">
                              <TextField
                                error={this.isInvalid(part)}
                                label="Shipment quantity"
                                variant="outlined"
                                fullWidth
                                type='number'
                                disabled
                                margin="dense"
                                value={part.shipment_quantity}
                              />
                            </div>
                            <div className="col-md-2 col-lg-2">
                              <TextField
                                error={this.isInvalid(part)}
                                label="Warehouse quantity"
                                variant="outlined"
                                fullWidth
                                type='number'
                                disabled
                                margin="dense"
                                value={part.warehouse_quantity}
                              />
                            </div>
                            <div className="col-md-1 col-lg-1">
                              <Checkbox
                                required
                                disabled={!this.isInvalid(part)}
                                defaultChecked={!this.isInvalid(part)}
                                // checked={part.fixed}
                                // onChange={(e) => {this.handlePartFixedChecked(part, e)}}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    
                  </div>
                  )
              })}
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.dismiss()} color="primary">Cancel</Button>
            <Button onClick={this.submit.bind(this)} color="primary">Save</Button>
          </DialogActions>
        </Dialog>
      </Theme>
    )
  }
}

OutboundDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
  title: PropTypes.string,
  packages: PropTypes.object,
  stock_parts: PropTypes.object
}

export default confirmable(OutboundDialog);
