import React from 'react';
import { Link } from 'react-router-dom';
import AsyncButton from '../../../shared/components/async_button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DomUtil from '../../../shared/util/dom_util';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getAccount } from '../../../shared/services/account_service';
import { getInvoiceGroup, updateInvoiceGroup } from '../../../shared/services/invoice_service';
import ErrorHandler from '../../../shared/util/error_handler';
import Mapper from '../../../shared/util/mapper';
import LoadingTextField from '../../../shared/components/loading_text_field'
import LoadingSelectField from '../../../shared/components/loading_select_field'

class EditInvoiceGroup extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      account_id: this.props.match.params.account_id,
      invoice_group_id: this.props.match.params.invoice_group_id,
      account: {}
    };
  }

  componentWillMount() {
    this.getInvoiceGroup();
    this.getAccount();
  }

  getInvoiceGroup() {
    getInvoiceGroup(this.state.invoice_group_id).then((invoice_group) => {
      if (invoice_group) {
        this.setState({ invoice_group: invoice_group });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getAccount() {
    getAccount(this.state.account_id).then((account) => {
      if (account) {
        this.setState({ account: account });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  handleChange(e) {
    this.state.invoice_group[e.target.name] = e.target.value;
    this.setState({ invoice_group: this.state.invoice_group });
  }

  createInvoiceGroup(e) {
    e.preventDefault();
    var form = e.currentTarget;
    DomUtil.disableFormSubmitButtonClass(form);
    updateInvoiceGroup(this.state.invoice_group_id, this.state.invoice_group).then((invoice_group) => {
      this.props.history.push(`/accounts/${this.state.account_id}/invoice-groups/${invoice_group.id}` + this.props.location.search);
    }).catch(error => {
      ErrorHandler.showError(error);
      DomUtil.enableFormSubmitButtonClass(form);
    });
  }

  render() {
    return (
      <div>

        <div className="container-content">

        <Breadcrumb>
            <BreadcrumbItem><Link to={`/accounts` + this.props.location.search}>Accounts</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`/accounts/${this.state.account_id}` + this.props.location.search}>{this.state.account.name}</Link></BreadcrumbItem>
            <BreadcrumbItem>Invoice groups</BreadcrumbItem>
            <BreadcrumbItem><Link to={`/accounts/${this.state.account_id}/invoice-groups/${this.state.invoice_group_id}` + this.props.location.search}>{this.state.invoice_group ? this.state.invoice_group.name : undefined}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Edit</BreadcrumbItem>
          </Breadcrumb>

          <div className="container-body">
            
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Add invoice group</label>
                </div>
              </div>
            </div>

            <form autoComplete="off" onSubmit={this.createInvoiceGroup.bind(this)}>
              
              <div className="row row-small">
                <div className="col-md-4">
                { this.state.invoice_group &&
                  <TextField
                    label="Name"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    name="name"
                    value={this.state.invoice_group.name}
                    onChange={this.handleChange.bind(this)} />
                  }
                  { !this.state.invoice_group &&
                    <LoadingTextField
                      label="Full name"
                      shrink={true}
                    />
                  }
                </div>
              </div>

              <div className="row row-small">
                <div className="col-md-4">
                { this.state.invoice_group &&
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    fullWidth >
                    <InputLabel>Status *</InputLabel>
                    <Select
                      native
                      required
                      label="Status *"
                      name="status"
                      value={this.state.invoice_group.status}
                      onChange={this.handleChange.bind(this)} >
                        <option key={"shipment_status_empty"} value=""></option>
                        {Object.keys(Mapper.invoiceGroupStatuses()).map(status => {
                          return (<option key={"invoice_group_status_" + status} value={status}>{Mapper.fromInvoiceGroupStatus(status)}</option>);
                        })}
                    </Select>
                  </FormControl>
                  }
                  { !this.state.invoice_group &&
                    <LoadingSelectField
                      label="Role"
                      shrink={true}
                    />
                  }
                </div>
              </div>

              <div className="row row-small">
                <div className="col-md">
                  <AsyncButton
                    type="submit"
                    variant="outlined"
                    color="primary"
                    icon="check"
                    text="Update" />
                </div>
              </div>

            </form>
          
          </div>

        </div>
      </div>
    );
  }
}

export default EditInvoiceGroup;
