import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import alertDialog from './alert';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import fileDownload from 'js-file-download';

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

class DocumentUpload extends React.Component {
  
  constructor(props) {
    super(props)
    this.progressEvent = this.progressEvent.bind(this);

    this.state = {
      progress: undefined,
      document: this.props.document,
      existing_document: (this.props.document && this.props.document.id),
      disabled: this.props.disabled,
      required: this.props.required !== undefined ? this.props.required : true,
    };
  }

  clearDocument(e) {
    this.setState({ progress: undefined, document: {}, existing_document: false });
    if(this.props.documentCleared) {
      this.props.documentCleared();
    }
  }

  handleFileChange(files) {
    this.uploadFile(files[0]);
  }

  uploadFile(file) {
    if(!file) {
      return;
    }

    this.props.uploadDocument(file, this.progressEvent).then((document) => {
      if(!document.file_name) {
        document.file_name = file.name;
      }

      this.setState({ document: document });

      if(this.props.documentUploaded) {
        this.props.documentUploaded(document);
      }
    }).catch(error => {
      console.error(error)

      var message = "Failed to upload document, max allowed document size 100 MB.";
      if (error.response) {
        var data = error.response.data;
        if(data.error_description) {
          message = data.error_description;
        } else if(data.message) {
          message = data.message;
        }
      }
      
      this.setState({ progress: undefined });
      alertDialog("Alert", message)
    });
  }

  progressEvent(progressEvent) {
    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    this.setState({ progress: percentCompleted });
  }

  downloadDocument(e) {
    e.preventDefault();
    if(!this.props.downloadDocument) {
      return;
    }
    
    this.props.downloadDocument(this.state.document).then(blob => {
      fileDownload(blob, this.state.document.file_name);
    }).catch(error => {
      console.error(error)
      alertDialog("Alert", "Failed to download document.")
    });
  }

  render() {
    if(this.state.document && this.state.document.file_name) {
      return(
        <div className='file-input-download-parent'>
          <TextField
            label={this.props.title}
            variant="outlined"
            required={this.state.required}
            fullWidth
            margin="dense"
            disabled
            value=""
            InputLabelProps={{
              shrink: true,
            }}
          />
          <span className="icon-span file-input-download-icon"><i className="far fa-file"/></span>
          <Link
            className="file-input-download-link"
            component="button"
            variant="body2"
            type="button"
            onClick={this.downloadDocument.bind(this)}>
          {this.state.document.file_name}</Link>
          <Button
            className="button-icon-only button-inline clear-form-button"
            color="secondary"
            type="button"
            disabled={this.state.disabled}
            onClick={this.clearDocument.bind(this)}
            startIcon={<Icon>clear</Icon>} />
        </div>
      );
    }

    if(this.state.progress) {
      return(
        <LinearProgressWithLabel
          className="file-progress-bar field-side-margins"
          value={this.state.progress} />
      );
    }

    return(
      <div className='file-input-download-parent'>
        <TextField
          label={this.props.title}
          variant="outlined"
          required={this.state.required}
          fullWidth
          margin="dense"
          type="file"
          InputLabelProps={{
            shrink: true,
          }}
          disabled={this.state.disabled}
          onChange={ (e) => this.handleFileChange(e.target.files) }
        />
        {this.props.alwayClearable &&
          <Button
            className="button-icon-only button-inline clear-form-button"
            color="secondary"
            type="button"
            disabled={this.state.disabled}
            onClick={this.clearDocument.bind(this)}
            startIcon={<Icon>clear</Icon>} />
        }
      </div>
    );
  }
}

export default DocumentUpload;
