import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

class AsyncButton extends React.Component {
  
  constructor(props) {
    super(props)

    this.state = {
      progress: undefined
    };
  }

  render() {
    return (
      <div className={ "async-button " + this.props.rootClassName } >
        <Button
          type={this.props.type}
          className={this.props.className}
          variant={this.props.variant}
          color={this.props.color}
          disabled={this.props.disabled}
          startIcon={<Icon>{this.props.icon}</Icon>}
          onClick={this.props.onClick} >
          {this.props.text}
          {<CircularProgress size={24} className="button-loading" />}
        </Button>
      </div>
    );
  }
}

export default AsyncButton;
