import React from 'react';
import { Link } from 'react-router-dom';
import DomUtil from '../../../shared/util/dom_util';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import { getShipment, updateShipmentTransport } from '../../../shared/services/shipment_service';
import { getCurrentAccount } from '../../../shared/services/account_service';
import ErrorHandler from '../../../shared/util/error_handler';
import Mapper from '../../../shared/util/mapper'
import confirm from '../../../shared/components/confirm.js'
import ShipmentForm from './components/form'
import EventBus from '../../../shared/components/event_bus';

class EditShipment extends React.Component {

  constructor(props) {
    super(props)
    this.updateShipment = this.updateShipment.bind(this);

    this.state = {
      shipment_id: this.props.match.params.shipment_id,
      shipment: undefined,
      countries: undefined,
      warehouses: undefined,
      ports: undefined,
      account: undefined,
      accounts: undefined
    };
  }

  componentWillMount() {
    this.getShipment();
    this.getCurrentAccount();
  }

  getShipment() {
    getShipment(this.state.shipment_id).then((shipment) => {
      if (shipment) {
        this.setState({ shipment: shipment });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getCurrentAccount() {
    getCurrentAccount().then((account) => {
      if (account) {
        this.setState({ account: account });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  updateShipment(e) {
    e.preventDefault();
    var form = e.currentTarget;
    confirm("Please confirm", "Are you sure to update this shipment?").then(() => {
      DomUtil.disableFormSubmitButtonClass(form);
      var shipment = Mapper.toShipmentRequestTransport(this.state.shipment);
      updateShipmentTransport(this.state.shipment_id, shipment).then(() => {
        EventBus.dispatch("updateShipment");
        this.props.history.push(`${this.getBasePath()}/${this.state.shipment_id}${this.props.location.search}`);
      }).catch(error => {
        ErrorHandler.showError(error);
        DomUtil.enableFormSubmitButtonClass(form);
      });
    });
  }

  isLoaded() {
    return this.state.account &&
      this.state.shipment;
  }

  isActivePath() {    
    return this.props.location.pathname.startsWith('/shipments/active');
  }

  getBasePath() {
    if(this.isActivePath()) {
      return '/shipments/active';
    }
    return '/shipments/all';
  }

  getBreadCrumbTitle() {
    if(this.isActivePath()) {
      return 'Active Shipments';
    }
    return 'All Shipments';
  }

  render() {
    return (
      <div>

        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.getBasePath()}/${this.props.location.search}`}>{this.getBreadCrumbTitle()}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.getBasePath()}/${this.props.match.params.shipment_id}${this.props.location.search}`}>{this.props.match.params.shipment_id}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Edit</BreadcrumbItem>
          </Breadcrumb>
          
          {this.isLoaded() &&
            <ShipmentForm
              title={"Update Shipment " + this.state.shipment.id}
              account={this.state.account}
              shipment={this.state.shipment}
              saveShipment={this.updateShipment}
            />
          }
          
          {!this.isLoaded() &&
            <div className="container-body">
              <div className="row">
                <div className="col-md">
                  <div className="form-header">
                    <label>Update Shipment</label>
                  </div>
                </div>
              </div>          
              <div className="row row-small">
                <div className="col-md-12 col-lg-8">
                  <Skeleton count={5}/>
                </div>
              </div>
            </div>
          }

        </div>
      </div>
    );
  }
}

export default EditShipment;
