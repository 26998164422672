import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Converter from '../../../../shared/util/converter.js'
import Calculator from '../../../../shared/util/calculator.js';
import confirmPallet from '../../../../shared/components/confirm_pallet.js'
import PalletsTable from '../../../../shared/components/pallets_table.js'
import confirm from '../../../../shared/components/confirm.js'
import confirmShipmentPalletsImport from '../../../../shared/components/shipment_confirm_pallets_import.js'

class PalletsTab extends React.Component {


  isLessThanTransport() {
    return this.props.shipment.transport_type === "ltl" || this.props.shipment.transport_type === "lcl";
  }

  addPalletToList() {
    if(!this.props.shipment.pallets) {
      this.props.shipment.pallets = [];
    }
    var pallet = { key: this.getUniqueKey(), index: this.props.shipment.pallets.length };
    confirmPallet("Add pallet", pallet, this.isLessThanTransport(), true).then((responsepallet) => {
      this.props.shipment.pallets.push(responsepallet);
      this.props.updateShipment(this.props.shipment);
    });
  }

  editPalletOfList(index) {
    const clone = JSON.parse(JSON.stringify(this.props.shipment.pallets[index]));
    clone.index = index;

    confirmPallet("Edit pallet", clone, this.isLessThanTransport(), true).then((responsepallet) => {
      this.props.shipment.pallets[index] = responsepallet;
      this.props.updateShipment(this.props.shipment);
    });
  }

  clonePalletOfList(index) {
    const clone = JSON.parse(JSON.stringify(this.props.shipment.pallets[index]));
    clone.key = this.getUniqueKey()
    clone.index = this.props.shipment.pallets.length;
    
    confirmPallet("Clone pallet", clone, this.isLessThanTransport(), true).then((responsepallet) => {
      this.props.shipment.pallets.push(responsepallet);
      this.props.updateShipment(this.props.shipment);
    });
  }

  removePalletFromList(index) {
    confirm("Please confirm", `Are you sure you want to delete Pallet ${index + 1}?`).then(() => {
      this.props.shipment.pallets.splice(index, 1);
      this.props.updateShipment(this.props.shipment);
    });
  }

  importPallets() {
    confirmShipmentPalletsImport().then((data) => {
      if(!data.pallets) {
        return;
      }

      if(!this.props.shipment.pallets) {
        this.props.shipment.pallets = [];
      }

      if(data.replace) {
        this.props.shipment.pallets = [];
      }

      for(const pallet of data.pallets) {
        pallet.key = this.getUniqueKey();
        this.props.shipment.pallets.push(pallet);
      }
      
      this.props.updateShipment(this.props.shipment);
    });
  }

  getTotalPallets() {
    if(!this.props.shipment.pallets) {
      return 0;
    }
    return this.props.shipment.pallets.length;
  }

  getTotalWeight() {
    if(!this.props.shipment.pallets) {
      return 0;
    }
    
    var total = 0;
    this.props.shipment.pallets.forEach(pallet => {
        total += Number(pallet.weight_kg);
    });
    return total;
  }

  getTotalVolume() {
    if(!this.props.shipment.pallets) {
      return 0;
    }

    var total = 0;
    this.props.shipment.pallets.forEach(pallet => {
        total += this.getVolumeOfPallet(pallet);
    });
    return total;
  }

  getVolumeOfPallet(pallet) {
    var volume = 0;
    if(pallet.length_cm && pallet.width_cm && pallet.height_cm) {
      volume = (Number(pallet.length_cm) / 100) * (Number(pallet.width_cm) / 100) * (Number(pallet.height_cm) / 100);
    }
    return volume;
  }

  getUniqueKey() {
    return new Date().getTime() + Math.random();
  }

  render() {
    return (
      <div>
    
        <div className="row row-small">
          <div className="col-md-6 col-lg-4">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td width={200} className="header-text">Total pallets</td>
                  <td>{Converter.formatNumber(this.getTotalPallets(), 0)}</td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Total volume</td>
                  <td>{Converter.formatNumber(this.getTotalVolume(), 2)} m3</td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Total weight</td>
                  <td>{Converter.formatNumber(this.getTotalWeight(), 2)} kg</td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Loading meter</td>
                  <td>{Converter.formatNumber(Calculator.calculateLoadingMeters(this.props.shipment.pallets), 2)} ldm</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="col-md">
            <Button
              variant="outlined"
              color="primary"
              onClick={this.addPalletToList.bind(this)}
              startIcon={<Icon>playlist_add_check</Icon>}>
              Add pallet
            </Button>
              <Button
                className='align_right'
                variant="outlined"
                color="primary"
                onClick={this.importPallets.bind(this)}
                startIcon={<Icon>upload_file</Icon>}>
                Import
              </Button>
          </div>
        </div>
      
        <div className="row">
          <div className="col-sm">
            <PalletsTable
                name="shipment_form_pallets_table_state"
                data={this.props.shipment}
                headers={[
                  "length_cm",
                  "width_cm",
                  "height_cm",
                  "volume_m3",
                  "weight_kg",
                  "hs_codes",
                  "stackable"
                ]}
                editable={true}
                onEdit={this.editPalletOfList.bind(this)}
                onClone={this.clonePalletOfList.bind(this)}
                onDelete={this.removePalletFromList.bind(this)}
            />
          </div>
        </div>
      
      </div>
      
    );
  }
}

export default PalletsTab;
