import React from 'react';
import PalletsTable from "../../../components/pallets_table.js";
import LoadingContent from '../../../components/loading_content.js';
import AsyncButton from '../../../components/async_button.js';
import Converter from '../../../util/converter.js'
import Calculator from '../../../util/calculator.js';
import { exportPalletsToDocument } from '../../../services/shipment_service.js';
import DomUtil from '../../../util/dom_util.js';
import ErrorHandler from '../../../util/error_handler.js';
import fileDownload from 'js-file-download';

class PalletsTab extends React.Component {

  getTotalPallets() {
    if(!this.props.shipment.pallets) {
      return 0;
    }
    return this.props.shipment.pallets.length;
  }

  getTotalWeight() {
    if(!this.props.shipment.pallets) {
      return 0;
    }

    var total = 0;
    if(!this.props.shipment.pallets) {
      return total;
    }
    this.props.shipment.pallets.forEach(pallet => {
      if(pallet.weight_kg) {
        total += Number(pallet.weight_kg);
      }
    });
    return total;
  }

  getTotalVolume() {
    if(!this.props.shipment.pallets) {
      return 0;
    }

    var total = 0;
    this.props.shipment.pallets.forEach(pallet => {
      total += this.getVolumeOfPallet(pallet);
    });
    return total;
  }

  getVolumeOfPallet(pallet) {
    var volume = 0;
    if(pallet.length_cm && pallet.width_cm && pallet.height_cm) {
      volume = (Number(pallet.length_cm) / 100) * (Number(pallet.width_cm) / 100) * (Number(pallet.height_cm) / 100);
    }
    return volume;
  }

  exportPallets(e) {
    e.preventDefault();
    var button = e.currentTarget;
    DomUtil.disableClass(button);

    exportPalletsToDocument(this.props.shipment.id).then((blob) => {
      fileDownload(blob, `shipment_${this.props.shipment.id}_pallets_export.xlsx`);
      DomUtil.enableClass(button);
    }).catch((error) => {
      ErrorHandler.showError(error);
      DomUtil.enableClass(button);
    });
  }

  render() {
    return (
      <div>

        <div className="row row-small">
          <div className="col-md-12 col-lg-6">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td width={200} className="header-text">Total pallets</td>
                  <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => Converter.formatNumber(this.getTotalPallets(), 0)} /></td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Total volume</td>
                  <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => Converter.formatNumber(this.getTotalVolume(), 2) + " m3"} /></td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Total weight</td>
                  <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => Converter.formatNumber(this.getTotalWeight(), 2) + " kg"} /></td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Loading meter</td>
                  <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => Converter.formatNumber(Calculator.calculateLoadingMeters(this.props.shipment.pallets), 2) + " ldm"} /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="col-md">
            <AsyncButton
              variant="outlined"
              color="secondary"
              onClick={this.exportPallets.bind(this)}
              icon="download"
              text="Export" />
          </div>
        </div>

        <div className="row">
          <div className="col-sm">
            <PalletsTable
                name="shipment_pallets_table_state"
                data={this.props.shipment}
                headers={[
                  "length_cm",
                  "width_cm",
                  "height_cm",
                  "volume_m3",
                  "weight_kg",
                  "hs_codes",
                  "stackable"
                ]}
            />
          </div>
        </div>
        
      </div>
    );
  }
}

export default PalletsTab;
