import { DefaultApi } from "generated-invoices-api";
import { getHeaders, handleResponse } from './web_service';

export const API = new DefaultApi({
  basePath: window.appConfig.API_HOSTNAME
});

export function getInvoice(id) {
  return API.getInvoice(id, getHeaders()).then(handleResponse);
}

export function getInvoices(page, size, sort, filter) {
  return API.getInvoices(page, size, sort, filter, getHeaders()).then(handleResponse);
}

export function getInvoiceCount() {
  return API.getInvoiceCount(getHeaders()).then(handleResponse);
}

export function getInvoicesReport(customerId, status, from, to, showAllInGroup) {
  return API.getInvoicesReport(customerId, status, from, to, showAllInGroup, getHeaders()).then(handleResponse);
}

export function getInvoicesOfResource(type, id) {
  return API.getInvoicesOfResource(type, id, getHeaders()).then(handleResponse);
}

export function createInvoice(data) {
  return API.createInvoice(data, getHeaders()).then(handleResponse);
}

export function updateInvoice(id, data) {
  return API.updateInvoice(id, data, getHeaders()).then(handleResponse);
}

export function deleteInvoice(id) {
  return API.deleteInvoice(id, getHeaders()).then(handleResponse);
}

export function generateInvoice(data) {
  return API.generateInvoice(data, getHeaders()).then(handleResponse);
}

export function generatePreviewInvoice(data) {
  var headers = getHeaders();
  headers['responseType'] = 'blob';
  return API.generatePreviewInvoice(data, headers).then(handleResponse);
}

export function sendInvoice(id, data) {
  return API.sendInvoice(id, data, getHeaders()).then(handleResponse);
}

export function getInvoiceGroups(customerId, page, size, sort, filter) {
  return API.getInvoiceGroups(customerId, page, size, sort, filter, getHeaders()).then(handleResponse);
}

export function getAllInvoiceGroups() {
  return API.getAllInvoiceGroups(getHeaders()).then(handleResponse);
}

export function getInvoiceGroup(id) {
  return API.getInvoiceGroup(id, getHeaders()).then(handleResponse);
}

export function createInvoiceGroup(request) {
  return API.createInvoiceGroup(request, getHeaders()).then(handleResponse);
}

export function updateInvoiceGroup(id, request) {
  return API.updateInvoiceGroup(id, request, getHeaders()).then(handleResponse);
}

export function getInvoiceGroupDocument(id, documentId) {
  var headers = getHeaders();
  headers['responseType'] = 'blob';
  return API.getInvoiceGroupDocument(id, documentId, headers).then(handleResponse);
}

export function uploadInvoiceGroupDocument(id, file, access, progress_event) {
  var headers = getHeaders();
  headers['content-type'] = 'multipart/form-data';
  headers['onUploadProgress'] = progress_event;
  return API.uploadInvoiceGroupDocument(id, file, access, headers).then(handleResponse);
}
