import React from 'react';
import Skeleton from 'react-loading-skeleton';
import ReactResizeDetector from 'react-resize-detector';

class Table extends React.Component {
  
  constructor(props) {
    super(props)
    this.tableRef = React.createRef();

    this.state = {
      rows: undefined
    };
  }

  getSkeletonRows() {
    var rows = [];
    for (var i = 0; i < this.props.loading_rows; i++) {
      rows.push(
        <tr key={Math.random()} className={ i === 0 ? "" : "table-transparent-top-border"}>
          {this.props.headers.map(header => {
            return <td key={header.key + "_"+ i}><Skeleton/></td>;
          })}
        </tr>
      );
    }
    return rows;
  }

  renderRows() {
    if(this.props.rows.length === 0) {
      return <tr key={Math.random()} >
        <td colSpan={this.props.headers.length}>{this.props.empty_rows_text}</td>
      </tr>
    }
    
    var rows = [];
    this.props.rows.forEach(row => {
      rows.push(
        <tr key={Math.random()} className={this.props.selectable ? "row-selectable" : ""} onClick={(e) => this.onRowSelected(e, row)}>
          {this.props.headers.map(header => {
            return <td key={header.key}>{row[header.key]}</td>;
          })}
      </tr>
      );
    });
    return rows;
  }

  onRowSelected(e, row) {
    if(this.props.selectable) {
      var newTab = e.ctrlKey || e.metaKey;
      this.props.onRowSelected(row, newTab);
    }
  }

  render() {
    return (
      <ReactResizeDetector targetRef={this.tableRef} handleHeight>
        {({ height }) =>
          <div style={{ height: height }} className="loading-table">
            <table ref={this.tableRef} className="table">
              <thead className="rt-thead thead-light">
                <tr>
                  {this.props.headers.map(header => {
                    return <th key={header.name} className={header.className} scope="col">{header.name}</th>
                  })}
                </tr>
              </thead>
              <tbody className="rt-tbody">
                {!this.props.rows &&
                  this.getSkeletonRows()
                }
                {this.props.rows &&
                  this.renderRows()
                }
              </tbody>
            </table>
          </div>
        }
      </ReactResizeDetector>
    );
  }
}

export default Table;
