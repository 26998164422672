import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import RowDataTable from './row_data_table';
import Mapper from '../util/mapper';
import Converter from '../util/converter'
import TableUtil from '../util/table_util';

class ContainersTable extends React.Component {

  getContainersRows() {
    var containers = [];
    var data = this.props.data;
    
    if(!data) {
      return;
    }
    
    if(!data.containers) {
      return { data: [], totalElements: 0 };
    }
    
    data.containers.forEach((container, index) => {
      containers.push({ ... container, index: index, id: `Container ${index + 1}` });
    });

    return { data: containers, totalElements: containers.length, ready: true };
  }

  getContainersHeaders() {
    var headers = [];
    
    if(this.props.editable) {
      headers.push( {
        headerName: "Actions",
        field: "actions",
        cellRenderer: (params) => this.getContainersActionButtons(params),
        minWidth: 100,
        width: 100,
        pinned: 'left',
        lockPinned: true,
        lockVisible: true
      });
    }

    headers.push(TableUtil.getTextColumn("id", "#", undefined, { lockVisible: true }));

    if(this.includesHeader("container_number")) {
      headers.push(TableUtil.getTextColumn("container_number", "Container number"));
    }
    if(this.includesHeader("quantity")) {
      headers.push(TableUtil.getNumberColumn("quantity", "Quantity"));
    }
    if(this.includesHeader("size")) {
      headers.push(TableUtil.getSetColumn("size", "Size", (params) => Mapper.fromContainerSize(params.value), Object.keys(Mapper.containerSizes()), (value) => Mapper.fromContainerSize(value)));
    }
    if(this.includesHeader("type")) {
      headers.push(TableUtil.getSetColumn("type", "Type", (params) => Mapper.fromContainerType(params.value), Object.keys(Mapper.containerTypes()), (value) => Mapper.fromContainerType(value)));
    }
    if(this.includesHeader("net_weight_kg")) {
      headers.push(TableUtil.getNumberColumn("net_weight_kg", "Net weight (kg)", (params) => Converter.formatNumber(params.value, 2)));
    }
    if(this.includesHeader("co2_emissions_kg")) {
      headers.push(TableUtil.getTextColumn(
        "co2_emissions_kg",
        "Carbon emissions (kg)",
        (params) => {
          if(params.value) {
            return <a href={`${params.data.co2_emissions_link}`} target="_blank" rel="noreferrer" >{Converter.formatNumber(params.value, 2)}</a>;
          }
          return "";
        }
      ));
    }

    return headers;
  }

  includesHeader(name) {
    return this.props.headers && this.props.headers.includes(name);
  }

  getContainersActionButtons(params) {
    return (
      <div className='table-cell-button-container'>
      <IconButton
        className='table-cell-button'
        size="small"
        onClick={() => this.props.onEdit(params.data.index)}
      >
          <Icon>edit</Icon>
        </IconButton>
        <IconButton
          className='table-cell-button'
          size="small"
          onClick={() => this.props.onDelete(params.data.index)}
        >
          <Icon>delete</Icon>
        </IconButton>
      </div>
    );
  }

  render() {
    return (
      <RowDataTable
        name={this.props.name}
        rowData={this.getContainersRows()}
        columnDefs={this.getContainersHeaders()}
        overlayNoRowsTemplate={"There are no containers"}
        pagination={this.props.pagination}
        defaultPageSize={this.props.defaultPageSize}
      />
    );
  }
}

export default ContainersTable;
