import { DefaultApi } from "generated-accounts-api";
import { getHeaders, handleResponse } from './web_service';

export const API = new DefaultApi({
  basePath: window.appConfig.API_HOSTNAME
});

export function getAccounts(page, size, sort, filters) {
  return API.getAccounts(page, size, sort, filters, getHeaders()).then(handleResponse);
}

export function getAllAccounts() {
  return API.getAllAccounts(getHeaders()).then(handleResponse);
}

export function getAccount(id) {
  return API.getAccount(id, getHeaders()).then(handleResponse);
}

export function getCurrentAccount() {
  return API.getCurrentAccount(getHeaders()).then(handleResponse);
}

export function setCompanyDetails(details) {
  return API.setCompanyDetails(details, getHeaders()).then(handleResponse);
}

export function inviteUser(invite) {
  return API.inviteUser(invite, getHeaders()).then(handleResponse);
}

export function getUser(id) {
  return API.getUser(id, getHeaders()).then(handleResponse);
}

export function updateUser(id, user) {
  return API.updateUser(id, user, getHeaders()).then(handleResponse);
}

export function deleteUser(id) {
  return API.deleteUser(id, getHeaders()).then(handleResponse);
}

export function updateCurrentUser(data) {
  return API.updateCurrentUser(data, getHeaders()).then(handleResponse);
}

export function getCurrentUser() {
  return API.getCurrentUser(getHeaders()).then(handleResponse);
}

export function createAddress(body) {
  return API.createAddress(body, getHeaders()).then(handleResponse);
}

export function deleteAddress(id) {
  return API.deleteAddress(id, getHeaders()).then(handleResponse);
}

export function updateAddress(id, body) {
  return API.updateAddress(id, body, getHeaders()).then(handleResponse);
}

export function getAddress(id) {
  return API.getAddress(id, getHeaders()).then(handleResponse);
}
