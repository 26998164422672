"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * CargoDesk Invoices Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultApi = exports.DefaultApiFactory = exports.DefaultApiFp = exports.DefaultApiAxiosParamCreator = exports.InvoiceType = exports.InvoiceStatus = exports.InvoiceResourceType = exports.InvoiceGroupStatus = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
exports.InvoiceGroupStatus = {
    Open: 'open',
    Closed: 'closed'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.InvoiceResourceType = {
    Shipment: 'shipment',
    InvoiceGroup: 'invoice_group'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.InvoiceStatus = {
    Open: 'open',
    Paid: 'paid'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.InvoiceType = {
    Purchase: 'purchase',
    Sale: 'sale'
};
/**
 * DefaultApi - axios parameter creator
 * @export
 */
exports.DefaultApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create invoice
         * @param {InvoiceRequest} invoiceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoice: function (invoiceRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'invoiceRequest' is not null or undefined
                            common_1.assertParamExists('createInvoice', 'invoiceRequest', invoiceRequest);
                            localVarPath = "/invoices";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(invoiceRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create invoice group
         * @param {InvoiceGroupRequest} invoiceGroupRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoiceGroup: function (invoiceGroupRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'invoiceGroupRequest' is not null or undefined
                            common_1.assertParamExists('createInvoiceGroup', 'invoiceGroupRequest', invoiceGroupRequest);
                            localVarPath = "/invoice-groups";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(invoiceGroupRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete invoice
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvoice: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('deleteInvoice', 'id', id);
                            localVarPath = "/invoices/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Generate invoice
         * @param {InvoiceGenerateRequest} invoiceGenerateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateInvoice: function (invoiceGenerateRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'invoiceGenerateRequest' is not null or undefined
                            common_1.assertParamExists('generateInvoice', 'invoiceGenerateRequest', invoiceGenerateRequest);
                            localVarPath = "/invoices/generate";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(invoiceGenerateRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Generate preview invoice
         * @param {InvoiceGenerateRequest} invoiceGenerateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePreviewInvoice: function (invoiceGenerateRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'invoiceGenerateRequest' is not null or undefined
                            common_1.assertParamExists('generatePreviewInvoice', 'invoiceGenerateRequest', invoiceGenerateRequest);
                            localVarPath = "/invoices/generate/preview";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(invoiceGenerateRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get all invoice groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInvoiceGroups: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/invoice-groups/all";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get invoice
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoice: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getInvoice', 'id', id);
                            localVarPath = "/invoices/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get invoices count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceCount: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/invoices/count";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get invoice group
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceGroup: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getInvoiceGroup', 'id', id);
                            localVarPath = "/invoice-groups/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get invoice group document
         * @param {string} id
         * @param {string} documentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceGroupDocument: function (id, documentId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getInvoiceGroupDocument', 'id', id);
                            // verify required parameter 'documentId' is not null or undefined
                            common_1.assertParamExists('getInvoiceGroupDocument', 'documentId', documentId);
                            localVarPath = "/invoice-groups/{id}/documents/{document_id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)))
                                .replace("{" + "document_id" + "}", encodeURIComponent(String(documentId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get invoice groups
         * @param {string} counterpartyId
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {string} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceGroups: function (counterpartyId, page, size, sort, filter, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'counterpartyId' is not null or undefined
                            common_1.assertParamExists('getInvoiceGroups', 'counterpartyId', counterpartyId);
                            localVarPath = "/invoice-groups";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (counterpartyId !== undefined) {
                                localVarQueryParameter['counterparty_id'] = counterpartyId;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (size !== undefined) {
                                localVarQueryParameter['size'] = size;
                            }
                            if (sort !== undefined) {
                                localVarQueryParameter['sort'] = sort;
                            }
                            if (filter !== undefined) {
                                localVarQueryParameter['filter'] = filter;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get invoices
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {string} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoices: function (page, size, sort, filter, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/invoices";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (size !== undefined) {
                                localVarQueryParameter['size'] = size;
                            }
                            if (sort !== undefined) {
                                localVarQueryParameter['sort'] = sort;
                            }
                            if (filter !== undefined) {
                                localVarQueryParameter['filter'] = filter;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get invoices of resource
         * @param {InvoiceResourceType} type
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesOfResource: function (type, id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'type' is not null or undefined
                            common_1.assertParamExists('getInvoicesOfResource', 'type', type);
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getInvoicesOfResource', 'id', id);
                            localVarPath = "/invoices/resources/{type}/{id}"
                                .replace("{" + "type" + "}", encodeURIComponent(String(type)))
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get invoices report
         * @param {string} [counterpartyId]
         * @param {InvoiceStatus} [status]
         * @param {string} [from]
         * @param {string} [to]
         * @param {boolean} [showAllInGroup]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesReport: function (counterpartyId, status, from, to, showAllInGroup, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/invoices/report";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (counterpartyId !== undefined) {
                                localVarQueryParameter['counterparty_id'] = counterpartyId;
                            }
                            if (status !== undefined) {
                                localVarQueryParameter['status'] = status;
                            }
                            if (from !== undefined) {
                                localVarQueryParameter['from'] = (from instanceof Date) ?
                                    from.toISOString().substr(0, 10) :
                                    from;
                            }
                            if (to !== undefined) {
                                localVarQueryParameter['to'] = (to instanceof Date) ?
                                    to.toISOString().substr(0, 10) :
                                    to;
                            }
                            if (showAllInGroup !== undefined) {
                                localVarQueryParameter['show_all_in_group'] = showAllInGroup;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Send invoice
         * @param {string} id
         * @param {InvoiceSendRequest} invoiceSendRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvoice: function (id, invoiceSendRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('sendInvoice', 'id', id);
                            // verify required parameter 'invoiceSendRequest' is not null or undefined
                            common_1.assertParamExists('sendInvoice', 'invoiceSendRequest', invoiceSendRequest);
                            localVarPath = "/invoices/{id}/send"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(invoiceSendRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update invoice
         * @param {string} id
         * @param {InvoiceUpdateRequest} invoiceUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoice: function (id, invoiceUpdateRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('updateInvoice', 'id', id);
                            // verify required parameter 'invoiceUpdateRequest' is not null or undefined
                            common_1.assertParamExists('updateInvoice', 'invoiceUpdateRequest', invoiceUpdateRequest);
                            localVarPath = "/invoices/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(invoiceUpdateRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update invoice group
         * @param {string} id
         * @param {InvoiceGroupUpdateRequest} invoiceGroupUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoiceGroup: function (id, invoiceGroupUpdateRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('updateInvoiceGroup', 'id', id);
                            // verify required parameter 'invoiceGroupUpdateRequest' is not null or undefined
                            common_1.assertParamExists('updateInvoiceGroup', 'invoiceGroupUpdateRequest', invoiceGroupUpdateRequest);
                            localVarPath = "/invoice-groups/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(invoiceGroupUpdateRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Upload invoice group document
         * @param {string} id
         * @param {any} file
         * @param {string} access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInvoiceGroupDocument: function (id, file, access, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('uploadInvoiceGroupDocument', 'id', id);
                            // verify required parameter 'file' is not null or undefined
                            common_1.assertParamExists('uploadInvoiceGroupDocument', 'file', file);
                            // verify required parameter 'access' is not null or undefined
                            common_1.assertParamExists('uploadInvoiceGroupDocument', 'access', access);
                            localVarPath = "/invoice-groups/{id}/upload"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (file !== undefined) {
                                localVarFormParams.append('file', file);
                            }
                            if (access !== undefined) {
                                localVarFormParams.append('access', access);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * DefaultApi - functional programming interface
 * @export
 */
exports.DefaultApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.DefaultApiAxiosParamCreator(configuration);
    return {
        /**
         * Create invoice
         * @param {InvoiceRequest} invoiceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoice: function (invoiceRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createInvoice(invoiceRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Create invoice group
         * @param {InvoiceGroupRequest} invoiceGroupRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoiceGroup: function (invoiceGroupRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createInvoiceGroup(invoiceGroupRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Delete invoice
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvoice: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteInvoice(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Generate invoice
         * @param {InvoiceGenerateRequest} invoiceGenerateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateInvoice: function (invoiceGenerateRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.generateInvoice(invoiceGenerateRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Generate preview invoice
         * @param {InvoiceGenerateRequest} invoiceGenerateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePreviewInvoice: function (invoiceGenerateRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.generatePreviewInvoice(invoiceGenerateRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get all invoice groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInvoiceGroups: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAllInvoiceGroups(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get invoice
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoice: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getInvoice(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get invoices count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceCount: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getInvoiceCount(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get invoice group
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceGroup: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getInvoiceGroup(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get invoice group document
         * @param {string} id
         * @param {string} documentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceGroupDocument: function (id, documentId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getInvoiceGroupDocument(id, documentId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get invoice groups
         * @param {string} counterpartyId
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {string} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceGroups: function (counterpartyId, page, size, sort, filter, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getInvoiceGroups(counterpartyId, page, size, sort, filter, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get invoices
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {string} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoices: function (page, size, sort, filter, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getInvoices(page, size, sort, filter, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get invoices of resource
         * @param {InvoiceResourceType} type
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesOfResource: function (type, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getInvoicesOfResource(type, id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get invoices report
         * @param {string} [counterpartyId]
         * @param {InvoiceStatus} [status]
         * @param {string} [from]
         * @param {string} [to]
         * @param {boolean} [showAllInGroup]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesReport: function (counterpartyId, status, from, to, showAllInGroup, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getInvoicesReport(counterpartyId, status, from, to, showAllInGroup, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Send invoice
         * @param {string} id
         * @param {InvoiceSendRequest} invoiceSendRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvoice: function (id, invoiceSendRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.sendInvoice(id, invoiceSendRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update invoice
         * @param {string} id
         * @param {InvoiceUpdateRequest} invoiceUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoice: function (id, invoiceUpdateRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateInvoice(id, invoiceUpdateRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update invoice group
         * @param {string} id
         * @param {InvoiceGroupUpdateRequest} invoiceGroupUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoiceGroup: function (id, invoiceGroupUpdateRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateInvoiceGroup(id, invoiceGroupUpdateRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Upload invoice group document
         * @param {string} id
         * @param {any} file
         * @param {string} access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInvoiceGroupDocument: function (id, file, access, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.uploadInvoiceGroupDocument(id, file, access, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * DefaultApi - factory interface
 * @export
 */
exports.DefaultApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.DefaultApiFp(configuration);
    return {
        /**
         * Create invoice
         * @param {InvoiceRequest} invoiceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoice: function (invoiceRequest, options) {
            return localVarFp.createInvoice(invoiceRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create invoice group
         * @param {InvoiceGroupRequest} invoiceGroupRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoiceGroup: function (invoiceGroupRequest, options) {
            return localVarFp.createInvoiceGroup(invoiceGroupRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete invoice
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvoice: function (id, options) {
            return localVarFp.deleteInvoice(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Generate invoice
         * @param {InvoiceGenerateRequest} invoiceGenerateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateInvoice: function (invoiceGenerateRequest, options) {
            return localVarFp.generateInvoice(invoiceGenerateRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Generate preview invoice
         * @param {InvoiceGenerateRequest} invoiceGenerateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePreviewInvoice: function (invoiceGenerateRequest, options) {
            return localVarFp.generatePreviewInvoice(invoiceGenerateRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get all invoice groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInvoiceGroups: function (options) {
            return localVarFp.getAllInvoiceGroups(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get invoice
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoice: function (id, options) {
            return localVarFp.getInvoice(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get invoices count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceCount: function (options) {
            return localVarFp.getInvoiceCount(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get invoice group
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceGroup: function (id, options) {
            return localVarFp.getInvoiceGroup(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get invoice group document
         * @param {string} id
         * @param {string} documentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceGroupDocument: function (id, documentId, options) {
            return localVarFp.getInvoiceGroupDocument(id, documentId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get invoice groups
         * @param {string} counterpartyId
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {string} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceGroups: function (counterpartyId, page, size, sort, filter, options) {
            return localVarFp.getInvoiceGroups(counterpartyId, page, size, sort, filter, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get invoices
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {string} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoices: function (page, size, sort, filter, options) {
            return localVarFp.getInvoices(page, size, sort, filter, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get invoices of resource
         * @param {InvoiceResourceType} type
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesOfResource: function (type, id, options) {
            return localVarFp.getInvoicesOfResource(type, id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get invoices report
         * @param {string} [counterpartyId]
         * @param {InvoiceStatus} [status]
         * @param {string} [from]
         * @param {string} [to]
         * @param {boolean} [showAllInGroup]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesReport: function (counterpartyId, status, from, to, showAllInGroup, options) {
            return localVarFp.getInvoicesReport(counterpartyId, status, from, to, showAllInGroup, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Send invoice
         * @param {string} id
         * @param {InvoiceSendRequest} invoiceSendRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvoice: function (id, invoiceSendRequest, options) {
            return localVarFp.sendInvoice(id, invoiceSendRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update invoice
         * @param {string} id
         * @param {InvoiceUpdateRequest} invoiceUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoice: function (id, invoiceUpdateRequest, options) {
            return localVarFp.updateInvoice(id, invoiceUpdateRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update invoice group
         * @param {string} id
         * @param {InvoiceGroupUpdateRequest} invoiceGroupUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoiceGroup: function (id, invoiceGroupUpdateRequest, options) {
            return localVarFp.updateInvoiceGroup(id, invoiceGroupUpdateRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Upload invoice group document
         * @param {string} id
         * @param {any} file
         * @param {string} access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInvoiceGroupDocument: function (id, file, access, options) {
            return localVarFp.uploadInvoiceGroupDocument(id, file, access, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
var DefaultApi = /** @class */ (function (_super) {
    __extends(DefaultApi, _super);
    function DefaultApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create invoice
     * @param {InvoiceRequest} invoiceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.createInvoice = function (invoiceRequest, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).createInvoice(invoiceRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create invoice group
     * @param {InvoiceGroupRequest} invoiceGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.createInvoiceGroup = function (invoiceGroupRequest, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).createInvoiceGroup(invoiceGroupRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete invoice
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.deleteInvoice = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).deleteInvoice(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Generate invoice
     * @param {InvoiceGenerateRequest} invoiceGenerateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.generateInvoice = function (invoiceGenerateRequest, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).generateInvoice(invoiceGenerateRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Generate preview invoice
     * @param {InvoiceGenerateRequest} invoiceGenerateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.generatePreviewInvoice = function (invoiceGenerateRequest, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).generatePreviewInvoice(invoiceGenerateRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get all invoice groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getAllInvoiceGroups = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getAllInvoiceGroups(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get invoice
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getInvoice = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getInvoice(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get invoices count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getInvoiceCount = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getInvoiceCount(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get invoice group
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getInvoiceGroup = function (id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getInvoiceGroup(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get invoice group document
     * @param {string} id
     * @param {string} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getInvoiceGroupDocument = function (id, documentId, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getInvoiceGroupDocument(id, documentId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get invoice groups
     * @param {string} counterpartyId
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sort]
     * @param {string} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getInvoiceGroups = function (counterpartyId, page, size, sort, filter, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getInvoiceGroups(counterpartyId, page, size, sort, filter, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get invoices
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sort]
     * @param {string} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getInvoices = function (page, size, sort, filter, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getInvoices(page, size, sort, filter, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get invoices of resource
     * @param {InvoiceResourceType} type
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getInvoicesOfResource = function (type, id, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getInvoicesOfResource(type, id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get invoices report
     * @param {string} [counterpartyId]
     * @param {InvoiceStatus} [status]
     * @param {string} [from]
     * @param {string} [to]
     * @param {boolean} [showAllInGroup]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getInvoicesReport = function (counterpartyId, status, from, to, showAllInGroup, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).getInvoicesReport(counterpartyId, status, from, to, showAllInGroup, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Send invoice
     * @param {string} id
     * @param {InvoiceSendRequest} invoiceSendRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.sendInvoice = function (id, invoiceSendRequest, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).sendInvoice(id, invoiceSendRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update invoice
     * @param {string} id
     * @param {InvoiceUpdateRequest} invoiceUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.updateInvoice = function (id, invoiceUpdateRequest, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).updateInvoice(id, invoiceUpdateRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update invoice group
     * @param {string} id
     * @param {InvoiceGroupUpdateRequest} invoiceGroupUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.updateInvoiceGroup = function (id, invoiceGroupUpdateRequest, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).updateInvoiceGroup(id, invoiceGroupUpdateRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Upload invoice group document
     * @param {string} id
     * @param {any} file
     * @param {string} access
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.uploadInvoiceGroupDocument = function (id, file, access, options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).uploadInvoiceGroupDocument(id, file, access, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return DefaultApi;
}(base_1.BaseAPI));
exports.DefaultApi = DefaultApi;
