import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Theme from './theme.js';
import InputAdornment from '@material-ui/core/InputAdornment';
import MultilineTextField from './multiline_textfield.js';
import { generatePreviewInvoice, generateInvoice } from '../services/invoice_service.js';
import ErrorHandler from '../util/error_handler.js';
import Converter from '../util/converter.js'

class GenerateInvoiceDialog extends React.Component {

  constructor(props) {
    super();

    this.state = {
      invoice_request: props.invoice_request
    };

    this.formRef = React.createRef();
    this.MY_TIMER = undefined;
  }

  componentDidMount() {
    if(this.isComplete()) {
      this.generatePreviewInvoice();
    }
  }

  submit(e) {
    e.preventDefault();

    if(!this.formRef.current.checkValidity()) {
      this.formRef.current.reportValidity();
      return;
    }

    generateInvoice(this.state.invoice_request).then((invoice) => {
      this.props.proceed(invoice);
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }
  
  generatePreviewInvoice(e) {
    if(e) {
      e.preventDefault();
    }

    generatePreviewInvoice(this.state.invoice_request).then((preview_blob) => {
      if (preview_blob) {
        var document = preview_blob.slice(0, preview_blob.size, "application/pdf")
        var document_url = URL.createObjectURL(document);
        this.setState({ preview_blob: preview_blob, document_url: document_url, document_loaded: true })
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  handleChange(e) {
    this.state.invoice_request[e.target.name] = e.target.value;
    this.setState({ invoice_request: this.state.invoice_request }, () => {
      this.updateIfcomplete();
    });
  }

  addLineToList() {
    if(!this.state.invoice_request.invoice_lines) {
      this.state.invoice_request.invoice_lines = [];
    }
    this.state.invoice_request.invoice_lines.push({ key: this.getUniqueKey() });
    this.setState({ invoice_request: this.state.invoice_request });
  }

  removeLineFromList(index) {
    this.state.invoice_request.invoice_lines.splice(index, 1);
    this.setState({ invoice_request: this.state.invoice_request }, () => {
      this.updateIfcomplete(true);
    });
  }

  handleLineChange(index, property, e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.state.invoice_request.invoice_lines[index][property] = value;
    this.setState({ invoice_request: this.state.invoice_request }, () => {
      this.updateIfcomplete();
    });
  }

  updateIfcomplete(direct) {
    if(this.MY_TIMER) {
      clearTimeout(this.MY_TIMER);
      this.MY_TIMER = undefined;
    }

    if(this.isComplete()) {
      if(direct) {
        this.generatePreviewInvoice();
      } else {
        this.MY_TIMER = setTimeout(() => {
          if(this.isComplete()) {
            this.generatePreviewInvoice();
          }
        }, 1000); 
      }
    }
  }
  
  isComplete() {
    var complete = true;
    complete &= !this.isEmpty(this.state.invoice_request.invoice_date)
    for(const invoice_line of this.state.invoice_request.invoice_lines) {
      complete &= !this.isEmpty(invoice_line.description);
      complete &= !this.isEmpty(invoice_line.quantity);
      complete &= !this.isEmpty(invoice_line.price);
    }
    return complete;
  }

  isEmpty(value) {
    return value === undefined || value === null || value === "";
  }

  getUniqueKey() {
    return new Date().getTime() + Math.random();
  }

  render() {
    return (
      <Theme>
        <Dialog fullWidth={true} maxWidth={"xl"} open={this.props.show} onClose={this.props.dismiss} >
          <DialogTitle>{this.props.title}</DialogTitle>
          <DialogContent>
            <div className="row">
              <div className="col-md-6 col-lg-6">

                <form autoComplete="off" ref={this.formRef} onSubmit={this.submit.bind(this)}>
                  
                  <div className="row row-small">
                    <div className="col-md-4">
                      <TextField
                        label="Invoice date"
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={this.state.invoice_request.invoice_date}
                        name="invoice_date"
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3 col-lg-3">
                      <div className='center-text'>
                        <span className='form-header-text'>Invoice lines</span>
                      </div>
                    </div>
                  </div>

                  {this.state.invoice_request.invoice_lines && this.state.invoice_request.invoice_lines.map((line, index) => {
                  return (
                    <div key={line.key} className="list-row-item">
                    <span className="list-row-item-header-text">Line #{index + 1}</span>

                      <div className="row row-small">
                        <div className="col-md-5 col-lg-5">
                          <TextField
                            label="Description"
                            variant="outlined"
                            fullWidth
                            required
                            margin="dense"
                            value={line.description}
                            onChange={(e) => {this.handleLineChange(index, "description", e)}}
                          />
                        </div>
                        <div className="col-md-3 col-lg-3">
                          <TextField
                            label="Quantity"
                            variant="outlined"
                            fullWidth
                            type='number'
                            required
                            margin="dense"
                            value={line.quantity}
                            onChange={(e) => {this.handleLineChange(index, "quantity", e)}}
                          />
                        </div>
                        <div className="col-md-3 col-lg-3">
                          <TextField
                            label="Price"
                            variant="outlined"
                            fullWidth
                            required
                            margin="dense"
                            value={line.price}
                            onChange={(e) => {this.handleLineChange(index, "price", e)}}
                            InputProps={{
                              inputComponent: Converter.numberFormatter,
                              startAdornment: <InputAdornment position="start">€</InputAdornment>,
                            }}
                          />
                        </div>
                        <div className="col-md-1 col-lg-1">
                          {index !== 0 &&
                            <Button
                              className="button-icon-only button-inline"
                              color="secondary"
                              type="button"
                              onClick={(e) => {this.removeLineFromList(index, e)}}
                              startIcon={<Icon>clear</Icon>} />
                          }
                        </div>
                      </div>
                    </div>
                  )})}
                    
                  <div className="row row-small">
                    <div className="col-md">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={(e) => {this.addLineToList(e)}}
                        startIcon={<Icon>add</Icon>}>
                        Add line
                      </Button>
                    </div>
                  </div>
                  
                  <div className="row row-small">
                    <div className="col-md-12">
                      <MultilineTextField
                        label="Comment"
                        variant="outlined"
                        fullWidth
                        min_rows={4}
                        margin="dense"
                        name="comment"
                        value={this.state.invoice_request.comment}
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-6 col-lg-6">
                <iframe className="invoice-viewer" src={this.state.document_url} type="application/pdf" />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.dismiss()} color="primary">Cancel</Button>
            <Button onClick={this.submit.bind(this)} color="primary">Create</Button>
          </DialogActions>
        </Dialog>
      </Theme>
    )
  }
}

GenerateInvoiceDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
  title: PropTypes.string,
  invoice_request: PropTypes.object,
  fields: PropTypes.array
}

export default confirmable(GenerateInvoiceDialog);
