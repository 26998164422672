import { DefaultApi } from "generated-authentication-api";
import { checkStatus, getHeaders, handleResponse } from './web_service';
import decode from 'jwt-decode';

export const API = new DefaultApi({
  basePath: window.appConfig.API_HOSTNAME
});

export function login(username, password) {
  return API.login({
    email: username,
    password: password
  })
  .then(checkStatus)
  .then(res => {
    var token = res.headers.authorization;
    token = token.replace('Bearer ','');
    setToken(token)
    return Promise.resolve(res);
  }).catch(error => {
    return Promise.reject(error)
  });
}

export function tempLogin(data) {
  return API.tempLogin(data).then(handleResponse);
}

export function forgotPassword(data) {
  return API.forgotPassword(data).then(handleResponse);
}

export function verifyMfa(data) {
  return API.verifyMfa(data, getHeaders()).then(handleResponse);
}

export function sendMfa() {
  return API.sendMfa(getHeaders()).then(handleResponse);
}

export function loggedIn() {
  const token = getToken()
  return !!token && !isTokenExpired(token)
}

export function getExpiration() {
  const token = getToken()
  return getTokenExpiration(token)
}

export function hasType(types) {
  const token = getToken()
  return !!token && !isTokenExpired(token) && tokenHasType(token, types);
}

export function getType() {
  const token = getToken()
  return !!token && !isTokenExpired(token) && getTokenType(token);
}

export function mfaVerification() {
  const token = getToken();
  if(token) {
    const decoded = decode(token);
    return !decoded.mfa_verified;
  }
  return false;
}

export function actionRequired() {
  const token = getToken();
  if(token) {
    const decoded = decode(token);
    return decoded.actions_required && decoded.actions_required.length > 0;
  }
  return false;
}

export function hasAction(action) {
  const token = getToken();
  if(token) {
    const decoded = decode(token);
    if(decoded.actions_required) {
      return decoded.actions_required.includes(action)
    }
  }
  return false;
}

export function getUserId() {
  const token = getToken();
  if(token) {
    const decoded = decode(token);
    return decoded.sub;
  }
}

export function getAccountId() {
  const token = getToken();
  if(token) {
    const decoded = decode(token);
    return decoded.account_id;
  }
}

export function isAdmin() {
  const token = getToken();
  if(token) {
    const decoded = decode(token);
    return decoded.user_role === "admin";
  }
  return false;
}

export function isAuthorised() {
  const token = getToken();
  if(token) {
    const decoded = decode(token);
    return decoded.user_role === "authorised";
  }
  return false;
}

export function isReadOnly() {
  const token = getToken();
  if(token) {
    const decoded = decode(token);
    return decoded.user_role === "read_only";
  }
  return false;
}

export function getTokenExpiration(token) {
  try {
    const decoded = decode(token);
    return decoded.exp;
  }
  catch (err) {
    return Date.now() / 1000;
  }
}

export function isTokenExpired(token) {
  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      return true;
    }
    else
      return false;
  }
  catch (err) {
    return false;
  }
}

export function tokenHasType(token, types) {
  var type = decode(token).account_type;
  return types.includes(type);
}

export function getTokenType(token) {
  return decode(token).account_type;
}

export function setToken(idToken) {
  localStorage.setItem('id_token', idToken)
}

export function getToken() {
  return localStorage.getItem('id_token')
}

export function logout() {
  localStorage.removeItem('id_token');
}

export function getProfile() {
  return decode(getToken());
}
