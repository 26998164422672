import React from 'react';
import Link from '@material-ui/core/Link';
import { mfaVerification, logout } from '../../services/authentication_service';
import DomUtil from '../../util/dom_util';
import ErrorHandler from '../../util/error_handler';
import AsyncButton from '../../components/async_button';
import TextField from '@material-ui/core/TextField';
import { sendMfa, verifyMfa } from '../../services/authentication_service';
import alertDialog from '../../components/alert';

class MfaVerification extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.sendMfa = this.sendMfa.bind(this);
    this.verifyMfa = this.verifyMfa.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  componentWillMount() {
    if (!mfaVerification()) {
      this.redirect();
    } else {
      this.sendMfa();
    }
  }

  redirect() {
    const params = new URLSearchParams(this.props.location.search);
    var ref = params.get('ref') ? params.get('ref') : "/";
    this.props.history.replace(ref);
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value
      }
    )
  }

  sendMfa(e) {
    if(e) {
      e.preventDefault();
    }
    sendMfa().then((res) => {
      if(e) {
        alertDialog("Message", "A new code has been sent")
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  verifyMfa(e) {
    e.preventDefault();
    var form = e.currentTarget;
    DomUtil.disableFormSubmitButtonClass(form);
    verifyMfa({ code: this.state.code }).then((res) => {
      this.redirect();
    }).catch(error => {
      ErrorHandler.showError(error);
      DomUtil.enableFormSubmitButtonClass(form);
    });
  }

  logout(e) {
    e.preventDefault();
    logout();
    this.redirect();
  }
  
  render() {
    return (
      <div>

        <div className="container container-content container-center-small">

          <div className="container-body">
            
            <div className="row">
              <div className="col-sm">
                <div className="login-logo">
                  <img alt="logo" src="/images/logo.png" />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm">
                <span>We've just sent a code to your email address</span>
              </div>
            </div>

            <div className="row">
              <div className="col-md">

                <form onSubmit={this.verifyMfa.bind(this)}>

                  <div className="row">
                      <div className="col-md">
                        <TextField
                          label="Code"
                          variant="outlined"
                          required
                          fullWidth
                          margin="dense"
                          name="code"
                          autoComplete="off"
                          type="tel"
                          pattern='[0-9]*'
                          autoFocus
                          onChange={this.handleChange.bind(this)}
                          inputProps={{
                            maxLength: 6
                          }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md">
                        <AsyncButton
                          rootClassName="fullwidth-button"
                          className="fullwidth-button"
                          type="submit"
                          variant="contained"
                          color="primary"
                          icon="lock"
                          text="Verify" />
                      </div>
                    </div>

                </form>
                
              </div>
            </div>

            <div className="row">
              <div className="col-md">
                <Link href="" onClick={this.sendMfa.bind(this)} >Send another code</Link>
              </div>
            </div>

            <div className="row">
              <div className="col-md">
                <Link href="" onClick={this.logout.bind(this)} >Back to login</Link>
              </div>
            </div>

          </div>

        </div>

      </div >
    );
  }
}

export default MfaVerification;
