import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LoadingSelectField from "../../../../shared/components/loading_select_field";

class AddressForm extends React.Component {

  constructor(props) {
    super(props)

    var location = this.props.location;

    if(location.type === 'address') {
      if(location.country) {
        var address = this.props.account.addresses.find(a =>
          a.country.country_code === location.country.country_code &&
          a.city === location.city &&
          a.postal_code === location.postal_code &&
          a.address === location.address
        );

        if(address) {
          location = address;
        } else {
          location.is_custom = true;
        }
      } else {
        location = {
          is_custom: false,
          address: "",
          postal_code: "",
          city: "",
          country: {}
        }
      }
    }

    this.state = {
      location: location
    };
  }
  
  firstLetter(option) {
    return option.name[0].toUpperCase();
  }

  handleDefaulAddressChange(e, newValue) {    
    if(newValue && !newValue.is_custom) {
      this.state.location = newValue;
    } else {
      this.state.location = {
        is_custom: newValue && newValue.is_custom,
        address: "",
        postal_code: "",
        city: "",
        country: {}
      };
    }
    
    this.state.location.type = "address";
    this.setState({ location: this.state.location }, () => {
      this.props.onLocationUpdate(this.props.property, this.state.location);
    });
  }

  handleAddressChange(e) {
    this.state.location[e.target.name] = e.target.value;
    this.setState({ location: this.state.location }, () => {
      this.props.onLocationUpdate(this.props.property, this.state.location);
    });
  }
  
  handleAutoCompleteChange(e, newValue) {
    if(newValue) {
      this.state.location.country = newValue;
    } else {
      this.state.location.country = { };
    }
    this.setState({ location: this.state.location }, () => {
      this.props.onLocationUpdate(this.props.property, this.state.location);
    });
  }

  formatAddress(address) {
    if(!address) {
      return "";
    }
    if(address.is_custom) {
      return "-- Other address --"
    }
    if(address.name && address.country.name) {
      return address.name + " (" + address.address + ", " + address.postal_code + " " + address.city + ", " + address.country.name + ")";
    }
    if(address.country.name) {
      return address.address + ", " + address.postal_code + " " + address.city + ", " + address.country.name;
    }
  }

  shrink(properties) {
    var shrink = true;
    var prop = this.state.location;

    for(const property of properties) {
      if(prop) {
        if(prop[property] === undefined || prop[property] === null || prop[property] === "") {
          shrink = false;
        } else {
          prop = prop[property];
        }
      }
    }

    return {
      shrink: shrink,
    };
  }

  render() {
    return (
      <div>
        
        <div className="row row-small">
          <div className="col-md-12 col-lg-8">
            { this.props.account &&
              <Autocomplete
                key="location"
                options={this.props.account.addresses.concat([{ is_custom: true }])}
                getOptionLabel={(option) => this.formatAddress(option)}
                value={this.state.location}
                onChange={this.handleDefaulAddressChange.bind(this)}
                renderInput={(params) => 
                  <TextField {...params}
                    label="Address"
                    variant="outlined"
                    required={this.props.required}
                    fullWidth
                    margin="dense"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />                      
                }
              />
            }
            { !this.props.account &&
              <LoadingSelectField
                label="Address"
                shrink={false}
              />
            }
          </div>
        </div>
        
        <div className="row row-small">
          <div className="col-md-6 col-lg-4">
            <TextField
              label="Address line"
              variant="outlined"
              required={this.props.required}
              fullWidth
              margin="dense"
              disabled={!this.state.location.is_custom}
              value={this.state.location.address}
              InputLabelProps={this.shrink(["address"])}
              name="address"
              onChange={this.handleAddressChange.bind(this)}
            />
          </div>
          <div className="col-md-6 col-lg-4">
            <TextField
              label="Postal code"
              variant="outlined"
              required={this.props.required}
              fullWidth
              disabled={!this.state.location.is_custom}
              margin="dense"
              value={this.state.location.postal_code}
              InputLabelProps={this.shrink(["postal_code"])}
              name="postal_code"
              onChange={this.handleAddressChange.bind(this)}
            />
          </div>
        </div>

        <div className="row row-small">
          <div className="col-md-6 col-lg-4">
            <TextField
              label="City"
              variant="outlined"
              required={this.props.required}
              fullWidth
              disabled={!this.state.location.is_custom}
              margin="dense"
              value={this.state.location.city}
              InputLabelProps={this.shrink(["city"])}
              name="city"
              onChange={this.handleAddressChange.bind(this)}
            />
          </div>
          <div className="col-md-6 col-lg-4">
            { this.props.countries &&
              <Autocomplete
                key="country_code"
                options={this.props.countries.sort((a, b) => -b.name[0].localeCompare(a.name[0]))}
                groupBy={this.firstLetter.bind(this)}
                getOptionLabel={(option) => option.name}
                disabled={!this.state.location.is_custom}
                value={this.state.location.country}
                onChange={this.handleAutoCompleteChange.bind(this)}
                renderInput={(params) => 
                  <TextField {...params}
                    label="Country"
                    variant="outlined"
                    required={this.props.required}
                    fullWidth
                    margin="dense"
                    value={this.state.location.country_input}
                    name="country_input"
                    onChange={this.handleAddressChange.bind(this)}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                    InputLabelProps={this.shrink(["country", "country_code"])}
                  />                      
                }
              />
            }
            { !this.props.countries &&
              <LoadingSelectField
                label="Country"
                shrink={false}
              />
            }
          </div>
        </div>

      </div>
        
    );
  }
}

export default AddressForm;
