import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Theme from './theme';
import MultiShipmentDocumentUpload from './multi_shipment_document_upload'

class DocumentUploadDialog extends React.Component {

  constructor(props) {
    super();
    this.state = {
      documents: [],
      comment: undefined
    };

    this.formRef = React.createRef();
  }

  submit(e) {
    e.preventDefault();

    if(!this.formRef.current.checkValidity()) {
      this.formRef.current.reportValidity();
      return;
    }

    this.props.proceed({
      documents: this.state.documents,
      comment: this.state.comment
    });
  }

  addDocumentToList() {
    this.state.documents.push({ key: this.getUniqueKey() });
    this.setState({ documents: this.state.documents });
  }

  documentsUpdated(documents) {
    this.state.documents = documents;
    this.setState({ documents: this.state.documents });
  }

  handleCommentChange(e) {
    this.setState({ comment: e.target.value });
  }

  getUniqueKey() {
    return new Date().getTime() + Math.random();
  }

  render() {
    return (
      <Theme>
        <Dialog fullWidth={true} maxWidth={"md"} open={this.props.show} onClose={this.props.dismiss} >
          <DialogTitle>Upload documents</DialogTitle>
          <DialogContent>
            <form autoComplete="off" ref={this.formRef} onSubmit={this.submit.bind(this)}>
                
              <div className="row">
                <div className="col-md">
                  <MultiShipmentDocumentUpload 
                    title="Documents"
                    shipment_id={this.props.shipment_id}
                    access="all"
                    required={true}
                    documentsUpdated={this.documentsUpdated.bind(this)}
                    documents={this.state.documents} />                        
                </div>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.dismiss()} color="primary">Cancel</Button>
            <Button onClick={this.submit.bind(this)} color="primary">Upload</Button>
          </DialogActions>
        </Dialog>
      </Theme>
    )
  }
}

DocumentUploadDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
  shipment_id: PropTypes.string
}

export default confirmable(DocumentUploadDialog);
