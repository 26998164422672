import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Button from '@material-ui/core/Button';
import AsyncButton from './async_button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Theme from './theme';

class InboundDialog extends React.Component {

  constructor(props) {
    super();
    this.state = {
      counted_quantity: this.getTotalUnloadingQuantity(props.parts),
      total_quantity: this.getTotalQuantity(props.parts),
      total_unloading_quantity: this.getTotalUnloadingQuantity(props.parts),
      _package: props._package,
      parts: props.parts
    };

    this.formRef = React.createRef();
  }

  generatePackageNumberLabel(e) {
    this.props.generatePackageNumberLabel(e)
  }

  handleCountedQuantityChange(e) {
    var counted_quantity = e.target.value === "" ? null : e.target.value;
    this.setState({ counted_quantity: counted_quantity });
    
    var quantity_left = counted_quantity;
    for(const part of this.state.parts) {
      var part_quantity = Math.min(part.quantity, quantity_left);
      part.unloading_quantity = part_quantity;
      quantity_left -= part_quantity;
    }

    this.state.parts[this.state.parts.length - 1].unloading_quantity += quantity_left;

    this.setState({ parts: this.state.parts }, () => this.setTotalUnloadingQuantity());
  }

  handlePartChange(index, property, e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.state.parts[index][property] = value;
    this.setState({ parts: this.state.parts }, () => this.setTotalUnloadingQuantity());
  }

  getTotalQuantity(parts) {
    var total = 0;
    for(const part of parts) {
      if(part.quantity) {
        total += Number(part.quantity);
      }
    }
    return total;
  }

  getTotalUnloadingQuantity(parts) {
    var total = 0;
    for(const part of parts) {
      if(part.unloading_quantity) {
        total += Number(part.unloading_quantity);
      }
    }
    if(total === 0) {
      return undefined;
    }
    return total;
  }

  setTotalUnloadingQuantity() {
    var total = 0;
    for(const part of this.state.parts) {
      if(part.unloading_quantity) {
        total += Number(part.unloading_quantity);
      }
    }
    this.setState({ total_unloading_quantity: total });
  }

  submit(e) {
    e.preventDefault();

    if(!this.formRef.current.checkValidity()) {
      this.formRef.current.reportValidity();
      return;
    }

    this.props.proceed(this.state.parts)
  }

  hasError() {
    return this.state.counted_quantity !== undefined && this.state.total_unloading_quantity !== undefined && Number(this.state.counted_quantity) !== Number(this.state.total_unloading_quantity);
  }

  getPartClassname(part) {
    if(part.unloading_quantity === undefined) {
      return;
    }
    return Number(part.quantity) !== Number(part.unloading_quantity) ? "Mui-warning" : "Mui-success";
  }

  render() {
    return (
      <Theme>
        <Dialog fullWidth={true} maxWidth={"lg"} open={this.props.show} onClose={this.props.cancel} >
          <DialogTitle>{this.props.title}</DialogTitle>
          <AsyncButton
            className='dialog_top_right'
            variant="outlined"
            color="primary"
            icon="print"
            text="New package label"
            onClick={this.generatePackageNumberLabel.bind(this)} />
          <DialogContent>
            <form autoComplete="off" ref={this.formRef} onSubmit={this.submit.bind(this)}>
              <div key={this.state._package.package_number} className="list-row-item">
                  <span className="list-row-item-header-text">Part number {this.state.parts[0].part_number} - {this.state.parts[0].description}</span>

                  <div className="row">
                    <div className="col-md-2 col-lg-2">
                      <TextField
                        label="Counted quantity"
                        variant="outlined"
                        error={this.hasError()}
                        fullWidth
                        autoFocus
                        type='number'
                        margin="dense"
                        value={this.state.counted_quantity}
                        onChange={this.handleCountedQuantityChange.bind(this)}
                        InputProps={{
                          inputProps: { min: 0 }
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-md-3 col-lg-3">
                    </div>
                    <div className="col-md-2 col-lg-2">
                      <TextField
                        label="Total quantity"
                        variant="outlined"
                        fullWidth
                        disabled
                        type='number'
                        margin="dense"
                        value={this.state.total_quantity}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-lg-2">
                      <TextField
                        label="Total unloading quantity"
                        variant="outlined"
                        error={this.hasError()}
                        fullWidth
                        disabled
                        type='number'
                        margin="dense"
                        value={this.state.total_unloading_quantity}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>

                  {this.state.parts && this.state.parts.map((part, index) => {
                  return (
                    <div key={this.state._package.key + part.key}>

                      <div className="row">
                        <div className="col-md-2 col-lg-2">
                          <TextField
                            label="Order number"
                            variant="outlined"
                            fullWidth
                            disabled
                            margin="dense"
                            value={part.order_number}
                          />
                        </div>
                        <div className="col-md-3 col-lg-3">
                          <TextField
                            label="Reference"
                            variant="outlined"
                            fullWidth
                            disabled
                            margin="dense"
                            value={part.reference}
                          />
                        </div>
                        <div className="col-md-2 col-lg-2">
                          <TextField
                            label="Quantity"
                            variant="outlined"
                            fullWidth
                            type='number'
                            disabled
                            margin="dense"
                            value={part.quantity}
                          />
                        </div>
                        <div className="col-md-2 col-lg-2">
                          <TextField
                            className={this.getPartClassname(part)}
                            label="Unloading quantity"
                            variant="outlined"
                            fullWidth
                            required
                            type='number'
                            margin="dense"
                            value={part.unloading_quantity}
                            onChange={(e) => {this.handlePartChange(index, "unloading_quantity", e)}}
                            InputProps={{
                              inputProps: { min: 0 }
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                        <div className="col-md-3 col-lg-3">
                          <TextField
                            label="New package number"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            value={part.new_package_number}
                            onChange={(e) => {this.handlePartChange(index, "new_package_number", e)}}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )})}
                  
                </div>
              </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.cancel()} color="primary">Cancel</Button>
            <Button disabled={this.hasError()} onClick={this.submit.bind(this)} color="primary">Save</Button>
          </DialogActions>
        </Dialog>
      </Theme>
    )
  }
}

InboundDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
  title: PropTypes.string,
  _package: PropTypes.object,
  parts: PropTypes.object,
  generatePackageNumberLabel: PropTypes.func
}

export default confirmable(InboundDialog);
