import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { getCurrentAccount } from '../../services/account_service';
import { isAdmin, hasType, isReadOnly } from '../../services/authentication_service';
import Mapper from '../../util/mapper';
import ErrorHandler from '../../util/error_handler';
import Table from "../../components/table";
import LoadingText from '../../components/loading_text';

class Account extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      account: {}
    }
  }

  componentDidMount() {
    this.getCurrentAccount();
  }

  getCurrentAccount() {
    getCurrentAccount().then((account) => {
      if (account) {
        this.setState({ account: account }, () => {
          this.setUserRows();
          this.setAddressRows();
        });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  openUser(user) {
    if(isAdmin()) {
      this.props.history.push(`/account/` + user.id + this.props.location.search);
    }
  }

  getUserHeaders() {
    return [
      { name: "Email", key: "email" },
      { name: "Full name", key: "full_name" },
      { name: "Role", key: "role" }
    ];
  }

  setUserRows() {
    var rows = [];
    this.state.account.users.forEach(user => {
      rows.push({
        id: user.id,
        email: user.email,
        full_name: user.full_name,
        role: Mapper.fromUserRole(user.role)
      });
    });
    this.setState({ user_rows: rows });
  }

  openAddress(address) {
    if(!isReadOnly()) {
      this.props.history.push(`/account/addresses/` + address.id + this.props.location.search);
    }
  }

  getAddressHeaders() {
    return [
      { name: "Name", key: "name" },
      { name: "Address line", key: "address" },
      { name: "Postcal code", key: "postal_code" },
      { name: "City", key: "city" },
      { name: "Country", key: "country_name" }
    ];
  }

  setAddressRows() {
    var rows = [];
    this.state.account.addresses.forEach(address => {
      rows.push({
        id: address.id,
        name: address.name,
        address: address.address,
        postal_code: address.postal_code,
        city: address.city,
        country_name: address.country.name
      });
    });
    this.setState({ address_rows: rows });
  }

  render() {
    return (
      <div>
        
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem active>Account</BreadcrumbItem>
          </Breadcrumb>

          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Company information</label>
                </div>
              </div>
            </div>

            <div className="row row-small">
              <div className="col-sm-6">
                <table className="table table-details" >
                  <tbody>
                    <tr className="row-no-top-border-and-padding">
                      <td style={{width: "230px"}} className="header-text">Name</td>
                      <td><LoadingText text={this.state.account.name} /></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          
          {(hasType("customer") || hasType("forwarder")) &&
            <div className="container-body">
              <div className="row">
                <div className="col-md">
                  <div className="form-header">
                    <label>Addresses</label>
                  </div>
                </div>
              </div>

              { !isReadOnly() &&
                <div className="row row-small">
                  <div className="col-sm">
                    <Link className="link-button-wrapper" to={`/account/addresses/create` + this.props.location.search}>
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<Icon>add</Icon>}>
                        Add
                      </Button>
                    </Link>
                  </div>
                </div>
              }

              <div className="row row-small">
                <div className="col-sm">
                  <Table
                    loading_rows={3}
                    headers={this.getAddressHeaders()}
                    rows={this.state.address_rows}
                    selectable={!isReadOnly()}
                    onRowSelected={this.openAddress.bind(this)}
                    empty_rows_text="There are no addresses"
                  />
                </div>
              </div>
              
            </div>
          }
                    
          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Users</label>
                </div>
              </div>
            </div>

            { isAdmin() &&
              <div className="row row-small">
                <div className="col-sm">
                  <Link className="link-button-wrapper" to={`/account/invite` + this.props.location.search}>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<Icon>add</Icon>}>
                      Invite
                    </Button>
                  </Link>
                </div>
              </div>
            }

            <div className="row row-small">
              <div className="col-sm">
                <Table
                  loading_rows={3}
                  headers={this.getUserHeaders()}
                  rows={this.state.user_rows}
                  selectable={isAdmin()}
                  onRowSelected={this.openUser.bind(this)}
                  empty_rows_text="There are no users"
                />
              </div>
            </div>
            
          </div>
          
        </div>
      </div >
    );
  }
}

export default Account;
