import React from 'react';
import Converter from '../../../util/converter'
import LoadingContent from '../../../components/loading_content.js';
import PackagesTable from '../../../components/packages_table.js';
import AsyncButton from '../../../components/async_button.js';
import { exportColliToDocument } from '../../../services/quote_service';
import DomUtil from '../../../util/dom_util.js';
import ErrorHandler from '../../../util/error_handler.js';
import fileDownload from 'js-file-download';

class ColliContainer extends React.Component {

  getTotalPackages() {
    if(!this.props.quote.colli) {
      return 0;
    }
    return this.props.quote.colli.length;
  }

  getTotalParts() {
    if(!this.props.quote.colli) {
      return 0;
    }

    var total = 0;
    this.props.quote.colli.forEach(_package => {
      if(_package.parts) {
        total += _package.parts.length;
      }
    });
    return total;
  }

  exportColli(e) {
    e.preventDefault();
    var button = e.currentTarget;
    DomUtil.disableClass(button);

    exportColliToDocument(this.props.quote.id).then((blob) => {
      fileDownload(blob, `qoute_${this.props.quote.id}_colli_export.xlsx`);
      DomUtil.enableClass(button);
    }).catch((error) => {
      ErrorHandler.showError(error);
      DomUtil.enableClass(button);
    });
  }

  render() {
    return (
      <div className="container-body">
        <div className="row">
          <div className="col-md">
            <div className="form-header">
              <label>Colli</label>
            </div>
          </div>
        </div>

        <div className="row row-small">
          <div className="col-md-12 col-lg-6">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td width={200} className="header-text">Total packages</td>
                  <td><LoadingContent mustBeLoaded={this.props.quote} content={() => Converter.formatNumber(this.getTotalPackages(), 0)} /></td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Total parts</td>
                  <td><LoadingContent mustBeLoaded={this.props.quote} content={() => Converter.formatNumber(this.getTotalParts(), 0)} /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="col-md">
            <AsyncButton
              variant="outlined"
              color="secondary"
              onClick={this.exportColli.bind(this)}
              icon="download"
              text="Export" />
          </div>
        </div>

        <div className="row">
          <div className="col-sm">
            <PackagesTable
                name="quote_packages_table_state"
                data={this.props.quote}
                headers={[ 
                  "number",
                  "loading_number",
                  "order_number",
                  "quantity",
                  "reference",
                  "part_price",
                  "country_of_origin",
                  "description"
                ]}
            />
          </div>
        </div>

      </div>
    );
  }
}

export default ColliContainer;
