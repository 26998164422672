import React from 'react';
import { Link } from 'react-router-dom';
import { getCachedEnvironment, getCurrentEnvironment, cacheEnvironment } from '../../shared/services/environment_service';
import { mfaVerification, actionRequired } from '../../shared/services/authentication_service';
import ErrorHandler from '../../shared/util/error_handler';

class ParentMenu extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      environment: getCachedEnvironment()
    }
  }

  componentDidMount() {
    if(this.hasAccess()) {
      this.getCurrentEnvironment();
    }
  }

  hasAccess() {
    return !mfaVerification() && !actionRequired();
  }

  getCurrentEnvironment() {
    getCurrentEnvironment().then((environment) => {
      if (environment) {
        cacheEnvironment(environment);
        this.setState({ environment: environment });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  hasModule(module) {
    if(!module) {
      return true;
    }
    return this.state.environment &&
      this.state.environment.modules &&
      this.state.environment.modules.includes(module);
  }

  getMenuItem(item) {
    if(item.onClick) {
      return (
        <a className="nav-item" href="/" onClick={item.onClick}>
          {this.getContent(item)}
        </a>
      );
    }
    return (
      <Link to={'/' + item.link}>
        {this.getContent(item)}
      </Link>
    );
  }

  getSubMenuItems(item) {
    var items = [];
    item.sub_items.forEach((sub_item, index) => {
      items.push(
        <li key={"sidebar-sticky.subitem" + item.name + index} className={'nav-item ' + (this.isActive(sub_item.link)  ? "active" : "")}>
          {this.getMenuItem(sub_item)}
        </li>
      )
    });
    return items;
  }

  getMobileMenuItem(item) {
    if(item.onClick) {
      return (
        <a className="nav-link" href="/" onClick={item.onClick}>
          {this.getContent(item)}
        </a>
      );
    }
    return (
      <Link className="nav-link" to={'/' + item.link}>
        {this.getContent(item)}
      </Link>
    );
  }

  getContent(item) {
    var rows = [];
    if(item.icon) {
      rows.push(<i key={"menu-item-icon" + item.name} className={item.icon}></i> );
    }
    rows.push(<span key={"menu-item-name" + item.name} >{item.name}</span>);
    rows.push(item.counter > 0 && <span key={"menu-item-counter" + item.name} className="numberCircle">{item.counter}</span>);
    rows.push(item.customerPostfix);
    return rows;
  }

  getTopNavMenuItem(item) {
    if(item.onClick) {
      return (
        <a className="nav-link nav-top-link" href="/" onClick={item.onClick}>
          <i className={item.icon}></i>
        </a>
      );
    }
    return (
      <Link className="nav-link nav-top-link" to={'/' + item.link}>
        <i className={item.icon}></i>
      </Link>
    );
  }

  isActive(link) {
    var path = this.props.location.pathname;
    var paths = path.split("/");
    var rootPath = paths.length > 1 ? paths[1] : "";
    var subPath = paths.length > 2 ? paths[2] : "";
    
    if(subPath) {
      return rootPath === link || `${rootPath}/${subPath}` === link; 
    }
    return rootPath === link; 
  }

  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-md navbar-light navbar-top fixed-top d-none d-md-block">
          <div className="navbar-collapse collapse">
            <Link className="navbar-brand" to='/'>
              <img alt="logo" src="/images/logo.png" />
            </Link>
            <div className="navbar-collapse">
              <ul key="main-sidebar" className="navbar-nav ml-auto">
                {this.props.topbar_items.map(topbar_item => {
                  return (
                    <li key={topbar_item.name} className={'nav-item ' + (this.isActive(topbar_item.link)  ? "active" : "")}>
                      {this.getTopNavMenuItem(topbar_item)}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </nav>
        <div className="nav-bar-top-breadcrumbs"/>

        <nav id="sidebar" className="d-none d-md-block" >
          <div className={this.state.environment && this.state.environment.logo ? "sidebar-sticky sidebar-sticky-with-environment" : "sidebar-sticky"} >
            {this.props.sidebar_items.map((sidebar_item, index) => {
              return (
                <div key={"sidebar-sticky.sidebar" + index}>
                  <div className="sidebar-title">
                    <p>{sidebar_item.title}</p>
                  </div>
                  <ul key={"sidebar-sticky.sidebar_item" + index} className="list-unstyled components">
                    {sidebar_item.items.map((item, item_index) => {
                      if(!this.hasModule(item.required_module)) {
                        return;
                      }
                      if(item.sub_items) {
                        return (
                          <li key={"sidebar-sticky.item" + item_index} className={'nav-item'}>
                            {this.getMenuItem(item)}
                            <ul key={"sidebar-sticky.subitems" + item_index} className="list-unstyled components submenu">
                              {this.getSubMenuItems(item)}
                            </ul>
                          </li>
                        );
                      }
                      return (
                        <li key={item.name} className={'nav-item ' + (this.isActive(item.link)  ? "active" : "")}>
                          {this.getMenuItem(item)}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
          {this.state.environment && this.state.environment.logo &&
            <div className="environment-sidebar-container">
              <div className="environment-sidebar-logo">
                <img src={this.state.environment.logo} />
              </div>
            </div>
          }
        </nav>
        
        <nav className="navbar navbar-expand-lg navbar-light bg-light d-block d-md-none navbar-mobile">
          <Link className="navbar-brand" to='/'>
            <img alt="logo" src="/images/logo.png" />
          </Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul key="navbar-mobile"className="navbar-nav">
              {this.props.sidebar_items.map(sidebar_item => {
                return sidebar_item.items.map(item => {
                  if(!this.hasModule(item.required_module)) {
                    return;
                  }
                  if(item.sub_items) {
                    return item.sub_items.map(sub_item => {
                      var aggregate = {
                        icon: item.icon,
                        name: `${sub_item.name} ${item.name}`,
                        link: sub_item.link,
                        counter: sub_item.counter
                      }
                      return (<li key={"navbar-mobile" + aggregate.name} className={'nav-item ' + (this.isActive(aggregate.link)  ? "active" : "")} data-toggle="collapse" data-target=".navbar-collapse.show">
                        {this.getMobileMenuItem(aggregate)}
                      </li>)
                    });
                  }
                  return (
                    <li key={"navbar-mobile" + item.name} className={'nav-item ' + (this.isActive(item.link)  ? "active" : "")} data-toggle="collapse" data-target=".navbar-collapse.show">
                      {this.getMobileMenuItem(item)}
                    </li>
                  );
                });
              })}
              {this.props.topbar_items.map(topbar_item => {
                return (
                  <li key={"navbar-mobile" + topbar_item.name} className={'nav-item ' + (this.isActive(topbar_item.link)  ? "active" : "")} data-toggle="collapse" data-target=".navbar-collapse.show">
                    {this.getMobileMenuItem(topbar_item)}
                  </li>
                );
              })}
            </ul>
          </div>
        </nav>

        <div className="container-fluid">
            { this.props.children }
        </div>
      </div>
    );
  }
}

export default ParentMenu;
