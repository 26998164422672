import React from 'react';
import Converter from '../../../util/converter.js'
import ContainersTable from '../../../components/containers_table.js';

class ContainersContainer extends React.Component {

  getTotalContainers() {
    var total = 0;
    this.props.quote.containers.forEach(container => {
      if(container.quantity) {
        total += Number(container.quantity);
      }
    });
    return total;
  }

  render() {
    return (
      <div className="container-body">
        <div className="row">
          <div className="col-md">
            <div className="form-header">
              <label>Containers</label>
            </div>
          </div>
        </div>

        <div className="row row-small">
          <div className="col-md-12 col-lg-6">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td width={200} className="header-text">Total containers</td>
                  <td>{Converter.formatNumber(this.getTotalContainers(), 0)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="col-sm">
            <ContainersTable
                name="quote_containers_table_state"
                data={this.props.quote}
                headers={[ "quantity", "size", "type" ]}
            />
          </div>
        </div>

      </div>
    );
  }
}

export default ContainersContainer;
