import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getInvoicesReport } from '../../services/invoice_service.js';
import ErrorHandler from '../../util/error_handler.js';
import Converter from '../../util/converter.js';
import { hasType } from '../../services/authentication_service';
import Invoices from "./components/invoices";

class OpenInvoices extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      invoices: []
    };
  }
  
  componentWillMount() {
    if(hasType("forwarder")) {
      this.getOpenInvoices();
    }
  }

  getOpenInvoices() {
    getInvoicesReport(undefined, 'open', undefined, undefined).then((invoices) => {
      if (invoices) {
        this.setState({ invoices: invoices });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }
  
  getGrossTotalInvoiceType(type) {
    var invoicesOfType = this.getInvoicesOfType(type);
    
    var total = 0;
    for(const invoice of invoicesOfType) {
      total += invoice.total_gross_amount;
    }

    return total;    
  }

  getNetTotalInvoiceType(type) {
    var invoicesOfType = this.getInvoicesOfType(type);

    var total = 0;
    for(const invoice of invoicesOfType) {
      total += invoice.total_net_amount;
    }

    return total;    
  }

  getInvoicesOfType(type) {
    if(!this.state.invoices) {
      return [];
    }
    return this.state.invoices.filter((invoice) => invoice.type === type);
  }
 
  getCountOfInvoiceType(type) {
    var invoicesOfType = this.getInvoicesOfType(type);
    if(!invoicesOfType) {
      return "0";
    }
    return invoicesOfType.length.toString();
  }

  getGrossProfit() {
    return this.getGrossTotalInvoiceType("sale") - this.getGrossTotalInvoiceType("purchase");
  }

  getNetProfit() {
    return this.getNetTotalInvoiceType("sale") - this.getNetTotalInvoiceType("purchase");
  }

  getGrossProfitClass() {
    if(this.getGrossProfit() > 0) {
      return "value-positive";
    }
    if(this.getGrossProfit() < 0) {
      return "value-negative";
    }
    return "";
  }

  getNetProfitClass() {
    if(this.getNetProfit() > 0) {
      return "value-positive";
    }
    if(this.getNetProfit() < 0) {
      return "value-negative";
    }
    return "";
  }

  openInvoice(event) {
    var newTab = event.event.ctrlKey || event.event.metaKey;
    var url = `/invoices/open/${event.data.id}${this.props.location.search}`
    if(newTab) {
      window.open(url, "_blank", "noreferrer");
    } else {
      this.props.history.push(url);
    }
  }
  
  render() {
    return (
      <div>
        
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem active>Open Invoices</BreadcrumbItem>
          </Breadcrumb>

          { hasType("forwarder") &&
            <div className="container-body">
              <div className="row">
                <div className="col-md">
                  <div className="form-header">
                    <label>Overview</label>
                  </div>
                </div>
              </div>
              <div className="row row-small">
                <div className="col-md-6 col-lg-6">
                  <table className="table table-details" >
                    <tbody>
                      <tr className="row-no-top-border-and-padding">
                        <td width={200} className="header-text"></td>
                        <td width={200} className="header-text">Gross</td>
                        <td width={200} className="header-text">Net</td>
                        <td width={200} className="header-text">Invoices</td>
                      </tr>
                      <tr>
                        <td width={200} className="header-text">Sale price</td>
                        <td>{Converter.toCurrency("eur", this.getGrossTotalInvoiceType("sale"))}</td>
                        <td>{Converter.toCurrency("eur", this.getNetTotalInvoiceType("sale"))}</td>
                        <td>{this.getCountOfInvoiceType("sale")}</td>
                      </tr>
                      <tr>
                        <td width={200} className="header-text">Purchase price</td>
                        <td>{Converter.toCurrency("eur", this.getGrossTotalInvoiceType("purchase"))}</td>
                        <td>{Converter.toCurrency("eur", this.getNetTotalInvoiceType("purchase"))}</td>
                        <td>{this.getCountOfInvoiceType("purchase")}</td>
                      </tr>
                      <tr>
                        <td width={200} className="header-text-bold">Profit</td>
                        <td>{<span className={this.getGrossProfitClass()}><b>{Converter.toCurrency("eur", this.getGrossProfit())}</b></span>}</td>
                        <td>{<span className={this.getNetProfitClass()}><b>{Converter.toCurrency("eur", this.getNetProfit())}</b></span>}</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          }
          
          <Invoices
            location={this.props.location}
            history={this.props.history}
            statuses={["open"]}
            openInvoice={this.openInvoice.bind(this)}
            name="open_invoices_table_state"
            emptyMessage="There are no open invoices"
          />

        </div>
      </div >
    );
  }
}

export default OpenInvoices;
