import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Alert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Theme from './theme.js';
import { sendInvoice } from '../services/invoice_service.js';
import MultilineTextField from './multiline_textfield';
import ErrorHandler from '../util/error_handler.js';
import Converter from '../util/converter.js';

class SendInvoiceDialog extends React.Component {

  constructor(props) {
    super();

    var request = {
      subject: `Invoice ${props.invoice.invoice_number}`,
      message: `\
Good day,\n\
\n\
Attached, please find invoice ${props.invoice.invoice_number} with due date ${Converter.toDate(props.invoice.due_date)}.\n\
\n\
You can also find this invoice on the CargoDesk platform.\n\
\n\
Thank you.\n\
\n\
Best regards,\n\
CargoDesk Finance Division`,
      cc: []
    }

    this.state = {
      request: request
    };

    this.formRef = React.createRef();
  }

  submit(e) {
    e.preventDefault();

    if(!this.formRef.current.checkValidity()) {
      this.formRef.current.reportValidity();
      return;
    }

    sendInvoice(this.props.invoice.id, this.state.request).then((invoice) => {
      this.props.proceed(invoice);
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }
  
  handleChange(e) {
    this.state.request[e.target.name] = e.target.value;
    this.setState({ request: this.state.request });
  }

  getTo() {
    if(this.props.account && this.props.account.company_details) {
      return this.props.account.company_details.email_address;
    }
  }

  getCC() {
    var cc = [];

    if(this.props.account && this.props.account.users) {
      for(const user of this.props.account.users) {
        cc.push(user.email);
      }
    }

    return cc;
  }

  render() {
    return (
      <Theme>
        <Dialog fullWidth={true} maxWidth={"md"} open={this.props.show} onClose={this.props.dismiss} >
          <DialogTitle>{this.props.title}</DialogTitle>
          <DialogContent>
            <div className="row">
              <div className="col-md">

                {this.props.invoice.sent_at &&
                  <div className="row">
                    <div className="col-md-12">
                      <Alert severity="warning">This invoice has already been sent to this customer on {Converter.toDatetime(this.props.invoice.sent_at)}.</Alert>
                    </div>
                  </div>
                }

                <form autoComplete="off" ref={this.formRef} onSubmit={this.submit.bind(this)}>
                  
                  <div className="row">
                    <div className="col-md-12">
                      <TextField
                        label="To"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        name="to"
                        disabled={true}
                        value={this.getTo()}
                      />
                    </div>
                  </div>  
                        
                  <div className="row">
                    <div className="col-md-12">
                      <FormControl
                        variant="outlined"
                        margin="dense"
                        fullWidth>
                        <InputLabel>CC</InputLabel>
                        <Select
                          multiple
                          label="CC"
                          value={this.state.request.cc}
                          renderValue={(selected) => selected.join(', ')}
                          name="cc"
                          onChange={this.handleChange.bind(this)} 
                          >
                            {this.getCC().map(cc => {
                              return (
                                <MenuItem key={cc} value={cc}>
                                  <Checkbox checked={this.state.request.cc.indexOf(cc) > -1} />
                                  <ListItemText primary={cc} />
                                </MenuItem>
                              )
                            })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>  
                                    
                  <div className="row">
                    <div className="col-md-12">
                      <TextField
                        label="Subject"
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        name="subject"
                        value={this.state.request.subject}
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                  </div>  

                  <div className="row">
                    <div className="col-md-12">
                      <MultilineTextField
                        label="Message"
                        variant="outlined"
                        fullWidth
                        min_rows={4}
                        margin="dense"
                        value={this.state.request.message}
                        name="message"
                        onChange={this.handleChange.bind(this)}
                        InputLabelProps={{
                          shrink: this.state.request.message !== undefined,
                        }}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.dismiss()} color="primary">Cancel</Button>
            <Button onClick={this.submit.bind(this)} color="primary">Send</Button>
          </DialogActions>
        </Dialog>
      </Theme>
    )
  }
}

SendInvoiceDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
  invoice: PropTypes.object,
  account: PropTypes.object,
  title: PropTypes.string,
  fields: PropTypes.array
}

export default confirmable(SendInvoiceDialog);
