import { DefaultApi } from "generated-documents-api";
import { getHeaders, handleResponse } from './web_service';

export const API = new DefaultApi({
  basePath: window.appConfig.API_HOSTNAME
});

export function getTermsAndConditions() {
  var headers = getHeaders();
  headers['responseType'] = 'blob';
  return API.getTermsAndConditions(headers).then(handleResponse);
}
