import { DefaultApi } from "generated-warehouses-api";
import { getHeaders, handleResponse } from './web_service';

export const API = new DefaultApi({
  basePath: window.appConfig.API_HOSTNAME
});

export function getWarehouses() {
  return API.getWarehouses(getHeaders()).then(handleResponse);
}

export function getWarehouse(code) {
  return API.getWarehouse(code, getHeaders()).then(handleResponse);
}

export function getWarehouseStock(code, page, size, sort, filters) {
  return API.getWarehouseStock(code, page, size, sort, filters, getHeaders()).then(handleResponse);
}

export function exportStockToDocument(code, sort, filters) {
  var headers = getHeaders();
  headers['responseType'] = 'blob';
  return API.exportStockToDocument(code, sort, filters, headers).then(handleResponse);
}
