import { DefaultApi } from "generated-shipments-api";
import { getHeaders, handleResponse } from './web_service';

export const API = new DefaultApi({
  basePath: window.appConfig.API_HOSTNAME
});

export function getShippingLines() {
  return API.getShippingLines(getHeaders()).then(handleResponse);
}
