import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Converter from '../../../../shared/util/converter'
import confirmContainer from '../../../../shared/components/confirm_container.js'
import ContainersTable from '../../../../shared/components/containers_table'
import confirm from '../../../../shared/components/confirm.js'
import confirmShipmentContainersImport from '../../../../shared/components/shipment_confirm_containers_import.js'

class ContainersTab extends React.Component {

  getTotalContainers() {
    return this.props.shipment.containers.length;
  }

  getTotalNetWeightKg() {
    if(!this.props.shipment) {
      return
    }

    if(!this.props.shipment.containers) {
      return 0;
    }
  
    var total = 0;
    for(const container of this.props.shipment.containers) {
      if(container.net_weight_kg) {
        total += Number(container.net_weight_kg);
      }
    }
    return total;
  }

  addContainerToList() {
    var container = { key: this.getUniqueKey(), index: this.props.shipment.containers.length };
    confirmContainer("Add container", container, [ "container_number", "size", "type", "net_weight_kg" ]).then((response_container) => {
      this.props.shipment.containers.push(response_container);
      this.setState({ quote: this.props.shipment });
    });
  }

  editContainerOfList(index) {
    const clone = JSON.parse(JSON.stringify(this.props.shipment.containers[index]));
    clone.index = index;
    
    confirmContainer("Edit container", clone, [ "container_number", "size", "type", "net_weight_kg" ]).then((response_container) => {
      this.props.shipment.containers[index] = response_container;
      this.setState({ quote: this.props.shipment });
    });
  }

  removeContainerFromList(index) {
    confirm("Please confirm", `Are you sure you want to delete Container ${index + 1}?`).then(() => {
      this.props.shipment.containers.splice(index, 1);
      this.setState({ quote: this.props.shipment });
    });
  }

  getUniqueKey() {
    return new Date().getTime() + Math.random();
  }

  updateContainers() {
    confirmShipmentContainersImport().then((data) => {
      if(!data.containers || !this.props.shipment.containers) {
        return;
      }

      for(const container of data.containers) {
        var existing = this.props.shipment.containers.find(c => container.container_number === c.container_number);
        if(existing) {
          existing.net_weight_kg = container.net_weight_kg;
        }
      }
      
      this.props.updateShipment(this.props.shipment);
    });
  }

  render() {
    return (
      <div>

        <div className="row row-small">
          <div className="col-md-6 col-lg-4">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td width={200} className="header-text">Total containers</td>
                  <td>{Converter.formatNumber(this.getTotalContainers(), 0)}</td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Total net weight)</td>
                  <td>{Converter.formatNumber(this.getTotalNetWeightKg(), 2)} kg</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="col-md">
            <Button
              variant="outlined"
              color="primary"
              onClick={this.addContainerToList.bind(this)}
              startIcon={<Icon>playlist_add_check</Icon>}>
              Add container
            </Button>
            <Button
              className='align_right'
              variant="outlined"
              color="primary"
              onClick={this.updateContainers.bind(this)}
              startIcon={<Icon>upload_file</Icon>}>
              Update
            </Button>
          </div>
        </div>

        <div className="row">
          <div className="col-sm">
            <ContainersTable
                name="shipment_form_containers_table_state"
                data={this.props.shipment}
                headers={[ "container_number", "size", "type", "net_weight_kg" ]}
                editable={true}
                onEdit={this.editContainerOfList.bind(this)}
                onDelete={this.removeContainerFromList.bind(this)}
            />
          </div>
        </div>
      
      </div>
      
    );
  }
}

export default ContainersTab;
