import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Theme from './theme';
import RowDataTable from './row_data_table';
import TableUtil from '../util/table_util'
import Mapper from '../util/mapper';
import Converter from '../util/converter';

class StockHistoryDialog extends React.Component {

  constructor(props) {
    super();
    this.state = {
      history: props.history,
    };

    this.formRef = React.createRef();
  }

  getStockHistoryRows() {    
    if(!this.state.history) {
      return;
    }
    
    if(!this.state.history) {
      return { data: [], totalElements: 0 };
    }
    
    return { data: this.state.history, totalElements: this.state.history.length, ready: true };
  }

  getStockHistoryHeaders() {
    var headers = [];
      
    headers.push(TableUtil.getTextColumn(
      "shipment_id",
      "Shipment",
      (params) => {
        if(params.value) {          
          return <a href={`/shipments/all/${params.value}`} target="_blank" rel="noopener noreferrer" >{params.value}</a>;
        }
        return "";
      }
    ));
    
    headers.push(TableUtil.getSetColumn(
      "type",
      "Type",
      (params) => {
        if(params.value) {
          return <span className={"badge " + Mapper.toStockHistoryTypeBadge(params.value)} >{Mapper.fromStockHistoryType(params.value)}</span>;
        }
        return <span/>
      },
      Object.keys(Mapper.stockHistoryTypes()),
      (value) => Mapper.fromStockHistoryType(value)
    ));
    
    headers.push(TableUtil.getSetColumn(
      "status",
      "Status",
      (params) => {
        if(params.value) {
          return <span className={"badge " + Mapper.toStockHistoryStatusBadge(params.value)} >{Mapper.fromStockHistoryStatus(params.value)}</span>;
        }
        return <span/>
      },
      Object.keys(Mapper.stockHistoryStatuses()),
      (value) => Mapper.fromStockHistoryStatus(value)
    ));

    headers.push(TableUtil.getNumberColumn("quantity", "Quantity"));

    headers.push(TableUtil.getDateColumn(
      "arrival_date",
      "Arrival date",
      (params) => Converter.toDate(params.value),
    ));
    
    headers.push(TableUtil.getDateColumn(
      "ready_date",
      "Ready date",
      (params) => Converter.toDate(params.value),
    ));

    headers.push(TableUtil.getDateColumn(
      "dispatch_date",
      "Dispatch date",
      (params) => Converter.toDate(params.value),
    ));
    return headers;
  }

  render() {
    return (
      <Theme>
        <Dialog fullWidth={true} maxWidth={"lg"} open={this.props.show} onClose={this.props.dismiss} >
          <DialogTitle>{this.props.title}</DialogTitle>
          <DialogContent>
            <div className="row">
                <div className="col-md">
                  <RowDataTable
                    name="stock_history_table_state"
                    rowData={this.getStockHistoryRows()}
                    columnDefs={this.getStockHistoryHeaders()}
                    overlayNoRowsTemplate="There is no history"
                  />
                </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.dismiss()} color="primary">Close</Button>
          </DialogActions>
        </Dialog>
      </Theme>
    )
  }
}

StockHistoryDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
  title: PropTypes.string,
  history: PropTypes.object,
  dimension_required: PropTypes.bool
}

export default confirmable(StockHistoryDialog);
