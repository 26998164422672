import React from 'react';
import fileDownload from 'js-file-download';
import DocumentLink from '@material-ui/core/Link';
import Table from '../../../components/table.js';
import { getShipmentDocument } from '../../../services/shipment_service';
import { hasType } from '../../../services/authentication_service.js';
import ErrorHandler from '../../../util/error_handler';
import Converter from '../../../util/converter.js';

class DocumentsTab extends React.Component {
  
  getDocumentRows(documents) {    
    if(!documents) {
      return [];
    }
    
    return documents.map(document => {
      return ({
        file_name: 
          <span>
            <span className="icon-span"><i className="far fa-file"/></span>
            <DocumentLink
              className="file-download-link"
              component="button"
              variant="body2"
              type="button"
              onClick={this.downloadDocument.bind(this, document)}
              >
                {document.file_name}
            </DocumentLink>
          </span>,
          created_at: Converter.toDatetime(document.created_at)
      });
    });
  }

  downloadDocument(document) {
    getShipmentDocument(this.props.shipment.id, document.id).then(blob => {
      fileDownload(blob, document.file_name);
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  render() {
    return (
      <div>

        { (hasType("forwarder") || hasType("admin")) &&
          <div>

            <div className="row row-small">
              <div className="col-md-6 col-lg-6">

                <div className="row row-small">
                  <div className="col-md-12 col-lg-12">
                    <div className="header-text">
                      <label>Public documents</label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <Table
                      loading_rows={3}
                      headers={[{ name: "File", key: "file_name" }, { name: "Date", key: "created_at" }]}
                      rows={this.props.shipment ? this.getDocumentRows(this.props.shipment.public_documents) : undefined}
                      empty_rows_text="There are no documents"
                      selectable={false}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row row-small">
              <div className="col-md-6 col-lg-6">

                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <div className="header-text">
                      <label>Internal documents</label>
                    </div>
                  </div>
                </div>

                <div className="row row-small">
                  <div className="col-md-12 col-lg-12">
                    <Table
                      loading_rows={3}
                      headers={[{ name: "File", key: "file_name" }, { name: "Date", key: "created_at" }]}
                      rows={this.props.shipment ? this.getDocumentRows(this.props.shipment.internal_documents) : undefined}
                      empty_rows_text="There are no documents"
                      selectable={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        { (hasType("customer") || hasType("agent")) &&
          <div className="row row-small">
            <div className="col-md-12 col-lg-6">
              <Table
                loading_rows={3}
                headers={[{ name: "File", key: "file_name" }, { name: "Date", key: "created_at" }]}
                rows={this.props.shipment ? this.getDocumentRows(this.props.shipment.public_documents) : undefined}
                empty_rows_text="There are no documents"
              />
            </div>
          </div>
        }
      </div>
    );
  }
}

export default DocumentsTab;
