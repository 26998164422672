import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Theme from './theme.js';

class InputDialog extends React.Component {

  constructor(props) {
    super();
    this.state = {
      value: props.initial_value
    };

    this.formRef = React.createRef();
  }

  submit(e) {
    e.preventDefault();

    if(!this.formRef.current.checkValidity()) {
      this.formRef.current.reportValidity();
      return;
    }

    this.props.proceed(this.state.value)
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <Theme>
        <Dialog open={this.props.show} onClose={this.props.cancel} >
          <DialogTitle>{this.props.title}</DialogTitle>
          <DialogContent>
            <form autoComplete="off" ref={this.formRef} onSubmit={this.submit.bind(this)}>

              <div className="row">
                <div className="col-md-12">

                  <div className="row">
                    <div className="col-md-12">
                      <span>{this.props.message}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <TextField
                        label={this.props.field_name}
                        variant="outlined"
                        required
                        fullWidth
                        type={this.props.field_type}
                        margin="dense"
                        autoFocus
                        value={this.state.value}
                        name="value"
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                  </div>

                </div>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.cancel()} color="primary">Cancel</Button>
            <Button onClick={this.submit.bind(this)} color="primary">Ok</Button>
          </DialogActions>
        </Dialog>
      </Theme>
    )
  }
}

InputDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
  title: PropTypes.string,
  message: PropTypes.string,
  field_name: PropTypes.string,
  field_type: PropTypes.string,
  initial_value: PropTypes.number
}

export default confirmable(InputDialog);
