import React from 'react';
import { Redirect } from 'react-router-dom'
import { hasType, loggedIn, getExpiration, mfaVerification, actionRequired } from '../services/authentication_service';

const expirationTimer = {};

class Authenticated extends React.Component {
  
  constructor(props) {
    super(props)
    this.redirectToLogin = this.redirectToLogin.bind(this);
  }
  
  componentDidMount() {
    this.setExpirationTimer();
  }
  
  componentDidUpdate() {
    this.setExpirationTimer();
  }

  setExpirationTimer() {
    if (loggedIn() && !expirationTimer.timer) {
      var msUntilExp = this.getTokenExpiration();

      console.log("Setting timer");

      expirationTimer.timer = setTimeout(() => {
        expirationTimer.timer = undefined;

        if(loggedIn()) {
          console.log("Still logged in, setting new timer");
          this.setExpirationTimer();
        } else {
          console.log("Token expired, redirect to login");
          this.redirectToLogin();
        }
      }, msUntilExp);
    }
  }

  redirectToLogin() {
    this.props.history.push('/login?ref=' + window.location.pathname);
  }

  getTokenExpiration() {
    var expiration = getExpiration();
    return (expiration * 1000) - Date.now();
  }

  render() {
    var ref = window.location.pathname;

    if (!loggedIn()) {
      if(window.location.pathname === "/login") {
        return <div></div>
      }
      if(ref && ref !== "/") {
        return <Redirect to={'/login?ref=' + window.location.pathname} />
      }
      return <Redirect to={'/login'} />
    }

    if (mfaVerification()) {
      if(window.location.pathname === "/mfa-verification") {
        return <div></div>
      }
      if(ref && ref !== "/") {
        return <Redirect to={'/mfa-verification?ref=' + window.location.pathname} />
      }
      return <Redirect to={'/mfa-verification'} />
    }

    if (actionRequired()) {
      if(window.location.pathname === "/update" || window.location.pathname === "/document/terms-and-conditions") {
        return <div></div>
      }
      return <Redirect to={'/update'} />
    }

    var that = this;
    if (hasType(this.props.types)) {
      var children = React.Children.map(this.props.children, function (child) {
        return React.cloneElement(child, {
          location: that.props.location
        })
      })
    
      return <div>{children}</div>
    }

    return <div></div>
  }
}

export default Authenticated;
