import React from 'react';
import ContainersTable from "../../../components/containers_table.js";
import LoadingContent from '../../../components/loading_content.js';
import Converter from '../../../util/converter.js'
import AsyncButton from '../../../components/async_button.js';
import { exportContainersToDocument } from '../../../services/shipment_service.js';
import DomUtil from '../../../util/dom_util.js';
import ErrorHandler from '../../../util/error_handler.js';
import fileDownload from 'js-file-download';

class ContainersTab extends React.Component {
  
  constructor(props) {
    super(props)
  }

  getTotalContainers() {
    if(!this.props.shipment) {
      return
    }

    if(!this.props.shipment.containers) {
      return 0;
    }
    
    return this.props.shipment.containers.length;
  }

  getTotalNetWeightKg() {
    if(!this.props.shipment) {
      return
    }

    if(!this.props.shipment.containers) {
      return 0;
    }
  
    var total = 0;
    for(const container of this.props.shipment.containers) {
      if(container.net_weight_kg) {
        total += Number(container.net_weight_kg);
      }
    }
    return total;
  }

  exportContainers(e) {
    e.preventDefault();
    var button = e.currentTarget;
    DomUtil.disableClass(button);

    exportContainersToDocument(this.props.shipment.id).then((blob) => {
      fileDownload(blob, `shipment_${this.props.shipment.id}_containers_export.xlsx`);
      DomUtil.enableClass(button);
    }).catch((error) => {
      ErrorHandler.showError(error);
      DomUtil.enableClass(button);
    });
  }

  render() {
    return (
      <div>

        <div className="row row-small">
          <div className="col-md-12 col-lg-6">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td width={200} className="header-text">Total containers</td>
                  <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => Converter.formatNumber(this.getTotalContainers(), 0)} /></td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Total net weight</td>
                  <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => Converter.formatNumber(this.getTotalNetWeightKg(), 2) + " kg"} /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="col-md">
            <AsyncButton
              variant="outlined"
              color="secondary"
              onClick={this.exportContainers.bind(this)}
              icon="download"
              text="Export" />
          </div>
        </div>

        <div className="row">
          <div className="col-sm">
            <ContainersTable
                name="shipment_containers_table_state"
                data={this.props.shipment}
                headers={[ "container_number", "size", "type", "net_weight_kg", "co2_emissions_kg" ]}
                pagination={true}
                defaultPageSize={10}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ContainersTab;
