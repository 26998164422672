import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getShipment, getShipmentDocument, uploadShipmentDocument } from '../../../shared/services/shipment_service.js';
import { getAccount } from '../../../shared/services/account_service.js';
import ErrorHandler from '../../../shared/util/error_handler.js';
import InvoiceContainer from '../../../shared/components/invoicing_container.js';

class InvoiceShipment extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      shipment_id: this.props.match.params.shipment_id,
      shipment: undefined
    };
  }

  componentWillMount() {
    this.getShipment();
  }

  getShipment() {
    getShipment(this.state.shipment_id).then((shipment) => {
      if (shipment) {
        this.setState({ shipment: shipment }, () => {
          this.getCustomer();
        });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getCustomer() {
    getAccount(this.state.shipment.customer.id).then((customer) => {
      if (customer) {
        this.setState({ customer: customer });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  isActivePath() {    
    return this.props.location.pathname.startsWith('/shipments/active');
  }

  getBasePath() {
    if(this.isActivePath()) {
      return '/shipments/active';
    }
    return '/shipments/all';
  }

  getBreadCrumbTitle() {
    if(this.isActivePath()) {
      return 'Active Shipments';
    }
    return 'All Shipments';
  }
  
  getInvoiceLines() {
    var lines = [];

    if(this.state.shipment.sale_lines && this.state.shipment.sale_lines.length > 0) {
      for(const line of this.state.shipment.sale_lines) {
        lines.push({
          description: line.name,
          quantity: 1,
          price: line.value,
        });
      }
    } else {
      lines.push({});
    }
    return lines;
  }

  getComment() {
    var comment = "Client reference: "+ this.state.shipment.reference;
    if(this.state.shipment.customer_contact) {
      comment += "\nContact person: " + this.state.shipment.customer_contact.name;
    }
    return comment;
  }

  uploadDocument(file, progressEvent) {
    return uploadShipmentDocument(this.state.shipment_id, file, "nobody", progressEvent);
  }

  downloadDocument(document) {
    return getShipmentDocument(this.state.shipment_id, document.id);
  }
  
  render() {
    return (
      <div>

        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.getBasePath()}/${this.props.location.search}`}>{this.getBreadCrumbTitle()}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.getBasePath()}/${this.props.match.params.shipment_id}${this.props.location.search}`}>{this.props.match.params.shipment_id}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Invoicing</BreadcrumbItem>
          </Breadcrumb>

          <InvoiceContainer
            title={`Shipment ${this.state.shipment_id} invoicing`}
            resource_type="shipment"
            resource_id={this.state.shipment_id}
            counterparty={this.state.customer}
            getbaseInvoiceLines={this.getInvoiceLines.bind(this)}
            getBaseComment={this.getComment.bind(this)}
            uploadDocument={this.uploadDocument.bind(this)}
            downloadDocument={this.downloadDocument.bind(this)}
          />
        
        </div>
      </div>
    );
  }
}

export default InvoiceShipment;
