import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Theme from './theme';
import DocumentUpload from './document_upload'
import { getContainersExampleDocument, readContainersDocument } from '../services/shipment_service';
import Converter from '../util/converter'
import fileDownload from 'js-file-download';
import alertDialog from './alert';

class ShipmentContainersImportDialog extends React.Component {

  constructor(props) {
    super();
    this.state = {
      containers: [],
      replace: false

    };

    this.formRef = React.createRef();
  }

  submit(e) {
    e.preventDefault();

    if(!this.formRef.current.checkValidity()) {
      this.formRef.current.reportValidity();
      return;
    }

    this.props.proceed({
      containers: this.state.containers
    })
  }

  handleReplaceChange(e) {
    this.setState({ replace: e.target.value });
  }

  downloadExampleFile(e) {
    e.preventDefault();
    getContainersExampleDocument().then(blob => {
      fileDownload(blob, "shipment_containers_import_example.xlsx");
    }).catch(error => {
      console.error(error)
      alertDialog("Alert", "Failed to download document.")
    });
  }

  uploadDocument(file, progressEvent) {
    return readContainersDocument(file, progressEvent);
  }

  documentUploaded(data) {
    this.setState({ containers: data.containers });
  }

  getTotalContainers() {
    if(!this.state.containers) {
      return 0;
    }
    return this.state.containers.length;
  }

  render() {
    return (
      <Theme>
        <Dialog fullWidth={true} maxWidth={"md"} open={this.props.show} onClose={this.props.dismiss} >
          <DialogTitle>Update container details</DialogTitle>
          <DialogContent>
            <form autoComplete="off" ref={this.formRef} onSubmit={this.submit.bind(this)}>
                
              <div className="row">
                <div className="col-md">

                  <div className="row">
                    <div className="col-md-12">
                      <span>The import allows you to update your container details using an Excel file.</span>
                      <br/>
                      <span>Download the example Excel file to see in which structure the data should be supplied.</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={this.downloadExampleFile.bind(this)}
                        startIcon={<Icon>download</Icon>}>
                        Download example Excel
                      </Button>
                    </div>
                  </div>
                                  
                </div>
              </div>

              <div className="row">
                <div className="col-md">

                  <div className="row">
                    <div className="col-md-6">
                      <span>Upload your Excel file with container details:</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <DocumentUpload 
                        title="Excel with container details"
                        required={true}
                        uploadDocument={this.uploadDocument.bind(this)}
                        documentUploaded={this.documentUploaded.bind(this)}
                      />
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="col-md-6">
                      <table className="table table-details" >
                        <tbody>
                          <tr className="row-no-top-border-and-padding">
                            <td width={200} className="header-text">Total containers</td>
                            <td>{Converter.formatNumber(this.getTotalContainers(), 0)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.dismiss()} color="primary">Cancel</Button>
            <Button onClick={this.submit.bind(this)} color="primary">Save</Button>
          </DialogActions>
        </Dialog>
      </Theme>
    )
  }
}

ShipmentContainersImportDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
}

export default confirmable(ShipmentContainersImportDialog);
