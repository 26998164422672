import React from 'react';
import Converter from '../../../util/converter.js'

class TrucksContainer extends React.Component {

  render() {
    return (
      <div className="container-body">
        <div className="row">
          <div className="col-md">
            <div className="form-header">
              <label>Truck Details</label>
            </div>
          </div>
        </div>

        <div className="row row-small">
          <div className="col-md-12 col-lg-6">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td width={200} className="header-text">Number of trucks</td>
                  <td>{Converter.formatNumber(this.props.quote.truck_quantity, 0)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    );
  }
}

export default TrucksContainer;
