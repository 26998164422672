import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getQuote } from '../../services/quote_service';
import ErrorHandler from '../../util/error_handler';
import ColliContainer from './components/colli_container.js';
import ContainersContainer from './components/containers_container.js';
import TrucksContainer from './components/trucks_container.js';
import CargoContainer from './components/cargo_container.js';
import ProvideQuoteContainer from './components/provide_quote_container.js';
import QuoteContainer from './components/quote_container.js';
import PalletsContainer from './components/pallets_container.js';

class ShowQuote extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      quote_id: this.props.match.params.quote_id,
      quote: undefined
    };
  }

  componentDidMount() {
    this.getQuote();
    this.isActivePath();
  }

  getQuote() {
    getQuote(this.state.quote_id).then((quote) => {
      if (quote) {
        this.setState({ quote: quote });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  quoteUpdated(quote) {
    this.setState({ quote: quote });
  }
  
  isActivePath() {    
    return this.props.location.pathname.startsWith('/quotes/active');
  }

  getBasePath() {
    if(this.isActivePath()) {
      return '/quotes/active';
    }
    return '/quotes/all';
  }

  getBreadCrumbTitle() {
    if(this.isActivePath()) {
      return 'Active Quotes';
    }
    return 'All Quotes';
  }

  isFCL() {
    if(!this.state.quote) {
      return false;
    }
    return this.state.quote.transport_type === "fcl";
  }

  isFTL() {
    if(!this.state.quote) {
      return false;
    }
    return this.state.quote.transport_type === "ftl";
  }
  
  render() {
    return (
      <div>

        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.getBasePath()}${this.props.location.search}`}>{this.getBreadCrumbTitle()}</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.props.match.params.quote_id}</BreadcrumbItem>
          </Breadcrumb>

          <QuoteContainer
            location={this.props.location}
            history={this.props.history}
            quote_id={this.state.quote_id}
            quote={this.state.quote}
            quoteUpdated={this.quoteUpdated.bind(this)}
          />

          <ProvideQuoteContainer
            history={this.props.history}
            quote_id={this.state.quote_id}
            quote={this.state.quote}
            quoteUpdated={this.quoteUpdated.bind(this)}
          />

          <CargoContainer
            quote={this.state.quote}
          />
          
          { this.isFCL() &&
            <ContainersContainer
              quote={this.state.quote}
            />
          }
          
          { this.isFTL() &&
            <TrucksContainer
              quote={this.state.quote}
            />
          }

          <PalletsContainer
            quote={this.state.quote}
          />

          <ColliContainer
            quote={this.state.quote}
          />

        </div>
      </div>
    );
  }
}

export default ShowQuote;
