import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Link from '@material-ui/core/Link';
import AsyncButton from '../../components/async_button';
import { getCurrentUser, updateCurrentUser } from '../../services/account_service';
import Mapper from '../../util/mapper';
import DomUtil from '../../util/dom_util';
import PasswordForm from '../../components/password_form';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ErrorHandler from '../../util/error_handler';
import alertDialog from '../../components/alert';
import LoadingSelectField from '../../components/loading_select_field'

class Settings extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      user: undefined,
      password_errors: [ "length", "case", "numeric", "symbol", "confirm" ]
    }
  }

  componentWillMount() {
    this.getCurrentUser();
  }

  getCurrentUser() {
    getCurrentUser().then((user) => {
      if (user) {
        this.setState({ user: user });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  handleChange(e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.state.user[e.target.name] = value;
    this.setState({ user: this.state.user });
  }

  passwordUpdated(password, has_errors) {
    this.state.password = password;
    this.state.password_has_errors = has_errors;
    this.setState({ user: this.state.user });
  }

  updateNotifications(e) {
    e.preventDefault();
    var form = e.currentTarget;

    DomUtil.disableFormSubmitButtonClass(form);
    updateCurrentUser({ notifications: this.state.user.notifications }).then(() => {
      DomUtil.enableFormSubmitButtonClass(form);
      alertDialog("Message", "Notifications updated")
    }).catch((error) => {
      ErrorHandler.showError(error);
      DomUtil.enableFormSubmitButtonClass(form);
    });
  }

  updatePassword(e) {
    e.preventDefault();
    var form = e.currentTarget;

    if(this.state.password_has_errors) {
      alertDialog("Alert", "Password does not match all requirements");
      return;
    }
    
    DomUtil.disableFormSubmitButtonClass(form);
    updateCurrentUser({ password: this.state.password }).then(() => {
      DomUtil.enableFormSubmitButtonClass(form);
      alertDialog("Message", "Password has been updated")
    }).catch((error) => {
      ErrorHandler.showError(error);
      DomUtil.enableFormSubmitButtonClass(form);
    });
  }

  openTerms(e) {
    e.preventDefault();
    var url = `/document/terms-and-conditions?file_name=General-Terms-and-Conditions.pdf`;
    window.open(url, "_blank");
  }

  render() {
    return (
      <div>

        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem active>Settings</BreadcrumbItem>
          </Breadcrumb>

          <div className="container-body">

            <form autoComplete="off" onSubmit={this.updateNotifications.bind(this)}>

              <div className="row">
                <div className="col-md">
                  <div className="form-header">
                    <label>Notifications</label>
                  </div>
                </div>
              </div>

              <div className="row row-small">
                <div className="col-md-4">
                  { this.state.user &&
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      fullWidth>
                      <InputLabel shrink={true} >Notifications *</InputLabel>
                      <Select
                        native
                        required
                        label="Notifications *"
                        value={this.state.user.notifications}
                        name="notifications"
                        onChange={this.handleChange.bind(this)} >
                          <option key={"notifications_empty"} value=""></option>
                          {Object.keys(Mapper.userNotifications()).map(value => {
                            return (<option key={"notifications_"+value} value={value}>{Mapper.fromUserNotifications(value)}</option>);
                          })}
                      </Select>
                    </FormControl>
                  }
                  { !this.state.user &&
                    <LoadingSelectField
                      label="Notifications"
                      shrink={true}
                    />
                  }
                </div>
              </div>
              
              <div className="row row-small">
                <div className="col-md">
                  <AsyncButton
                    type="submit"
                    variant="outlined"
                    color="primary"
                    icon="check"
                    text="Update" />
                </div>
              </div>

            </form>

          </div>

          <div className="container-body">

            <form autoComplete="off" onSubmit={this.updatePassword.bind(this)}>

              <div className="row">
                <div className="col-md">
                  <div className="form-header">
                    <label>Password</label>
                  </div>
                </div>
              </div>

              <div className="row row-small">
                <div className="col-md-4">
                  <PasswordForm passwordUpdated={this.passwordUpdated.bind(this)} />
                </div>
              </div>

              <div className="row row-small">
                <div className="col-md">
                  <AsyncButton
                    type="submit"
                    variant="outlined"
                    color="primary"
                    icon="check"
                    text="Update" />
                </div>
              </div>

            </form>

          </div>

          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Terms and conditions</label>
                </div>
              </div>
            </div>

            <div className="row row-small">
              <div className="col-md">
                <span>You have read and agreed to the&nbsp;</span>
                <Link
                  className="inline-link"
                  component="button"
                  variant="body2"
                  type="button"
                  onClick={this.openTerms.bind(this)}>
                    terms and conditions
                </Link>
              </div>
            </div>


          </div>
          
        </div>
      </div >
    );
  }
}

export default Settings;
