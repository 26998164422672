import React from 'react';
import { mfaVerification, actionRequired } from '../../services/authentication_service';

class Redirect extends React.Component {

  componentWillMount() {
    this.redirectToHome();
  }

  redirectToHome() {
    if (mfaVerification()) {
      this.props.history.replace('/mfa-verification');
    } else  if (actionRequired()) {
      this.props.history.replace('/update');
    } else {
      this.props.history.replace('/');
    }
  }

  render() {
    return ( <div/> );
  }
}

export default Redirect;
